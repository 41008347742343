import Filter, { FilterAttributes, FilterType } from "../../../../abstractions/filter";
import { NumberFilter } from "./components";
import BooleanFilter from "./components/booleanFilter";
import DateFilter from "./components/dateFilter";
import DateRangeFilter from "./components/dateRangeFilter";
import ReferenceFilter from "./components/referenceFilter/referenceFilter";
import SelectFilter from "./components/selectFilter";
import TextFilter from "./components/textFilter";

const factoryFilters = <TFilter extends Filter>(filter: FilterAttributes<any>, key: keyof TFilter, 
  updateFilter: (key: keyof TFilter, value: any, customUpdate?: (filter: Filter, key: keyof TFilter, value: any) => void) => void) => {

  switch (filter.type) {
    case FilterType.Number:
      return <NumberFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.Boolean:
      return <BooleanFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.Reference:
      return <ReferenceFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.Date:
      return <DateFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.DateRange:
      return <DateRangeFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.String:
      return <TextFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
    case FilterType.Select:
      return <SelectFilter<TFilter> key={key.toString()} attributes={filter} filterKey={key} updateFilter={updateFilter} />;
  }
}

export default factoryFilters;