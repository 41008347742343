import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Fragment } from "react";
import { FilterProps } from "..";
import { RootApiType } from "../../../../../../../api/rootApi";
import Filter, { FilterAttributes, FilterType } from "../../../../../../abstractions/filter";
import useReferenceFilter from "../../../../../../controller/filters/referenceFilter";
import './style.sass';

const ReferenceFilter = <TFilter extends Filter>(props: FilterProps<number, TFilter>) => {
  const { 
    open,
    setOpen,
    options,
    loading,
    value,
    onChange,
    inputValue,
    setInputValue,
    label
  } = useReferenceFilter(props);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option : any, value) => option.title === value.title}
      getOptionLabel={(option: any) => option?.label ? option.label : ""}
      options={options}
      loading={loading}
      value={value}
      onChange={(_, value) => onChange(value)}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            className:"reference-filter-input",
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default ReferenceFilter;

const createReferenceFieldFilter = (
  label: string, 
  defaultValues: number[] = [], 
  route: keyof RootApiType, 
  captionField: string = "name",
  needShowFilter: boolean = true, 
  isStaticFilter: boolean = false, 
  needShowChips: boolean = true,
  fieldForOrder?: string,
  getCaption?: (entity: any) => string,
): FilterAttributes<number> => ({
  label,
  needShowFilter,
  values: defaultValues,
  isStaticFilter,
  type: FilterType.Reference,
  route,
  captionField,
  needShowChips,
  getCaption,
  fieldForOrder: fieldForOrder ?? captionField
});

export { createReferenceFieldFilter };

