import { observer } from "mobx-react-lite";
import Contractor from "../../../domain/contractor/contractorEntity";
import EntityShowView from "../../../../common/pages/blanks/Show";
import api from "../../../../api";
import { DataType } from "../../../../types/dataType";
import { SucceededWithDataResult } from "../../../../api/types/succeededResult";

const ContractorShowView: React.FC = () => {
    return (
        <div>
            <EntityShowView<Contractor>
                showServiceProps={{storeName: "contractorStore", loadEntity: getContractorById, onEntityCreate: saveContractor, onEntityUpdate: updateContractor }}
                fields={
                    [
                        {source: 'name', label: 'Имя', required: true, type: DataType.String},
                        {source: 'tin', label: 'ИНН', required: true, type: DataType.String},
                        {source: 'stagingReasonCode', label: 'КПП', required: true, type: DataType.String},
                        {source: 'address', label: 'Адрес', required: true, type: DataType.String},
                    ]}
            />
        </div>
    )
}

async function getContractorById(id: number): Promise<Contractor> {
  const response = await api.contractors.getById(id);
  return {
    order: 0,
    ...response
  };
}

async function saveContractor(contractor: Contractor): Promise<SucceededWithDataResult<number>> {
  // todo: refactor this with SucceededWithDataResult<number>
  const response = await api.contractors.create(contractor.name, contractor.tin, contractor.stagingReasonCode, contractor.address);
  return { result: response?.contractorId, succeeded: response.succeeded, errors: response?.errors };
}

async function updateContractor(id: number, contractor: Contractor): Promise<SucceededWithDataResult<number>> {
  // todo: refactor this with SucceededWithDataResult<number>
  const response = await api.contractors.edit(id, contractor.name, contractor.tin, contractor.stagingReasonCode, contractor.address);
  return { result: response?.contractorId, succeeded: response.succeeded, errors: response?.errors };
}

const ContractorShow = observer(ContractorShowView);

export default ContractorShow;