import { makeAutoObservable } from "mobx";
import { Pagination } from "../types/pagination";
import { Document } from "../types/entities/document";

export class ParagraphDocumentStore {
    private _Documents: Document[];
    private _pagination: Pagination;
    private _totalCount: number;
    private _currentDocument?: number;

    constructor() {
        makeAutoObservable(this);
        this._Documents = new Array<Document>();
        this._pagination = { page: 0, perPage: 10 };
        this._totalCount = 0;
    }

    public get Documents () {
        return this._Documents;
    }

    public set Documents (newDocuments: Document[]) {
        this._Documents = newDocuments;
    }

    public get pagination () {
        return this._pagination;
    }

    public set pagination (newPagination: Pagination) {
        this._pagination = newPagination; 
    }

    public get totalCount () {
        return this._totalCount;
    }

    public set totalCount (newTotalCount: number) {
        this._totalCount = newTotalCount;
    }
    
    public get currentDocument () {
        return this._currentDocument;
    }

    public set currentDocument (id: number | undefined) {
        this._currentDocument = id;
    }
}