import { MakeArrayFilters } from "../../helpers/utils";
import { Entity } from "../../types/entities/entity";

export enum SortingOrder {
    Asc,
    Desc,
};

export type Sorting = {
    field: string,
    order: SortingOrder,
};

export type GetManyResult<TEntity> = {
    totalCount: number;
    items: TEntity[];
};

export type getOne<TEntity> = (id: number) => Promise<TEntity | null>;
export type getMany<TEntity> = getManyByFilter<TEntity, TEntity>;

export type getManyByFilter<TEntity, TFilter> = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
    filter?: MakeArrayFilters<TFilter>, search?: string, subroute?: string) => Promise<GetManyResult<TEntity>>;
    
export interface GetManyApi<TEntity extends Entity> {
    getMany: getMany<TEntity>;
}
