import { createContext, useContext } from "react";
import ContractorStore from "../accounting/infrastructure/contractor/contractorStore";
import FindDuplicatesStore from "../accounting/infrastructure/findDuplicates/findDuplicatesStore";
import ParagraphDocumentsStore from "../accounting/infrastructure/requirements/paragraphDocumentsStore";
import RequirementsStore from "../accounting/infrastructure/requirements/requirementsStore";
import ExternalDocumentsStore from "../accounting/infrastructure/externalDocuments/externalDocumentsStore";
import UploadsStore from "../accounting/infrastructure/uploads/uploadsStore";
import StorageStore from "../accounting/infrastructure/storage/storageStore";
import ValidationStore from "../accounting/infrastructure/validation/validationStore";
import { OrganizationStore } from "./organizationStore";
import { ParagraphDocumentStore } from "./paragraphDocumentsStore";
import { RequirementStore } from "./requirementStore";
import { UploadedFileStore } from "./uploadedFileStore";
import { RequirementParagraphStore } from "./requirementParagraphStore";
import { NotificationStore } from "./notificationStore";
import { ExternalDocumentStore } from "./externalDocumentStore";
import NoOriginalsStore from "../accounting/infrastructure/noOriginals/noOriginalsStore";
import { NoOriginalStore } from "./noOriginalStore";
import { StaleStore } from "./staleStorage";
import StalesStore from "../accounting/infrastructure/stales/stalesStorage";

interface IMainStore {
    uploadedFileStore: UploadedFileStore;
    organizationStore: OrganizationStore;
    requirementStore: RequirementStore;
    requirementParagraphStore: RequirementParagraphStore;
    externalDocumentStore: ExternalDocumentStore;
    paragraphDocumentsStore: ParagraphDocumentStore;
    notificationStore: NotificationStore;
    noOriginalStore: NoOriginalStore;
    staleStore: StaleStore;
}

const uploadedFileStore = new UploadedFileStore();

const organizationStore = new OrganizationStore();

const requirementStore = new RequirementStore();
const requirementParagraphStore = new RequirementParagraphStore();

const paragraphDocumentsStore = new ParagraphDocumentStore();

const externalDocumentStore = new ExternalDocumentStore();

const noOriginalStore = new NoOriginalStore();
const staleStore = new StaleStore();

const MainStore = createContext<IMainStore>({
    uploadedFileStore,
    organizationStore,
    requirementStore,
    requirementParagraphStore,
    paragraphDocumentsStore,
    externalDocumentStore,
    notificationStore: new NotificationStore(),
    noOriginalStore,
    staleStore
});

export { MainStore };
