import { useContext, useState } from "react";
import { SucceededWithDataResult } from "../../api/types/succeededResult";
import useGoToPageProvider from "../../router/goToPageProvider";
import { EntityStoresContext, IEntityStores } from "../../stores/entitiesStore";
import { Entity } from "../../types/entities/entity";
import { Error as CustomError } from "../abstractions/error";
import Filter from "../abstractions/filter";
import { EntityField } from "../abstractions/showEntity";
import ShowService from "../abstractions/showService";
import Store from "../infrastructure/Store";

const requiredFieldErrorText = "Обязательное поле";

const useShowService = <TEntity extends Entity> ({storeName, loadEntity, onEntityCreate, onEntityUpdate} : ShowServiceProps<TEntity>) : ShowService<TEntity> => {
  const [initialEntity, setInitialEntity] = useState<TEntity>();

  const store = useContext<IEntityStores>(EntityStoresContext)[storeName] as unknown as Store<TEntity, Filter>;

  const loadAndSaveEntity = async (id: number) => {
    const result = await loadEntity(id);
    if (result){
      store.updateCurrentEntity(result);
      setInitialEntity(result);
    }

    return result;
  }

  const saveEntityServer = async (entity: TEntity) => {
    let id = initialEntity?.id as number;
    let response = {} as SucceededWithDataResult<number>;
    if (initialEntity) 
      response = await onEntityUpdate(initialEntity.id, entity);
    else {
      response = await onEntityCreate(entity);
    }

    if (response?.succeeded)
      id = response.result;
    else 
      return Array.isArray(response.errors) ? response.errors?.join(",\n") : "Непредвиденная ошибка";
    
    loadAndSaveEntity(id);
  }

  const checkIfEntityKeyValueEmpty = (value: any) =>
    (value === undefined || value?.toString().length === 0) && requiredFieldErrorText

  const validateEntityKeyValues = (entityFields: EntityField<TEntity>[]) => {
    const entity = store.currentEntity as any;
    const comparingEntity = initialEntity as any;
    let isSimilarToInitialEntity = false;

    const errors = entityFields.map(vfk => {
      if (!vfk.required && !vfk.validateFunction)
        return false;
      else {
        const validationResult = vfk.validateFunction ?
        vfk.validateFunction(entity[vfk.source]) :
        checkIfEntityKeyValueEmpty(entity[vfk.source]);
        return validationResult && { source: vfk.source, errorMessage: validationResult }
      }
    }).filter(e => e) as CustomError[];

    if (initialEntity) {
      const keysCount = entityFields.length;
      isSimilarToInitialEntity = entityFields
        .map(vfk => entity[vfk.source] === comparingEntity[vfk.source])
        .filter(v => v).length === keysCount;
    }

    return { errors: errors, canSave: !errors.length && !isSimilarToInitialEntity };
  }

  return {
      entity: store.currentEntity,
      updateEntity: store.updateCurrentEntity,
      loadEntity: loadAndSaveEntity,
      validateEntityKeyValues: validateEntityKeyValues,
      saveEntityServer: saveEntityServer,
  }
}

interface ShowServiceProps<TEntity extends Entity> {
  storeName: keyof Omit<IEntityStores, "addNewFindDuplicatesStore" | "getFindDuplicatesStore" >;
  loadEntity: (id: number) => Promise<TEntity>; 
  onEntityCreate: (entity: TEntity) => Promise<SucceededWithDataResult<number>>; 
  onEntityUpdate: (id: number, entity: TEntity) => Promise<SucceededWithDataResult<number>>;
}

export { useShowService, ShowServiceProps };
