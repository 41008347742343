import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import './styles.sass';
import Filter, { FilterType } from '../../../../abstractions/filter';
import { observer } from 'mobx-react-lite';
import { CustomChip } from '../customChip';

interface IFiltersChipsProps<TFilter extends Filter> {
    filters: TFilter,
    resetFilter: (key: keyof TFilter) => void,
    removeFilterValue: (key: keyof TFilter, index: number) => void,
}

//TODO: extract logic to controller 
const FiltersChips = <TFilter extends Filter>({filters, resetFilter, removeFilterValue}: IFiltersChipsProps<TFilter>) => {

    const onFilterDelete = (keyName: string, index: number) => {
        removeFilterValue(keyName, index);
    }

    const onMultipleChipDelete = (keyName: string) => {
        resetFilter(keyName);
    }

    const ChipWithLabel = (label: string, keyName: string, array: any[], canDelete: boolean, valuePresent?: (v: any) => string) => {
        const additionalClass = array.length > 1 ? '' : 'singleChip';
        const chips = createChips(label, keyName, array, canDelete, additionalClass, valuePresent)
        
        if (array.length > 1)
            return (
                <CustomChip onDelete={canDelete ? () => onMultipleChipDelete(keyName) : undefined} >
                    {chips}
                </CustomChip>
            );
        else
            return chips;
            
    }

    const createChips = (label: string, keyName: string, array: any[], canDelete: boolean, className: string, valuePresent?: (v: any) => string) => {
        return array.map((v, i) =>
            <Chip
                clickable
                className={'chip ' + className}
                label={`${label}: ${valuePresent ? valuePresent(v) : v}`}
                onDelete={canDelete ? () => onFilterDelete(keyName, i) : undefined}
                key={keyName + i}
            />
        );
    }

    const filtersForShowChips = Object.entries(filters).filter(([_, {needShowChips, values}]) => needShowChips && values.length !== 0);

    return (
      <Stack flexWrap="wrap" direction="row" spacing={1} className={filtersForShowChips.length !== 0 ? "chipStack" : ""}>
        {
            filtersForShowChips
                .map(([key, {values, label, isStaticFilter, type, valuePresent}]) => {
                    return (
                        <div key={key}>
                            {ChipWithLabel(label ?? key, key, values, !isStaticFilter, valuePresent ?? factoryValuePresent(type, values))}
                        </div>
                    );
                })
        }
      </Stack>
    );
}

const factoryValuePresent = (type: FilterType, values: any[]) => {
    switch (type) {
        case FilterType.Date:
            return (v: any) => new Date(v).toLocaleDateString();
        case FilterType.Number:
            return (v: any) => v.toLocaleString();
        case FilterType.String:
            return (v: any) => v;
        case FilterType.Boolean:
            return (v: any) => v ? 'Да' : 'Нет';
        case FilterType.Currency:
            return (v: any) => v.toLocaleString();
        default:
            return (v: any) => v;
    }
}

export default observer(FiltersChips);