import { DocumentAttribute, DocumentAttributeKind } from '../types/entities/document';
import l from '../helpers/localization';

const localization = l.validation;

export const validate = (attribute: DocumentAttribute, value?: unknown) => {
    const { kind, required } = attribute;
    if (value == null || value == '') {
        if (required)
            return localization.fieldIsRequired;

        return null;   
    }        

    if (isTin(kind))
        return validateTin(value as string);

    if (isSrc(kind))
        return validateSrc(value as string);

    if (isName(kind))
        return validateName(value as string);

    return null;
}

const isTin = (kind: DocumentAttributeKind) => 
    [DocumentAttributeKind.CustomerTin, DocumentAttributeKind.ContractorTin].includes(kind);

const validateTin = (value: string) => {
    if (value.length != 10 && value.length != 12)
        return localization.invalidTinLength;
    
    if (value.split('').some(c => !isDigit(c)))
        return localization.valueMustContainOnlyDigits;

    const getCheckDigit = (coefficients: number[]) => {
        let result = 0;
        for (let i = 0; i < coefficients.length; i++)
            result += coefficients[i] * Number(value[i]);
            
        return (result % 11 % 10).toString();
    }

    if (value.length == 10)
        return getCheckDigit([2, 4, 10, 3, 5, 9, 4, 6, 8]) == value[9] 
            ?  null : localization.invalidTinCheckDigit;

    return value[10] == getCheckDigit([7, 2, 4, 10, 3, 5, 9, 4, 6, 8]) &&
        value[11] == getCheckDigit([3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8])
        ?  null : localization.invalidTinCheckDigit;
}

const isSrc = (kind: DocumentAttributeKind) => 
    [DocumentAttributeKind.CustomerSrc, DocumentAttributeKind.ContractorSrc].includes(kind);

const validateSrc = (value: string) => {
    if (value.length != 9)
        return localization.invalidSrcLength;

    const chars = value.split('');
    if (chars.some(c => !isDigit(c) && !isLetter(c)))
        return localization.invalidSrcContent;

    if (chars.slice(0, 4).some(c => !isDigit(c)))
        return localization.invalidSrcFormat;

    if (chars.slice(6).some(c => !isDigit(c)))
        return localization.invalidSrcFormat;

    return null;
}

const isName = (kind: DocumentAttributeKind) => 
    [DocumentAttributeKind.CustomerName, DocumentAttributeKind.ContractorName].includes(kind);

const validateName = (value: string) => {
    const maxLength = 420;
    return value.length <= maxLength ? null : localization.invalidStringLength.replace('{0}', maxLength.toString());
}

const isDigit = (value: string) => value >= '0' && value <= '9';
const isLetter = (value: string) => value.match(/[a-z]/i);
