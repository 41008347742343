import { makeAutoObservable } from "mobx";
import { ExternalDocument } from "../types/entities/externalDocument";

export class ExternalDocumentStore {
    private _externalDocuments: ExternalDocument[];

    constructor() {
        this._externalDocuments = [];
        makeAutoObservable(this);
    }

    public get externalDocuments() {
        return this._externalDocuments;
    }

    public set externalDocuments(newExternalDocuments: ExternalDocument[]) {
        this._externalDocuments = newExternalDocuments;
    }
}