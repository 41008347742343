import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Button } from '@mui/material';
import { ArrowBack} from '@mui/icons-material';
import './style.sass';
import { useHistory, useParams } from 'react-router-dom';
import './style.sass'
import { AttachedFile, ExternalDocument } from '../../../../types/entities/externalDocument';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

interface IDocumentScrollViewProps {
    document?: ExternalDocument;

    setAttachedFile: (attachedFile: AttachedFile) => void;
    attachedFile: AttachedFile;
}

const DocumentScrollView = (props: IDocumentScrollViewProps) => {
    const { document, attachedFile, setAttachedFile } = props;

    const handleAttachedFileChange = (attachedFile: AttachedFile) => {
        setAttachedFile({ ...attachedFile });
    }

    return (
        <div className="externalDocumentScrollView">
            <fieldset>
                <legend>Прикрепленные файлы</legend>
                <div className="file-list">
                    {
                        document ? 
                        document.attachedFiles.map(f =>
                            <div onClick={() => handleAttachedFileChange(f)} key={f.idAttachment} className={"document-item " + (attachedFile && attachedFile!.idAttachment == f.idAttachment ? "active" : "")}>
                                <div className="flex-item"><PictureAsPdfIcon className="pdf-icon"></PictureAsPdfIcon></div>
                                <div className="document-name">{f.fileName.length <= 40 ? f.fileName : f.fileName.substring(0, 30) + '...'}</div>
                            </div>
                        )
                        : <></>
                    }
                </div>
            </fieldset>
        </div>
    )
}

export default DocumentScrollView