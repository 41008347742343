import { useCallback } from 'react';
import Filter from "../../../common/abstractions/filter";
import EntityListWithFilters from "../../../common/pages/blanks/List";
import { createReferenceFieldFilter } from "../../../common/view/listFiltersView/components/filters/components/referenceFilter/referenceFilter";
import { createRangeDateFilter } from "../../../common/view/listFiltersView/components/filters/components/dateRangeFilter";
import { createCurrencyColumn, createDateColumn, createReferenceColumn, createTextColumn } from "../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../common/controller/entityListController/entityListController";
import Link from "../../../common/components/Link";
import { Button } from "@mui/material";
import { createNumberFilter } from "../../../common/view/listFiltersView/components/filters/components/numberFilter";
import useGoToPageProvider from "../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { createBoolFilter } from "../../../common/view/listFiltersView/components/filters/components/booleanFilter";
import StorageFilter from "../../domain/storage/storageFilter";
import { Document, DocumentStatus, documentStatusCaption } from "../../../types/entities/document";
import ValidationFilter from "../../domain/validation/validationFilter";
import UploadsFilter from "../../domain/uploads/uploadsFilter";
import { GridColTypeDef } from "../../../common/abstractions/gridColumn";
import { createTextFilter } from "../../../common/view/listFiltersView/components/filters/components/textFilter";
import { createSelectFilter } from "../../../common/view/listFiltersView/components/filters/components/selectFilter";
import NoOriginalFilter from "../../domain/noOriginal/noOriginalFilter";
import StaleFilter from "../../domain/stale/staleFilter";
import { IEntityStores } from "../../../stores/entitiesStore";
import { RootApiType } from "../../../api/rootApi";

export type DocumentsListVariant = 'uploads' | 'validation' | 'storage' | 'noOriginal' | 'overdue';

type DocumentsListDescriptor<TFilter extends Filter> = {
    columns: GridColTypeDef[];
    title: string;
    storeName: keyof IEntityStores;
    filter: TFilter;
    loadEntities: keyof RootApiType;
};

const uploadsColumns = [
    {...createReferenceColumn(createTextColumn<Document>('name', 'Организация'), 'organizationId', 'organizations'), width: 130},
    {...createReferenceColumn(createTextColumn<Document>('name', 'Тип'), 'typeId', 'types'), width: 150},
    {...createTextColumn('documentNumber', 'Номер документа'), width: 130},
    {...createDateColumn<Document>('documentDate', 'Дата документа', true), width: 120},
    {...createDateColumn<Document>('createdAt', 'Дата загрузки', true), width: 120},
    {...createTextColumn('contractorName', 'Исполнитель'), width: 200},
    {...createTextColumn('customerName', 'Заказчик'), width: 200},
    createCurrencyColumn('sum', 'Сумма'),
    {...createTextColumn('contractorTin', 'ИНН исполнителя'), width: 130},
    {...createTextColumn('customerTin', 'ИНН заказчика'), width: 130},
];

const uploadsFilters: UploadsFilter = {
    documentNumber: createTextFilter('Номер документа'),
    organizationId: createReferenceFieldFilter('Организация', [], 'organizations'),
    typeId: createReferenceFieldFilter('Тип', [], 'types'),
    documentDateRange: createRangeDateFilter("Дата документа"),
    createdAtRange: createRangeDateFilter("Дата загрузки"),    
    contractorName: createTextFilter('Исполнитель'),
    customerName: createTextFilter('Заказчик'),
    sum: createNumberFilter('Сумма'),
    contractorTin: createTextFilter('ИНН исполнителя'),
    customerTin: createTextFilter('ИНН заказчика'),
    onValidation: createBoolFilter(undefined, [false], false, true, false),
};

const validationColumns = [
    {...createReferenceColumn(createTextColumn<Document>('name', 'Организация'), 'organizationId', 'organizations'), width: 130},
    {...createReferenceColumn(createTextColumn<Document>('name', 'Тип'), 'typeId', 'types'), width: 150},
    {...createTextColumn('status', 'Статус', false, (v) => documentStatusCaption(v.value)), width: 110},
    {...createTextColumn('documentNumber', 'Номер документа'), width: 130},
    {...createDateColumn<Document>('documentDate', 'Дата документа', true), width: 120},
    {...createDateColumn<Document>('createdAt', 'Дата загрузки', true), width: 120},
    {...createTextColumn('contractorName', 'Исполнитель'), width: 200},
    {...createTextColumn('customerName', 'Заказчик'), width: 200},
    createCurrencyColumn('sum', 'Сумма'),
    {...createReferenceColumn(createTextColumn<Document>('name', 'Контрагент'), 'contractorId', 'contractors'), width: 200},
    {...createReferenceColumn(createTextColumn<Document>('tin', 'ИНН контрагента'), 'contractorId', 'contractors'), width: 130},
    {...createReferenceColumn(createTextColumn<Document>('documentNumber', 'Номер связанного договора'), 'contractId', 'documents'), width: 150},
    {...createReferenceColumn(createTextColumn<Document>('name', 'ЦФО'), 'financialResponsibilityCenterId', 'financialResponsibilityCenters'), width: 200},
];

const validationFilters: ValidationFilter = {
    documentNumber: createTextFilter('Номер документа'),    
    organizationId: createReferenceFieldFilter('Организация', [], 'organizations'),
    typeId: createReferenceFieldFilter('Тип', [], 'types'),
    status: createSelectFilter<DocumentStatus>('Статус', [DocumentStatus.Draft, DocumentStatus.Rejected, 
        DocumentStatus.Copy, DocumentStatus.WorkCopy, DocumentStatus.Original], documentStatusCaption),
    documentDateRange: createRangeDateFilter("Дата документа"),
    createdAtRange: createRangeDateFilter("Дата загрузки"),
    contractorName: createTextFilter('Исполнитель'),
    customerName: createTextFilter('Заказчик'),
    sum: createNumberFilter('Сумма'),
    contractorTin: createTextFilter('ИНН исполнителя'),
    customerTin: createTextFilter('ИНН заказчика'),
    contractorId: createReferenceFieldFilter('Контрагент', [], 'contractors'),
    contractId: createReferenceFieldFilter('Номер связанного договора', [], 'documents', 'documentNumber'),
    financialResponsibilityCenterId: createReferenceFieldFilter('ЦФО', [], 'financialResponsibilityCenters'),
    onValidation: createBoolFilter('На валидации', [true], false, true, false),
};

const staleFilters = { 
    ...validationFilters, 
    isStale: createBoolFilter('Просроченные', [true], false, false, false) 
};

const storageColumns = [
    {...createReferenceColumn(createTextColumn<Document>('name', 'Организация'), 'organizationId', 'organizations'), width: 130},
    {...createReferenceColumn(createTextColumn<Document>('name', 'Тип'), 'typeId', 'types'), width: 150},
    {...createTextColumn('status', 'Статус', false, (v) => documentStatusCaption(v.value)), width: 110},
    {...createTextColumn('documentNumber', 'Номер документа', false), width: 130},
    {...createDateColumn<Document>('documentDate', 'Дата документа', true), width: 120},
    createCurrencyColumn('sum', 'Сумма'),
    {...createReferenceColumn(createTextColumn<Document>('name', 'Контрагент'), 'contractorId', 'contractors'), width: 200},
    {...createReferenceColumn(createTextColumn<Document>('tin', 'ИНН контрагента'), 'contractorId', 'contractors'), width: 130},
    {...createReferenceColumn(createTextColumn<Document>('documentNumber', 'Номер связанного договора'), 'contractId', 'documents'), width: 150},
    {...createReferenceColumn(createTextColumn<Document>('name', 'ЦФО'), 'financialResponsibilityCenterId', 'financialResponsibilityCenters'), width: 200},
];

const storageFilters: StorageFilter = {
    documentNumber: createTextFilter('Номер документа'),    
    organizationId: createReferenceFieldFilter('Организация', [], 'organizations'),
    typeId: createReferenceFieldFilter('Тип', [], 'types'),
    status: createSelectFilter<DocumentStatus>('Статус', [DocumentStatus.Draft, DocumentStatus.Rejected, 
        DocumentStatus.Copy, DocumentStatus.WorkCopy, DocumentStatus.Original], documentStatusCaption),    
    documentDateRange: createRangeDateFilter("Дата документа"),
    createdAtRange: createRangeDateFilter("Дата загрузки"),
    sum: createNumberFilter('Сумма'),
    contractorId: createReferenceFieldFilter('Контрагент', [], 'contractors'),
    contractId: createReferenceFieldFilter('Номер связанного договора', [], 'documents', 'documentNumber'),
    financialResponsibilityCenterId: createReferenceFieldFilter('ЦФО', [], 'financialResponsibilityCenters'),
    validated: createBoolFilter(undefined, [true], false, true, false),
    isMainInStack: createBoolFilter('Лучшая копия', [true], true, false, true),
};

const noOriginalFilters = { 
    ...storageFilters, 
    noOriginal: createBoolFilter('Без оригинала', [true], false, false, false) 
};

function useDescriptorForVariant<TFilter extends Filter>(variant: DocumentsListVariant): DocumentsListDescriptor<TFilter> {

    if (variant === 'uploads')
        return { columns: uploadsColumns, storeName: "uploadsStore",  title: "Черновики", filter: (uploadsFilters as unknown) as TFilter, loadEntities: "documents" };

    if (variant === 'validation')
        return { columns: validationColumns, storeName: "validationStore", title: "Документы на валидации", filter: (validationFilters as unknown) as TFilter, loadEntities: "documents" };

    if (variant === 'storage')
        return { columns: storageColumns, storeName: "storageStore", title: "Хранилище документов", filter: (storageFilters as unknown) as TFilter, loadEntities: "documents" };

    if (variant === 'noOriginal')
        return { columns: storageColumns, storeName: "noOriginalsStore", title: "Без оригинала", filter: (noOriginalFilters as unknown) as TFilter, loadEntities: "documents" };

    if (variant === 'overdue')
        return { columns: storageColumns, storeName: "stalesStore", title: "Просроченные", filter: (staleFilters as unknown) as TFilter, loadEntities: "documents" };

    throw Error('unknown variant');
}

const UploadsStorageListView = () => {
    const { goToUploadDocument } = useGoToPageProvider();
    const button = <Button
        size="small"
        variant="contained"
        onClick={() => goToUploadDocument()}
    >
        Добавить
    </Button>;

    return <DocumentsStorageListViewInner<UploadsFilter> variant='uploads' button={button} />;
}

const ValidationsStorageListView = () => {
    return <DocumentsStorageListViewInner<ValidationFilter> variant='validation' />;
}

const StorageListView = () => {
    return <DocumentsStorageListViewInner<StorageFilter> variant='storage' />
}

const NoOriginalListView = () => {
    return <DocumentsStorageListViewInner<StorageFilter> variant='noOriginal' />
}

const StaleListView = () => {
    return <DocumentsStorageListViewInner<StorageFilter> variant='overdue' />
}

const DocumentsStorageListViewInner =
    <TFilter extends Filter>({ variant, button } : {
        variant: DocumentsListVariant,
        button?: JSX.Element
    }) => {
        const { columns, storeName, title, filter, loadEntities } = useDescriptorForVariant<TFilter>(variant);

        return (
            <EntityListWithFilters<Document, TFilter>
                ListHeaderView={<Link title={title} />}
                listControllerProps={{columns, loadEntities}}
                ListActions={button}
                useListController={createController<TFilter>(variant)}
                storeName={storeName}
                filterAttributes={filter}
            />
        );
    }

const useGetGoToPageForVariant = (variant: DocumentsListVariant) => {
    const { goToShowDocument, goToShowValidation, goToUploadDocument } = useGoToPageProvider();

    if (variant === 'uploads')
        return goToUploadDocument;

    if (variant === 'validation')
        return goToShowValidation;

    if (variant === 'storage')
        return goToShowDocument;

    if (variant === 'noOriginal')
        return goToShowDocument;
    
    if (variant === 'overdue')
        return goToShowDocument;

    throw Error('unknown variant');
}

function createController<TFilter extends Filter>(variant: DocumentsListVariant) {
    return (props: ListControllerProps<Document, TFilter>): ListControllerReturnType<Document> => {
        const listProps = useListController(props);
        const goToPage = useGetGoToPageForVariant(variant);

        const onRowClick = useCallback((props: GridRowParams<Document>) => {
            const { id } = props.row;
            goToPage(id);
        }, [goToPage]);

        return {
            ...listProps,
            onRowClick: onRowClick,
        } as ListControllerReturnType<Document>;
    };
}

export { UploadsStorageListView, ValidationsStorageListView, StorageListView, NoOriginalListView, StaleListView };