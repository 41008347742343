import { makeObservable } from "mobx";
import { FinancialResponsibilityCenter } from "../../../types/entities/financialResponsibilityCenter";
import Filter from "../../abstractions/filter";
import Store from "../Store";

export default class FrcStore extends Store<FinancialResponsibilityCenter, Filter> {
    constructor(gridId: string) {
      super(gridId);
      makeObservable(this, {});
    }
  }