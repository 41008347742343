import React, { useState } from "react";
import { Button, Card, CardActions, CardContent, TextField } from "@mui/material";
import './style.sass';
import Logo from '../../../styling/img/logo.svg';
import { useHistory } from "react-router-dom";
import localization from "../../../helpers/localization";
import api from "../../../api";
import { UserData } from "../../../helpers/userData";
import Routes from "../../../router/routes";
import { SignInOutput } from "../../../api/types";
import useGoToPageProvider from "../../../router/goToPageProvider";

const SignIn: React.FC = (props) => {
    const [login, setLogin] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const history = useHistory();
    const { goToForgotPassword } = useGoToPageProvider();

    const signInUser = async () => {
        setErrorMessage(undefined);

        const result = await api.account.signIn(login as string, password!);
        if (result.succeeded) {
            UserData.setData(result.accessToken!, result.userModel!);

            history.push(Routes.uploads);
            return;
        }

        setErrorMessage(getErrorMessage(result));
    }

    const getErrorMessage = (result: SignInOutput) => {
        const loc = localization.account;
        const { isBlocked, isLockedOut } = result;

        if (isBlocked)
            return loc.accountBlocked;

        if (isLockedOut)
            return loc.accountLockedOut;

        return loc.invalidPassword;
    }

    return (
        <div className='signIn'>
            <img src={Logo} />
            <form onSubmit={(e) => {
                e.preventDefault();
                signInUser();
            }}>
                <Card className='signIn_card'>
                    <CardContent>
                        <TextField
                            className='signIn_input'
                            label="Почта"
                            type='email'
                            onChange={e => setLogin(e.target.value)}
                        />
                        <TextField
                            className='signIn_input'
                            label="Пароль"
                            type='password'
                            onChange={e => setPassword(e.target.value)}
                        />
                        {errorMessage != null ? <span style={{ display: 'block' }}>{errorMessage}</span> : null}
                        <a className='signIn_a' href='#' onClick={() => goToForgotPassword()}>Забыли пароль?</a>
                    </CardContent>
                    <CardActions>
                        <Button
                            className='signIn_button'
                            size="small"
                            type="submit"
                        >
                            Войти
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    )
}

export default SignIn;