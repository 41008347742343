import { useEffect, useState } from 'react';
import { Button,  CircularProgress, Divider, TextField, Typography } from '@mui/material';
import './style.sass';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import { ChevronLeft, ChevronRight, SkipNext, SkipPrevious } from '@mui/icons-material';
import api from '../../../../../api';

const PdfViewer = (props: any) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumberText, setPageNumberText] = useState<string | number>('1');
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number}) {
      setNumPages(numPages);
    }
    const isMoveBackDisabled = pageNumber === 1;
    const isMoveForwardDisabled = pageNumber === numPages;
    const isOnePageFile = numPages === 1;

    useEffect(() => {
        if (Number.isInteger(pageNumberText))
            setPageNumber(pageNumberText as number);

    }, [pageNumberText]);

    const [showAll, setShowAll] = useState(false);

    const downloadFile = async () => {
        console.log(props);
        const { file, filename } = await api.documents.getDownloadFile(props.fileId);
        require("downloadjs")(file, filename);
    }

    return (
        <div className='file-container'>
            <div className='pagination-container'>
                <div className="pagination">
                    <Button onClick={() => setPageNumberText(1)} disabled={isMoveBackDisabled} ><SkipPrevious /></Button>
                    <Button onClick={() => setPageNumberText(pageNumber - 1)} disabled={isMoveBackDisabled} ><ChevronLeft /></Button>
                    <p>
                        Страница 
                        <input 
                            type='text' 
                            className='page-input' 
                            onChange={(e) => {
                                const page = parseInt(e.target.value);
                                if ((page && page > 0 && page <= numPages))
                                    setPageNumberText(page)
                                if (e.target.value === '')
                                    setPageNumberText('');
                            }}
                            value={pageNumberText}
                            disabled={isOnePageFile}
                        />
                        из {numPages}
                    </p>
                    <Button onClick={() => setPageNumberText(pageNumber + 1)} disabled={isMoveForwardDisabled} ><ChevronRight /></Button>
                    <Button onClick={() => setPageNumberText(numPages)} disabled={isMoveForwardDisabled} ><SkipNext /></Button>
                    <Divider variant='middle' orientation='vertical' flexItem  />
                    <div className='show_all-checkbox-container'>
                        <Typography >Показать все</Typography> 
                        <input type="checkbox" onChange={() => setShowAll(!showAll)} value={showAll as any} disabled={isOnePageFile} />
                    </div>
                    
                    <Divider variant='middle' orientation='vertical' flexItem  />
                    <Button className="downloadButton" onClick={downloadFile}>Скачать</Button>
                </div>
            </div>
            <div className="file">
                <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess} className="pdf_viewer-component" loading={<CircularProgress />} >
                    <Outline />
                    {!showAll ? 
                        <Page pageNumber={pageNumber} loading={<CircularProgress />} /> 
                        :  
                        Array.from({ length: numPages }, (_, i) => <Page pageNumber={i + 1} loading={<CircularProgress />} />)
                    }
                </Document>
            </div>
        </div>
    );
}

export default PdfViewer;