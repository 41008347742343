import { Box, Chip, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from "@mui/material";

const EnumEditor = ({dictionary, onChange, values = [], errorMessage, disabled}: {dictionary: EnumEditorDictioanry, onChange: (values: number[]) => void, values: number[], errorMessage?: string, disabled?: boolean}) => {
    const theme = useTheme();
  
    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      onChange((value as string[]).map(name => dictionary.find(item => item.value === name)!.key));
    };

    const getStyles = (name: string) => {
        const choosen = values.indexOf(dictionary.find(({value}) => value === name)!.key) !== -1;
        return {
            fontWeight:
                choosen
                    ? theme.typography.fontWeightMedium
                    : theme.typography.fontWeightRegular,
            backgroundColor:
                choosen
                    ? theme.palette.action.selected
                    : 'transparent',
        };
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
      <div>
        <FormControl sx={{ m: 1, width: 300 }}>
          <Select<string[]>
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={values.map(value => dictionary.find(item => item.key === value)!.value)}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((name) => (
                        <Chip key={dictionary.find(item => item.value === name)!.key} label={name} />
                    ))}
                </Box>
            )}
            MenuProps={MenuProps}
            disabled={disabled}
            error={errorMessage != null}
        >
            {dictionary.map(({key, value}) => (
              <MenuItem
                key={value}
                value={value}
                style={getStyles(value)}
              >
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
}

type EnumEditorDictioanry = {key: number, value: string}[];

export default EnumEditor;