import { DataGridProProps, GridValidRowModel, ruRU, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { isObservableObject, toJS } from 'mobx';
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useApi } from '../../../api';
import { RootApiType } from '../../../api/rootApi';
import { getMany } from '../../../api/types/getFunctions';
import { EntityStoresContext, IEntityStores } from '../../../stores/entitiesStore';
import { Entity } from '../../../types/entities/entity';
import { GridColTypeDef } from '../../abstractions/gridColumn';
import { BaseListService } from "../../abstractions/listService";
import IStore from '../../infrastructure/BaseStore';
import { BaseListServiceType } from '../../services/listService';
import LocalStorageService from '../../services/localStorageService';
import { useEffectLoadingEntities } from './hooks/loadingEntities';

const useBaseListController = <
  TEntity extends Entity,
  TGetMany extends Function = getMany<TEntity>,
  TListService extends ReturnType<BaseListServiceType<TEntity, TGetMany>> = ReturnType<BaseListServiceType<TEntity, TGetMany>>,
  TStore extends IStore<TEntity> = IStore<TEntity>
>
({
  storeName, loadEntities, overrideService, 
  listService, columns, needSkipFirstRender
}: BaseListControllerProps<TEntity, TGetMany, TListService, TStore>): BaseListControllerReturnType<TEntity, TListService> => {
  const api = useApi();
  const context = useContext(EntityStoresContext);

  const store = typeof storeName === "string" ? context[storeName as keyof IEntityStores] as unknown as TStore : storeName(context);
  let service = useMemo(() => overrideService ? overrideService(listService(store, loadEntities, api)) : listService(store, loadEntities, api), [api, listService, loadEntities, overrideService, store]);

  const localStorageService = useMemo(() => new LocalStorageService(), []);
  const savedGridsSettings = useMemo(() => localStorageService.get("gridsSettings"), [localStorageService]);
  const savedGridSettings = useMemo(() => savedGridsSettings?.[service.gridId], [savedGridsSettings, service.gridId]) as GridInitialStatePro | undefined;
  
  const entities = useMemo(() => service.entities, [service.entities]);

  const deps = JSON.stringify({entities, subEntities: service.subEntities});

  const createReferenceColumnName = (field: string, route: string) => `${field}_${route}`;

  const getRows = useMemo(() => {
    if (!entities)
      return [];

    return [...entities].map((entity: TEntity) => {
      const rowMoel = (isObservableObject(entity) ? toJS(entity) : entity) as unknown as GridValidRowModel;

      columns.filter(c => c.isReference).forEach(col => {
        if (!col.route || !col.referenceIdField)
          throw new Error('Reference column must have route and referenceIdField, trouble with column ' + JSON.stringify(col));

        const subEntities = service.subEntities[col.route];

        const referenceColumnFieldName = createReferenceColumnName(col.field, col.route) as keyof GridValidRowModel;
        if (!subEntities || subEntities.length === 0)
          return rowMoel[referenceColumnFieldName] = rowMoel[col.referenceIdField!];

        const subEntity = subEntities.find(e => e.id === rowMoel[col.referenceIdField!]);
        // todo: refactoring with GridValidRowModel
        if (!subEntity)
          rowMoel[referenceColumnFieldName] = "" as any;
        else if (col.renderCell)
          rowMoel[referenceColumnFieldName] = subEntity as any;
        else
          rowMoel[referenceColumnFieldName] = subEntity ? subEntity[col.field] : "";
      });
      
      return rowMoel;
    });
  }, [deps]);

  const loading = useEffectLoadingEntities({service, columns, needSkipFirstRender: needSkipFirstRender ? needSkipFirstRender(service) : undefined});

  const modifyColumns = useMemo((): GridColTypeDef[] => {
    return columns.map(col => { 
      if (col.isReference) {
        const {field, ...rest } = col;
        if (!col.route)
          throw new Error(`Reference column ${col.field} has no route`);

        return {...rest, field: createReferenceColumnName(col.field, col.route) };
      }

      return col;
    });
  }, [columns]);

  const gridApiRef = useGridEvents(localStorageService, service.gridId);

  const localText = useMemo(() => ruRU.components.MuiDataGrid.defaultProps.localeText, []);
  const totalCount = useMemo(() => service.totalCount, [service.totalCount]); 
  const defaultProps = useMemo(() => ({
    autoHeight: true,
    rowHeight: 32,
    headerHeight: 36,
    localeText: localText,
  }), [])

  const initialState = useMemo(() => ({
    ...savedGridSettings, 
    columns: { 
      ...savedGridSettings?.columns, 
      columnVisibilityModel: {
        ...savedGridSettings?.columns?.columnVisibilityModel ?? {}
      },
      orderedFields: savedGridSettings?.columns?.orderedFields,        
    },
  }), [savedGridSettings]);


  return {
    apiRef: gridApiRef,
    rows: getRows as TEntity[],
    rowCount: totalCount,
    columns: modifyColumns,
    loading,
    ...defaultProps,
        
    // if not manually pass the `columnVisibilityModel` it will not be exported from `gridApi.exportState` :D https://mui.com/x/react-data-grid/state/#save-and-restore-the-state
    initialState: initialState,
    service,
  }
}

const useGridEvents = (localStorageService: LocalStorageService, gridId: string) => {
  const gridApiRef = useGridApiRef();
  const gridApi = gridApiRef.current;

  useEffect(() => {
    if (Object.keys(gridApi).length === 0)
      return;

    gridApi.subscribeEvent('columnOrderChange', () => {
      saveSettings();
    });

    gridApi.subscribeEvent('columnWidthChange', () => {
      saveSettings();
    });

    gridApi.subscribeEvent('columnVisibilityModelChange', (m) => {
      saveSettings();
    });

    gridApi.subscribeEvent('pinnedColumnsChange', () => {
      saveSettings();
    });
  }, [gridApi]);

  const saveSettings = () => {
    const { preferencePanel, ...state} = gridApi.exportState();
    const gridsSettings = localStorageService.get("gridsSettings");

    if (gridsSettings) {
      gridsSettings[gridId] = state;
      localStorageService.set("gridsSettings", gridsSettings);
    }
    else
      localStorageService.set("gridsSettings", { [gridId]: state });
  }
  
  return gridApiRef;
}

interface BaseListControllerProps<
  TEntity extends Entity, 
  TGetMany extends Function = getMany<TEntity>,
  TListService extends ReturnType<BaseListServiceType<TEntity, TGetMany>> = ReturnType<BaseListServiceType<TEntity, TGetMany>>,
  TStore extends IStore<TEntity> = IStore<TEntity>
> extends BaseListControllerWithColumnsProps {
  needSkipFirstRender?: (service: TListService) => boolean;
  listService: BaseListServiceType<TEntity, TGetMany, TStore, TListService>;
  storeName: ((context: IEntityStores) => TStore) | keyof IEntityStores;
  loadEntities: TGetMany | keyof RootApiType;
  overrideService?: (service: TListService) => TListService;
}

interface BaseListControllerWithColumnsProps {
  columns: GridColTypeDef[];
}

interface BaseListControllerReturnType<TEntity extends Entity, TListService extends BaseListService<TEntity> = BaseListService<TEntity>> 
  extends Pick<DataGridProProps<TEntity>, 
  "rows" | "rowCount" | "columns" | 
  "loading" | "autoHeight" | "rowHeight" | 
  "headerHeight" | "localeText" | "apiRef" |
  "initialState" | "components" | "componentsProps"
>
{
  service: TListService;
}

export { useBaseListController, BaseListControllerProps, BaseListControllerReturnType, BaseListControllerWithColumnsProps };
