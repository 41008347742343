import { makeAutoObservable } from "mobx";
import { Pagination } from "../types/pagination";
import { DocumentFiltersChips, DocumentFilters } from "../types/documentsFilters";
import { Document } from "../types/entities/document";

export class UploadedFileStore {
    private _Documents: Document[];
    private _pagination: Pagination;
    private _totalCount: number;
    private _filters: DocumentFiltersChips;
    private _currentEditingFilters: DocumentFilters;
    private _currentDocument?: number;

    constructor() {
        makeAutoObservable(this);
        this._Documents = new Array<Document>();
        this._pagination = {page: 0, perPage: 5};
        this._totalCount = 0;
        this._filters = {};
        this._currentEditingFilters = {};
    }

    public get Documents () {
        return this._Documents;
    }

    public set Documents (newDocuments: Document[]) {
        this._Documents = newDocuments; 
    }

    public get pagination () {
        return this._pagination;
    }

    public set pagination (newPagination: Pagination) {
        this._pagination = newPagination; 
    }

    public get totalCount () {
        return this._totalCount;
    }

    public set totalCount (newTotalCount: number) {
        this._totalCount = newTotalCount;
    }

    public get filters () {
        return this._filters;
    }

    public set filters (newFilters: DocumentFiltersChips) {
        this._filters = newFilters;
    }

    public get currentEditingFilters () {
        return this._currentEditingFilters;
    }

    public set currentEditingFilters (newFilters: DocumentFilters) {
        this._currentEditingFilters = newFilters;
    }

    public get currentDocument () {
        return this._currentDocument;
    }

    public set currentDocument (id: number | undefined) {
        this._currentDocument = id;
    }
}