import { useEffect, useState } from "react";

// кастомный хук, для отложенного изменения значения, например, для autocomplete компонентов, строк поиска и т.д.
export default function useDebounce(value: any, delay: number) {
  // Состояние и сеттер для отложенного значения
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [value]
  );

  return debouncedValue;
}