import { GridSortModel } from "@mui/x-data-grid-pro";
import { Sorting, SortingOrder } from "../../../../api/types/getFunctions";
import { Entity } from "../../../../types/entities/entity";
import Filter from "../../../abstractions/filter";
import ListService from "../../../abstractions/listService";
import { FilterService } from "../../../services/filterService";

const needSetFilterAttributes = <TFilter extends Filter>(service: FilterService<TFilter>, filterAttributes?: TFilter) => {
  return filterAttributes !== undefined && Object.keys(service.filters).length === 0;
}

const needSkipFirstRender = <TEntity extends Entity, TFilter extends Filter>(service: ListService<TEntity, TFilter>, filterAttributes?: TFilter) => {
  return filterAttributes !== undefined && Object.values(filterAttributes).some(a => a.values.length !== 0) && Object.keys(service.filters).length === 0; 
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function decreaseFirstLater(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function mapSortModel(model: GridSortModel) : Sorting[] {
  return model.map(({ field, sort }) => ({ field: capitalizeFirstLetter(field), order: sort === "asc" ? SortingOrder.Asc : SortingOrder.Desc }) as Sorting);
}

function mapSortingToSortModel(sortings: Sorting[]): GridSortModel {
  return sortings.map(({ field, order }) => ({ field: decreaseFirstLater(field), sort: order === SortingOrder.Asc ? "asc" : "desc" }));
}

export { needSetFilterAttributes, mapSortModel, mapSortingToSortModel, needSkipFirstRender, capitalizeFirstLetter };
