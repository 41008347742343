import { Button } from "@mui/material";
import CompareIcon from '@mui/icons-material/Compare';
import './style.sass';
import useGoToPageProvider from "../../../router/goToPageProvider";
import { FC } from "react";

const GoToCompareDocsButton: FC<GoToCompareDocsButtonProps> = ({documentId, compareDocumentId, canBind}) => {
  const { goToCompareDocuments } = useGoToPageProvider();
  
  return (
    <Button
      variant="outlined"
      className="document_compare_button"
      onClick={() => goToCompareDocuments(documentId, compareDocumentId, canBind)}
      endIcon={<CompareIcon />}
    >
      Сравнить
    </Button>
    );
}

interface GoToCompareDocsButtonProps {
  documentId: number;
  compareDocumentId: number;
  canBind: boolean;
}

export default GoToCompareDocsButton;