import { observer } from "mobx-react-lite";
import { cloneElement, isValidElement, ReactElement } from "react";
import Filter from "../../../../abstractions/filter";
import factoryFilters from "./filtersFactory";

const Filters = <TFilter extends Filter>({ filters, customeFiltersComponents, updateFilter } : FiltersProps<TFilter>) => {
  const needShowFilters = Object.keys(filters).filter(k => filters[k].needShowFilter);
  
  return (
    <div className='filter_flex'>
      {needShowFilters.map(k => {
          const filter = filters[k as keyof TFilter];
          return factoryFilters<TFilter>(filter, k, updateFilter);
        })
      }
      {isValidElement(customeFiltersComponents) && cloneElement<CustomFiltersComponentsProps<TFilter>>(customeFiltersComponents, { filters, updateFilter })}
    </div>
  );
};

export default observer(Filters);


interface FiltersProps<TFilter extends Filter> {
  filters: TFilter;
  customeFiltersComponents?: ReactElement<CustomFiltersComponentsProps<TFilter>>;
  updateFilter: (key: keyof TFilter, value: any, customUpdate?: (filter: Filter, key: keyof TFilter, value: any) => void) => void;
}

export interface CustomFiltersComponentsProps<TFilter extends Filter> {
  filters: TFilter,
  updateFilter: (key: keyof TFilter, value: any) => void,
}