import * as React from 'react';
import { useState } from 'react';
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import RequirementsTable from "./RequirementsTable";
import ParagraphsTable from "./ParagraphsTable";
import './style.sass';
import { Close, NavigateBefore } from '@mui/icons-material';
import api from '../../../api';
import { Requirement } from '../../../types/entities/requirement/requirement';

export interface AddDocumentsDialogProps {
  open: boolean;
  onClose: () => void;

  documentId: number;
}

export default function AddDocumentsDialog(props: AddDocumentsDialogProps) {
  const { onClose, open, documentId } = props;
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  
  const [requirement, setRequirement] = useState<Requirement>();
  const [paragraphId, setParagraphId] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const addDocumentToParagraph = async (paragraphId: number, documentId: number) => {
    if (loading)
      return;

    setLoading(true);
    setActiveStep(2);

    try {
      await api.requirementParagraphs.addDocuments(paragraphId, [documentId])
    }
    finally {
      setLoading(false);
      handleClose();
    }
  }

  const handleClose = () => {
      onClose();
      setStep(0);
      setActiveStep(0);
      setRequirement(undefined);
      setParagraphId(0);
  }

  const [step, setStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const nextStep = () => {
    setStep(1);
    setActiveStep(1);
  }

  const steps = [
    <RequirementsTable
      setRequirement={setRequirement}
      attachedDocumentId={documentId}></RequirementsTable>,

    <ParagraphsTable
      requirement={requirement}
      setParagraphId={setParagraphId}></ParagraphsTable>
  ]

  const stepLabels = [
    'Выберите требование',
    'Выберите пункт'
  ]

  return (
    <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px",
            },
          },
        }}
        className="add_documents_dialog"
    >
        <DialogTitle><b>Добавить к требованию ФНС</b> <Close onClick={handleClose} /></DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <Box sx={{ width: '100%' }} className="add-document-stepper">
            <Stepper activeStep={activeStep} alternativeLabel>
              {stepLabels.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          
          <div className="content">{steps[step]}</div>
          
        </DialogContent>
        <DialogActions>
            <Button disabled={!step} color="secondary" onClick={() => { setStep(0); setActiveStep(0); setRequirement(undefined); setParagraphId(0) }}><NavigateBefore /> Назад</Button>
            
            {step == 0 ?
            <Button disabled={!requirement} 
            variant="contained"
            onClick={() => nextStep()}>Далее</Button>
            : <></>
            }
            {step == 1 ?
            <Button disabled={!paragraphId || !step || loading}
            onClick={() => addDocumentToParagraph(paragraphId, documentId)}
            variant="contained">{ !loading ? 'Добавить' : <CircularProgress size={25}></CircularProgress>}</Button>
            : <></>}
        </DialogActions>
    </Dialog>
  );
}