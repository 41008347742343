import { Entity } from "../../../../types/entities/entity";
import EntityShowView, { EntityShowViewProps } from "../../../view/entityShowView";

interface EntityShowProps<TEntity extends Entity> extends EntityShowViewProps<TEntity> { }

const EntityShowComponent = <TEntity extends Entity>(props: EntityShowProps<TEntity>) => {
    return (
        <div className='flex'>
            <div className='flex-component'>
                <EntityShowView<TEntity>
                    {...props}
                />
            </div>
        </div>
    );
};

export default EntityShowComponent;