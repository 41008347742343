import { HistoryChange } from "../types/entities/historyOperation";
import Api from "./api";
import { getMany, getOne } from "./types/getFunctions";

export type HistoryChangesApiType = {
    getOne: getOne<HistoryChange>;
    getMany: getMany<HistoryChange>;
};

export class HistoryChangesApi extends Api implements HistoryChangesApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'historyChanges'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal;
    getMany = this.getManyInternal;
};