import { Button, CircularProgress, Dialog, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import api from '../../../api';
import { Requirement } from '../../../types/entities/requirement/requirement';
import './style.sass';

export enum RequirementAction {
    SendToArchive = "Отправить в архив",
    ResumeWork = "Возообновить работу"
}

export interface RequirementActionDialogProps {
    open: boolean;
    onClose: () => void;

    requirement: Requirement;
    action: RequirementAction;

    updatePage: (requirementId: number) => void;
}
  
export default function SendToArchiveDialog(props: RequirementActionDialogProps) {
    const { onClose, open, requirement, action, updatePage } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        onClose();
    };

    const sendToArchive = async (id: number) => {
        setLoading(true);
        try {
            await api.requirements.sendToArchive(id);
        }
        finally {
            setLoading(false);
            onClose();
            updatePage(id);
        }
    }

    const resumeWork = async (id: number) => {
        setLoading(true);
        try {
            await api.requirements.resumeWork(id);
        }
        finally {
            setLoading(false);
            onClose();
            updatePage(id);
        }
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{action == RequirementAction.SendToArchive ? 'Отправка в архив' : 'Возообновление работы'}</DialogTitle>
            <div className="dialog_content">
                <div className="warning">
                <h3>{action == RequirementAction.SendToArchive
                ? 'Вы уверены, что хотите отправить требование в ахрив?'
                : 'Вы уверены, что хотите возообновить работу требования?'}</h3>
                </div>

                <div className="dialog_actions">
                <Button color="secondary" onClick={() => onClose()}>Отмена</Button>
                <Button variant="contained"
                onClick={async () => {
                    switch (action) {
                        case RequirementAction.SendToArchive:
                            await sendToArchive(requirement!.id);
                            break;
                        case RequirementAction.ResumeWork:
                            await resumeWork(requirement!.id);
                            break;
                    }
                }}
                disabled={loading}>{!loading ? action : <CircularProgress size={25}></CircularProgress>}</Button>
                </div>
            </div>
        </Dialog>
    )
}