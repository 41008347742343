import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import NoOriginalFilter from "../../domain/noOriginal/noOriginalFilter";
import { Document } from "../../../types/entities/document";

export default class NoOriginalsStore extends Store<Document, NoOriginalFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}