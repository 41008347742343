import { TextField } from "@mui/material";
import { useState } from "react";
import { FilterProps } from ".";
import Filter, { FilterAttributes, FilterType } from "../../../../../abstractions/filter";

const TextFilter = <TFilter extends Filter>({filterKey, attributes, updateFilter}: FilterProps<String, TFilter>) => {
  const { label } = attributes;
  const [value, setValue] = useState<string>("");

  return (
    <TextField
        label={label}
        variant="standard"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
          if (!value)
            return;

          setValue("");
          updateFilter(filterKey, value);
        }}
    />
  );
}

export default TextFilter;

export const createTextFilter = (label?: string, defaultValues: string[] = [], needShowFilter: boolean = true, 
  isStaticFilter: boolean = false, needShowChips: boolean = true): FilterAttributes<string> => ({
  label,
  needShowFilter,
  values: defaultValues,
  isStaticFilter,
  type: FilterType.String,
  needShowChips
});
