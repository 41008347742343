import { useApi } from "../../../../api";
import { RootApiType } from "../../../../api/rootApi";
import { SucceededWithDataResult } from "../../../../api/types/succeededResult";
import EntityView from "../../../../common/pages/blanks/Show";
import { DataType } from "../../../../types/dataType";
import { Organization } from "../../../../types/entities/organization";

const OrganizationShowView: React.FC = () => {
  const api = useApi();

  return (
      <div>
          <EntityView<Organization>
              showServiceProps={{storeName: "organizationsStore", loadEntity: getOrganizationById(api), onEntityCreate: saveOrganization(api), onEntityUpdate: updateOrganization(api)}}
              fields={[
                {source: 'name', label: 'Название', required: true, type: DataType.String},
                {source: 'tin', label: 'Инн', required: true, type: DataType.String},
              ]}
          />
      </div>
  )
}

//todo: refactor this. Need hide this methods inside EntityShowView, cuz it can be replaced with generics methods
const getOrganizationById = (api: RootApiType) => async (id: number): Promise<Organization> => {
  const organization = await api.organizations.getOne(id);
  if (!organization)
    throw new Error("Организация не найдена");

  return organization;
}

const saveOrganization = (api: RootApiType) => async (organization: Organization): Promise<SucceededWithDataResult<number>> => {
  return await api.organizations.create(organization);
}

const updateOrganization = (api: RootApiType) => async (id: number, organization: Organization): Promise<SucceededWithDataResult<number>> => {
  return await api.organizations.update(id, organization);
}

export default OrganizationShowView;