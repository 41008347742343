import { useEffect, useState } from "react";
import api from "../../../api";
import { GetManyApi, SortingOrder } from "../../../api/types/getFunctions";
import Filter from "../../abstractions/filter";
import useDebounce from "../../utils/hooks/debounce";
import { FilterProps } from "../../view/listFiltersView/components/filters/components";

const useReferenceFilter = <TFilter extends Filter>({attributes, filterKey, updateFilter}: FilterProps<number, TFilter>) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<{[k: string]: any;}>({});
  const [inputValue, setInputValue] = useState<string>("");
  const { label, captionField, fieldForOrder, route, getCaption } = attributes;
  const [loading, setLoading] = useState(false);
  const debInputValue  = useDebounce(inputValue, 275);

  useEffect(() => {
    (async () => {
      if (!open)
        return;
    
      if (!(captionField || getCaption) || !fieldForOrder)
        throw new Error(`nameFieldForSearch: "${captionField}" and fieldForOrder: "${fieldForOrder}" are required for reference filter: ` + filterKey.toString());

      setLoading(true);

      const sort = [{field: capitalizeFirstLetter(fieldForOrder), order: SortingOrder.Asc }];
      if (!route)
        throw new Error("Route is not defined for reference filter");

      const client = api[attributes.route!] as unknown as GetManyApi<any>;
      if (client.getMany === undefined)
        throw new Error(`Can't load sub entities for filter ${attributes.route}, because it doesn't have getMany method in api`);

      const result = await client.getMany(undefined, 100, sort, undefined, undefined, debInputValue);

      setOptions(result.items.map((entity: {[k: string]: any}) => ({ value: entity.id, label: getCaption ? getCaption(entity) : entity[captionField!]})));
  
      setLoading(false);
      
    })();
  },
  [debInputValue, captionField, open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChange = (entity?: {[k: string]: any}) => {
    if (!entity)
      return;

    setValue({});
    updateFilter(filterKey, entity.value as number);
  }

  return {
    open,
    setOpen,
    options,
    value,
    onChange,
    inputValue,
    setInputValue,
    loading,
    label,
    captionField,
  }
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default useReferenceFilter;