import { makeAutoObservable } from "mobx";
import { Document } from "../types/entities/document";

export class NoOriginalStore {
    private _noOriginals: Document[];

    constructor() {
        this._noOriginals = [];
        makeAutoObservable(this);
    }

    public get noOriginals() {
        return this._noOriginals;
    }

    public set noOriginals(noOriginals: Document[]) {
        this._noOriginals = noOriginals;
    }
}