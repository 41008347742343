import * as React from 'react';
import {
    DataGridPro,
    GridRowsProp,
    DataGridProProps,
} from '@mui/x-data-grid-pro';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useApi } from '../../../../../../api';
import { HistoryChange, HistoryOperation } from '../../../../../../types/entities/historyOperation';
import { formatDateTime } from '../../../../../../helpers/formatters';
import './style.sass';

export const ChangesModal: React.FC<{ open: boolean, setOpen: (open: boolean) => void, documentId?: number }> =
    ({ open, setOpen, documentId }) => {
        const api = useApi();
        const [operations, setOperations] = React.useState<HistoryOperation[]>();

        const initialize = async () => {
            const { operations } = await api.documents.getHistory(documentId!);

            setOperations(operations);
        }

        React.useEffect(() => {
            if (operations == null && open && documentId != null)
                initialize();

        }, [open, documentId]);

        const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] =
            React.useCallback(({ row }) => <EntitiesList changes={row.changes} />, []);

        return <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth='xl'
            className="changesList"
        >
            <DialogTitle>
                История изменений
            </DialogTitle>
            <DialogContent>
                <div style={{ height: 700, width: '100%' }}>
                    <DataGridPro
                        rows={operations as GridRowsProp}
                        getDetailPanelHeight={(params) => params.row.changes.length <= 5 ? (params.row.changes.length + 2) * 53 : 500}
                        columns={[
                            { field: 'timestamp', headerName: 'Дата', width: 200, type: 'date', valueGetter: (v) => formatDateTime(v.value) },
                            { field: 'issuer', headerName: 'Исполнитель', width: 200, type: 'text' },
                            { field: 'name', headerName: 'Операция', width: 200, type: 'text' },
                            { field: 'paramsDescription', headerName: 'Подробности', width: 500, type: 'text' },
                        ]}
                        getDetailPanelContent={getDetailPanelContent}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>OK</Button>
            </DialogActions>
        </Dialog>;
    }

const EntitiesList: React.FC<{ changes: HistoryChange[] }> = ({ changes }) => {
    return <DataGridPro
        rows={changes as GridRowsProp}
        columns={[
            { field: 'entity', headerName: 'Сущность', width: 200, type: 'text' },
            { field: 'entityId', headerName: 'Идентификатор', width: 200, type: 'text' },
            { field: 'property', headerName: 'Поле', width: 200, type: 'text' },
            { field: 'oldValue', headerName: 'Было', width: 200, type: 'text' },
            { field: 'newValue', headerName: 'Стало', width: 200, type: 'text' },
        ]}
    />
}
