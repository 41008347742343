import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import Contractor from "../../../domain/contractor/contractorEntity";
import ContractorFilter from "../../../domain/contractor/contractorFilter";
import { createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import Link from "../../../../common/components/Link";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import useGoToPageProvider from "../../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { createTextFilter } from "../../../../common/view/listFiltersView/components/filters/components/textFilter";

const ContractorListView: React.FC = () => {
    const { goToShowContractor } = useGoToPageProvider();

    return (
        <EntityListWithFilters<Contractor, ContractorFilter>
            ListHeaderView={<Link title="Контрагенты" />}
            listControllerProps={{
                columns: [
                    createTextColumn<Contractor>('name', 'Имя контрагента', true),
                    createTextColumn<Contractor>('address', 'Адрес', true),
                    createTextColumn<Contractor>('tin', 'ИНН', true),
                    createTextColumn<Contractor>('stagingReasonCode', 'КПП', true)
                ],
                loadEntities: 'contractors'
            }}
            filterAttributes={filterAttributes}
            storeName="contractorStore"
            useListController={useContractorListController}
            ListActions={
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => goToShowContractor()}
                >
                    Добавить
                </Button>
            }
        />
    );
};

const filterAttributes: ContractorFilter = {
    name: createTextFilter('Название'),
    address: createTextFilter('Адрес'),
    tin: createTextFilter('ИНН'),
    stagingReasonCode: createTextFilter('КПП'),
};

const useContractorListController = (props: ListControllerProps<Contractor, ContractorFilter>): ListControllerReturnType<Contractor> => {
    const listProps = useListController(props); 
    const { goToShowContractor } = useGoToPageProvider();

    const onRowClick = useCallback((props: GridRowParams<Contractor>) => {
        const { id } = props.row;
        goToShowContractor(id);
    }, [goToShowContractor]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<Contractor>;
}

const ContractorList = observer(ContractorListView);

export default ContractorList;