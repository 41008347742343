import { observer } from "mobx-react-lite";
import React, { useCallback, useContext } from 'react';
import api from "../../../../api";
import { MakeArrayFilters } from "../../../../helpers/utils";
import { Sorting } from "../../../../api/types/getFunctions";
import Filter from "../../../../common/abstractions/filter";
import { listService } from "../../../../common/services/listService";
import { createReferenceFieldFilter } from "../../../../common/view/listFiltersView/components/filters/components/referenceFilter/referenceFilter";
import { createRangeDateFilter } from "../../../../common/view/listFiltersView/components/filters/components/dateRangeFilter";
import { createDateColumn, createReferenceColumn, createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import Link from "../../../../common/components/Link";
import { Button } from "@mui/material";
import UploadedDocumentFilter from "../../../domain/uploads/uploadsFilter";
import { createNumberFilter } from "../../../../common/view/listFiltersView/components/filters/components/numberFilter";
import useGoToPageProvider from "../../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { createBoolFilter } from "../../../../common/view/listFiltersView/components/filters/components/booleanFilter";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import { Document } from "../../../../types/entities/document";
import { createTextFilter } from "../../../../common/view/listFiltersView/components/filters/components/textFilter";

const UploadedDocumentsListView: React.FC = () => {
    const { goToUploadDocument } = useGoToPageProvider();

    return (
        <EntityListWithFilters<Document, UploadedDocumentFilter>
            ListHeaderView={<Link title={"Загрузка документов"} />}
            listControllerProps={{
                loadEntities: "documents",
                columns: [
                    createReferenceColumn(createTextColumn<Document>('name', 'Тип'), 'typeId', 'types'),
                    createTextColumn('documentNumber', '№'),
                    createDateColumn<Document>('documentDate', 'Дата', true),
                    createReferenceColumn(createTextColumn<Document>('name', 'Организация'), 'organizationId', 'organizations'),
                ]
            }}
            storeName="uploadsStore"
            filterAttributes={filterAttributes}
            ListActions={
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => goToUploadDocument()}
                >
                    Добавить
                </Button>
            }
            useListController={useUploadedListController}
        />
    );
};

const useUploadedListController = (props: ListControllerProps<Document, UploadedDocumentFilter>): ListControllerReturnType<Document> => {
    const listProps = useListController(props); 
    const { goToUploadDocument } = useGoToPageProvider();

    const onRowClick = useCallback((props: GridRowParams<Document>) => {
        const { id } = props.row;
        goToUploadDocument(id);
    }, [goToUploadDocument]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<Document>;
}

const filterAttributes: UploadedDocumentFilter = {
    documentNumber: createTextFilter('Номер документа'),
    organizationId: createReferenceFieldFilter('Организация', [], 'organizations'),
    documentDateRange: createRangeDateFilter("Дата документа"),
    contractorId: createReferenceFieldFilter('Контрагент', [], 'contractors'),
    onValidation: createBoolFilter(undefined, [false], false, true, false),
};

const UploadedDocumentsList = observer(UploadedDocumentsListView);

export default UploadedDocumentsList;