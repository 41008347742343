import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { FilterProps } from ".";
import Filter, { FilterAttributes, FilterType } from "../../../../../abstractions/filter";

const SelectFilter = <TFilter extends Filter>({ filterKey, attributes, updateFilter }: FilterProps<number, TFilter>) => {
    const { label, values, options, getLabel } = attributes;
    const [value, setValue] = useState<number>();

    useEffect(() => {
        if (values?.length != 0)
            setValue(values[0]);
    }, [values]);

    return (
        <FormControl sx={{minWidth: 200}} className='select_filter'>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={(e) => updateFilter(filterKey, Number(e.target.value))}
            >
                {
                    options?.map((o, i) => <MenuItem key={i} value={i}>{getLabel!(o)}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}

export default SelectFilter;

function createSelectFilter<T>(label: string, options: T[], getLabel: (value: T) => string, selected?: T): FilterAttributes<T> {
    return {
        label,
        needShowFilter: true,
        values: selected != null ? [selected] : [],
        isStaticFilter: false,
        options,
        valuePresent: getLabel,
        getLabel,
        type: FilterType.Select,
        needShowChips: true,
    };
}

export { createSelectFilter };