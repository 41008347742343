import React, { useContext, useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Box, MenuItem, FormControl, Button, Menu, Select,
    FormLabel, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import { Search, ArrowBack } from '@mui/icons-material';
import './style.sass';
import '../../../../styling/common/index.sass';
import { useHistory, useParams } from 'react-router-dom';
import { MainStore } from '../../../../stores/mainStore';
import api from '../../../../api';
import { observer } from 'mobx-react-lite';
import { DocumentAttributeKind, DocumentAttributeValue, DocumentSide } from '../../../../types/entities/document';
import { UserData } from '../../../../helpers/userData';
import Routes, { getDiadocDocumentsRoute, getSbisDocumentsRoute, getUploadDocumentRoute } from '../../../../router/routes';
import { DocumentType } from '../../../../types/entities/documentType';
import LoopIcon from '@mui/icons-material/Loop';
import isNumber from '../../../../helpers/isNumber';
import { AttachedFile, DiadocDocument, SbisDocument } from '../../../../types/entities/externalDocument';
import { CreateDocumentOutput } from '../../../../api/types/createDocumentOutput';
import FileUploadPopUp from '../../../../common/components/FileUploadPopUp';
import AttributesEditor from '../../../../common/components/AttributesEditor';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { FileLabelWithName } from '../../../../common/components/FileLabelWithName';
import useNotificationStore from '../../../../stores/notificationStore';

const UploadDocumentPage: React.FC = () => {
    const { id: initialDocumentId } = useParams<{ id?: string }>();

    const [modal, setModal] = useState(false);
    const [uploadMenuOpen, setUploadMenuOpen] = useState(false);
    const [attributeAnchorEl, setAttributeAnchorEl] = useState<null | HTMLElement>(null);
    const [uploadAnchorEl, setUploadAnchorEl] = useState<null | HTMLElement>(null);
    const [organizationId, setOrganizationId] = useState(UserData.primaryOrganizationId);
    const [addAttributeDialogOpen, setAddAttributeDialogOpen] = useState(false);
    const [documentId, setDocumentId] = useState<number>();
    const [documentTypeId, setDocumentTypeId] = useState<number>();
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState<string>();
    const [hasErrors, setHasErrors] = useState(false);
    const [confirmSendToValidationModalOpen, setConfirmSendToValidationModalOpen] = useState(false);
    const [forceValidateAttributes, setForceValidateAttributes] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [side, setSide] = useState<DocumentSide>(DocumentSide.Customer);

    const getFileName = () => file?.name != null ? file.name : fileName;

    const [documentsTypes, setDocumentsTypes] = useState<DocumentType[]>([]);

    const { organizationStore } = useContext(MainStore);
    const [attributes, setAttributes] = useState<DocumentAttributeValue[]>([]);
    const organizations = organizationStore.organizations;
    const history = useHistory();
    const notificationStore = useNotificationStore();

    const { diadocDocument, sbisDocument, attachedFile } = history.location.state as {
        diadocDocument?: DiadocDocument,
        sbisDocument?: SbisDocument,
        attachedFile?: AttachedFile
    } ?? { diadocDocument: null, sbisDocument: null, attachedFile: null };

    const loadUserOrganizations = async () => {
        const { items } = await api.organizations.getMany(undefined, undefined, undefined, UserData.organizations);
        organizationStore.organizations = items;
    };

    const loadDocumentTypes = async () => {
        setDocumentsTypes(await api.documents.getAllTypes());
    };

    React.useEffect(() => {
        if (initialDocumentId != null && isNumber(initialDocumentId)) {
            initializeById(Number(initialDocumentId));
            return;
        }

        if (!organizationStore.organizations.length)
            loadUserOrganizations();

        if (documentsTypes.length == 0)
            loadDocumentTypes();

        tryCreateFromExternalFile();

        if (diadocDocument || sbisDocument || attachedFile)
            history.replace({ ...history.location, state: {} });
    }, []);

    const tryCreateFromExternalFile = async () => {
        if (diadocDocument != null) {
            setFileName(diadocDocument.fileName);
            await createDocument(() => api.documents.createFromDiadocDocument(organizationId!, diadocDocument));
        } else if (sbisDocument != null) {
            // todo: sbis
            setFileName(attachedFile!.fileName);

            if (initialDocumentId) {
                setUploading(true);
                try {
                    await api.documents.editFromSbisDocument(Number(initialDocumentId), sbisDocument, attachedFile!);
                }
                finally {
                    setUploading(false);
                }
            }
            else
                await createDocument(() => api.documents.createFromSbisDocument(organizationId!, sbisDocument, attachedFile!));
        }
    }

    const initializeById = async (documentId: number) => {
        if (diadocDocument != null || sbisDocument != null)
            await tryCreateFromExternalFile();

        const { organizations, organizationId, typeId,
            attributes, documentTypes, fileNames, side } = await api.documents.getUploadingViewModel(documentId);

        organizationStore.organizations = organizations;
        setDocumentId(documentId);
        setDocumentsTypes(documentTypes);
        setAttributes(sortAttributes(attributes));
        setOrganizationId(organizationId);
        setDocumentTypeId(typeId);
        setSide(side);

        if (fileNames.length != 0)
            setFileName(fileNames[0]);

        if (diadocDocument != null) {

        }
    }

    const sortAttributes = (attributes: DocumentAttributeValue[]) =>
        attributes.sort((a, b) => a.required === b.required ? 0 : (a.required ? -1 : 1));

    const handleDownloadMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setUploadAnchorEl(e.currentTarget);
        setUploadMenuOpen(true);
    }

    const handleAttributeMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAddAttributeDialogOpen(true);
        setAttributeAnchorEl(e.currentTarget);
    }

    const handleDocumentTypeChange = async (documentTypeId: number) => {
        setDocumentTypeId(documentTypeId);

        const result = await api.documents.setType(documentId!, documentTypeId);
        if (result.succeeded)
            setAttributes(sortAttributes(result.attributes!));
    }

    const handleFileChange = async (file: File) => {
        setFile(file);
        setUploadMenuOpen(false);
        setModal(false);

        if (documentId != null) {
            await api.documents.setFile(documentId!, file);
            return;
        }

        await createDocument(() => api.documents.create(organizationId!, file));
    }

    const createDocument = async (factory: () => Promise<CreateDocumentOutput>) => {
        setUploading(true);
        const { documentId: createdId, typeId, attributes, side, recognized } = await factory();
        setUploading(false);

        setDocumentId(createdId);
        setDocumentTypeId(typeId);
        setSide(side);
        setAttributes(sortAttributes(attributes));

        if (!recognized)
            notificationStore.showError('Не удалось распознать содержимое документа.');

        history.push(getUploadDocumentRoute(createdId));
    }

    const handleOrganizationChange = (id: number) => {
        setOrganizationId(id);
    }

    const attributeAdded = (value: DocumentAttributeValue) => {
        setAttributes([...attributes, value]);
    }

    const attributeChanged = (value: DocumentAttributeValue) => {
        setAttributes([...attributes]);
    }

    const attributeDeleted = (attirbute: DocumentAttributeValue) => {
        setAttributes(attributes.filter(a => a.id !== attirbute.id));
    }

    const onSendToValidationClick = async () => {
        const hasEmptyRequiredAttributes =
            attributes.filter(a => a.required && a.value == null).length != 0;

        if (hasErrors || hasEmptyRequiredAttributes) {
            setForceValidateAttributes(true);
            setConfirmSendToValidationModalOpen(true);
            return;
        }

        await sendToValidation();
    }

    const onSideChanged = async (side: DocumentSide) => {
        setSide(side);
        await api.documents.swapDocumentSides(documentId!);
    }

    const sendToValidation = async () => {
        if (documentId == null)
            return;

        const result = await api.documents.sendToValidation(documentId);
        if (!result.succeeded) {
            console.error('failed to send to validation', result);
            return;
        }

        history.push(Routes.uploads);
    }

    const onDiadocDocumentPicked = (document: DiadocDocument) => {
        onDiadocPageClose();
    }

    const onDiadocPageClose = () => {
        history.push(getUploadDocumentRoute(documentId));
    }

    const recognize = async () => {
        await createDocument(() => api.documents.recognize(documentId!));
    }

    const customerName = attributes.find(a => a.kind === DocumentAttributeKind.CustomerName)?.value as string | null ?? '';
    const supplierName = attributes.find(a => a.kind === DocumentAttributeKind.ContractorName)?.value as string | null ?? '';

    return (
        <div>
            <div>
                <div className="common_header">
                    <Button
                        color='inherit'
                        startIcon={<ArrowBack />}
                        onClick={() => history.push(Routes.uploads)}
                    >
                        Назад
                    </Button>
                    <h3>Загрузка документа</h3>
                </div>
                <div className="action-buttons show_action-buttons common_header">
                    <div>
                        <Button
                            onClick={handleDownloadMenuOpen}
                            variant="contained"
                            startIcon={<LoopIcon />}
                            disabled={uploading}
                        >
                            {getFileName() == null ? "Загрузить" : "Заменить"}
                        </Button>
                        <Button
                            disabled={uploading || getFileName() == null}
                            onClick={() => recognize()}
                            variant="contained"
                            endIcon={<Search />}
                        >
                            Распознать
                        </Button>
                    </div>
                    <Menu
                        anchorEl={uploadAnchorEl}
                        open={uploadMenuOpen}
                        onClose={() => setUploadMenuOpen(false)}
                    >
                        <MenuItem className='layout_menu' onClick={() => setModal(true)}>Из файла</MenuItem>
                        <MenuItem className='layout_menu' onClick={() => history.push(getDiadocDocumentsRoute(documentId))}>DiaDoc</MenuItem>
                        <MenuItem className='layout_menu' onClick={() => history.push(getSbisDocumentsRoute(documentId))}>SBIS</MenuItem>
                    </Menu>
                    <div className='common_header__buttons'>
                        <Button onClick={() => history.push(Routes.uploads)} color='secondary'>Сохранить черновик</Button>
                        <Button onClick={() => onSendToValidationClick()} disabled={documentId == null || documentTypeId == null} variant="contained">
                            Отправить на валидацию
                        </Button>
                    </div>
                </div>
                <div>{
                    uploading ?
                        <div className='upload'>
                            <div className="upload_box">
                                <HourglassBottomIcon /> <p>Идет распознавание. Это займет не больше минуты.</p>
                            </div>
                        </div>
                        :
                        null
                }</div>
                <div className='upload_flex_container'>
                    <div>
                        <div>
                            <div className="common_select_container">
                                <p>Организация:</p>
                                <FormControl variant="outlined" className='common_select-style'>
                                    <Select
                                        value={organizationId ?? ''}
                                        defaultValue=''
                                        className='common_select-style'
                                        onChange={(e) => handleOrganizationChange(Number(e.target.value))}
                                    >
                                        {organizations.map((o) => (
                                            <MenuItem
                                                className='common_select-style'
                                                value={o.id.toString()}
                                                key={o.id}
                                            >
                                                {o.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="common_select_container common_select-type">
                                <p>Тип документа:</p>
                                <FormControl variant="outlined">
                                    <Select
                                        className='common_select-style'
                                        value={documentTypeId ?? ''}
                                        disabled={documentId == null}
                                        onChange={e => handleDocumentTypeChange(Number(e.target.value))}
                                    >
                                        {documentsTypes.map((o) => (
                                            <MenuItem
                                                className='common_select-style'
                                                value={o.id.toString()}
                                                key={o.id}
                                            >
                                                {o.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <FileLabelWithName fileName={getFileName()!} fileId={parseInt(initialDocumentId as string)}/>
                        </div>
                    </div>
                    <div className="upload__attribute">
                        <div>
                            <FormControl>
                                <FormLabel>Контрагент</FormLabel>
                                <RadioGroup
                                    value={side}
                                    onChange={(_, value) => onSideChanged(Number(value))}
                                >
                                    <FormControlLabel disabled={documentId == null} value={DocumentSide.Supplier.toString()} control={<Radio />} label={'Покупатель: ' + customerName} />
                                    <FormControlLabel disabled={documentId == null} value={DocumentSide.Customer.toString()} control={<Radio />} label={'Поставщик: ' + supplierName} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className='common_attribute'>
                            <div className='common_attribute-content'>
                                <Box className='common_attribute__label'>
                                    <FormLabel>Атрибуты:</FormLabel>
                                    <Button
                                        disabled={documentId == null}
                                        variant="outlined"
                                        onClick={handleAttributeMenuOpen}
                                    >
                                        Добавить
                                    </Button>
                                </Box>
                                <div className='common_attribute__editor'>
                                    <AttributesEditor
                                        anchorElement={attributeAnchorEl}
                                        attributes={attributes}
                                        documentId={documentId}
                                        onAttributeAdded={attributeAdded}
                                        onAttributeChanged={attributeChanged}
                                        onAttributeDeleted={attributeDeleted}
                                        addAttributeDialogOpen={addAttributeDialogOpen}
                                        setAddAttributeDialogOpen={setAddAttributeDialogOpen}
                                        setHasErrors={(hasErrors) => setHasErrors(hasErrors)}
                                        forceValidate={forceValidateAttributes}
                                    />
                                    <ConfirmSendToValidationModal open={confirmSendToValidationModalOpen}
                                        setOpen={(open) => setConfirmSendToValidationModalOpen(open)}
                                        onAgree={() => sendToValidation()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FileUploadPopUp
                open={modal}
                setOpen={setModal}
                setFile={handleFileChange}
            />
        </div>

    );
};

const ConfirmSendToValidationModal = (props: {
    open: boolean, setOpen: (open: boolean) => void,
    onAgree: () => void
}) => {
    return <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => props.setOpen(false)}
    >
        <DialogTitle id="alert-dialog-title">
            Подтверждение
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Некоторые атрибуты не заполнены, либо содержат некорректные значения.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={() => props.setOpen(false)}>OK</Button>
        </DialogActions>
    </Dialog>;
}

export default observer(UploadDocumentPage);