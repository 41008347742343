import { Button, Card, CardActions, CardContent, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useApi } from "../../../api";
import useGoToPageProvider from "../../../router/goToPageProvider";
import useQuery from "../../../router/useQuery";
import Logo from '../../../styling/img/logo.svg';
import './style.sass';

const ResetPassword: React.FC = () => {
    const api = useApi();
    const { goToSignInPage } = useGoToPageProvider();
    const query = useQuery();
    const token = query.get("token");
    const userName = query.get("userName");

    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    if (!token || !userName)
        return <div>Неверный токен</div>;

    const onSubmit = async () => {
        const result = await api.account.resetPassword(userName, token, password);
        if (result.succeeded) {
            goToSignInPage();
        }
        else {
            if (result.errors)
                setErrorMessage(result.errors.join("\n"));
        }
    };

    return (
        <div className='resetPassword'>
            <img src={Logo} />
            <form onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}>
                <Card className='resetPassword_card'>
                    <Typography variant="h6" gutterBottom>Сброс пароля</Typography>
                    <CardContent className="resetPassword_card-content">
                        <TextField 
                            className="resetPassword_input" 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            label="Новый пароль"
                        />
                        {errorMessage != null ? <span className="resetPassword_error-field" style={{ display: 'block' }}>{errorMessage}</span> : null}
                    </CardContent>
                    <CardActions>
                        <Button 
                            disabled={!password}
                            className='resetPassword_button'
                            size="small"
                            type="submit"
                        >
                            Изменить пароль
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    );
}

export default ResetPassword;