import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import { Document } from "../../../types/entities/document";
import DocumentStorageFilter from "../../domain/storage/storageFilter";

export default class StorageStore extends Store<Document, DocumentStorageFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}