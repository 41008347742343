import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import ParagraphEntity from "../../domain/requirements/requirementParagraph/paragraphEntity";
import ParagraphFilter from "../../domain/requirements/requirementParagraph/paragraphFilter";

export default class RequirementParagraphsStore extends Store<ParagraphEntity, ParagraphFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}