import { useParams } from "react-router-dom";
import { useApi } from "../../../../api";
import { RootApiType } from "../../../../api/rootApi";
import { SucceededWithDataResult } from "../../../../api/types/succeededResult";
import EntityView from "../../../../common/pages/blanks/Show";
import { DataType } from "../../../../types/dataType";
import { User } from "../../../../types/entities/user";
import { UserRole } from "../../../../types/userRole";
import { EntityField } from "../../../abstractions/showEntity";

const UserShowView: React.FC = () => {
  const api = useApi();
  const { id } = useParams<{id: string | undefined}>();
  const fields: EntityField<User>[] = [
    {source: 'firstName', label: 'Имя', required: true, type: DataType.String},
    {source: 'lastName', label: 'Фамилия', required: true, type: DataType.String},
    {source: 'patronymic', label: 'Отчество', required: true, type: DataType.String},
    {source: 'userName', label: 'Электронная почта', required: true, type: DataType.String, disabled: id !== undefined},
    {source: 'primaryOrganizationId', label: 'Основная организация', required: true, disabled: id !== undefined, getCaption: (e) => e.name, route: "organizations", fieldForOrder: "name", type: DataType.Reference},
    {source: 'organizationIds', label: "Доступные организации", required: false, getCaption: (e) => e.name, route: "organizations", fieldForOrder: "name", type: DataType.ManyReference},
    {source: 'roles', label: 'Роли', required: true, type: DataType.Enum,
      dictionary: [
        {key: UserRole.Superuser, value: 'Суперпользователь'},
        {key: UserRole.Admin, value: 'Администратор'},
        {key: UserRole.Accountant, value: 'Бухгалтер'},
        {key: UserRole.ResponsibleAccountant, value: 'Ответственный бухгалтер'},
      ]
    },
  ];

  if (id !== undefined)
    fields.push({source: 'isBlocked', label: 'Заблокирован', type: DataType.Boolean});

  return (
      <div>
          <EntityView<User>
              showServiceProps={{storeName: "usersStore", loadEntity: getUserById(api), onEntityCreate: saveUser(api), onEntityUpdate: updateUser(api)}}
              fields={fields}
          />
      </div>
  )
}

//todo: refactor this. Need hide this methods inside EntityShowView, cuz it can be replaced with generics methods
const getUserById = (api: RootApiType) => async (id: number): Promise<User> => {
  const user = await api.users.getOne(id);
  if (!user)
    throw new Error("Пользователь не найден");

  return user;
}

const saveUser = (api: RootApiType) => async (user: User): Promise<SucceededWithDataResult<number>> => {
  return await api.users.create(user);
}

const updateUser = (api: RootApiType) => async (id: number, user: User): Promise<SucceededWithDataResult<number>> => {
  return await api.users.update(id, user);
}

export default UserShowView;