import React, { useEffect, useState } from 'react';
import {
    TableContainer, Link, Table, FormControl, Select,
    MenuItem, TableHead, TableRow, TableCell, TableBody, CircularProgress,
    LinearProgress, FormControlLabel, Checkbox
} from "@mui/material"
import api from '../../../../../api';
import { EditDocumentViewModel } from '../../../../../api/types/editDocumentViewModel';
import './style.sass';
import { DocumentStatus, DocumentStatusCaptions } from '../../../../../types/entities/document';
import format from 'date-fns/format';
import { parseToAttributeValues } from '../../../../../helpers/attributes';
import File from '../../../../../types/entities/documentFile';
import PdfViewer from '../PdfViewer';
import useNotificationStore from '../../../../../stores/notificationStore';
import { getValidateDocumentRoute } from '../../../../../router/routes';
import { DocumentType } from "../../../../../types/entities/documentType";

const createData = (name: string, value: any) => ({ name, value });

const CompareDocument: React.FC<ICompareDocumentProps> = ({ documentId, button, file, reload, onDocumentChanged, documentTypes, canBind }) => {
    const [status, setStatus] = useState<DocumentStatus>();
    const [document, setDocument] = useState<EditDocumentViewModel>();
    const notificationStore = useNotificationStore();

    useEffect(() => {
        (async () => {
            const document = await api.documents.getEditViewModel(documentId);
            setDocument(document);
            setStatus(document.status);
        })();
    }, [documentId, reload]);

    const onStatusChanged = async (status: DocumentStatus) => {
        await api.documents.setStatus(documentId, status);
        notificationStore.setIsOpenAndText(true, `Документ переведен в статус: ${DocumentStatusCaptions[status].caption}`);

        setStatus(status);

        if (onDocumentChanged != null)
            onDocumentChanged();
    }

    const setDocumentAsMain = async () => {
        await api.documents.setAsMainInStack(documentId);

        if (onDocumentChanged != null)
            onDocumentChanged();
    }

    if (!document || status === undefined) {
        return <CircularProgress />;
    }

    const rows = document.attributes.map(a => {
        const value = parseToAttributeValues(a.dataType, a.value as string);
        if (value instanceof Date)
            return createData(a.name, format(new Date(value), 'dd.MM.yyyy'));

        return createData(a.name, value);
    });

    const getDocumentType = (typeId: number) => documentTypes?.find(t => t.id === typeId)?.name ?? '';

    return (
        <div className='compare_document-container main'>
            <Header setDocumentAsMain={setDocumentAsMain} documentType={getDocumentType(document.typeId)} document={document!} 
                documentId={documentId} showLink={button === true} canBind={canBind} />
            <div>
                <div className='document_attributes'>
                    <TableContainer className='document_attributes_table'>
                        <Table>
                            <TableHead >
                                <TableRow sx={{ maxHeight: 40 }}>
                                    <TableCell sx={{ minWidth: 120 }} className=''>Название атрибута</TableCell>
                                    <TableCell sx={{ minWidth: 100 }} className=''>Значение</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                    >
                                        <TableCell className='' component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell className=''>{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="file-container-select">
                    <p>Статус:</p>
                    <FormControl className='file-container-select_content'>
                        <Select<DocumentStatus>
                            value={status}
                            displayEmpty
                            onChange={async (e) => onStatusChanged(e.target.value as DocumentStatus)}
                        >
                            {DocumentStatusCaptions.map(s => (
                                <MenuItem key={s.value} value={s.value}>{s.caption}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {file ? <PdfViewer file={file.file} fileId={documentId} /> : <LinearProgress />}
            </div>
        </div>
    );
};

const Header = ({ documentId, document, showLink, documentType, setDocumentAsMain, canBind }: {
    documentId: number,
    document: EditDocumentViewModel, showLink: boolean
    documentType: string, setDocumentAsMain: () => void,
    canBind: boolean
}) => {

    const text = `${documentType} №${document.documentNumber} от ${format(new Date(document.documentDate), "dd.MM.yyyy")}`;
    const result = showLink ? <Link href={getValidateDocumentRoute(documentId)} target="_blank">{text}</Link> : <p>{text}</p>;

    return <div className="compareDocument-title">
        { result }
        {!canBind ?
        <FormControlLabel disabled={document.isMainInStack}
            control={<Checkbox disabled={document.isMainInStack} checked={document.isMainInStack} />} 
            onClick={() => setDocumentAsMain()} label="Лучшая копия" /> : <></> }
    </div>;
}

interface ICompareDocumentProps {
    documentId: number;
    button?: true;
    file?: File;
    reload?: boolean;
    onDocumentChanged?: () => void;
    documentTypes: DocumentType[];
    canBind: boolean;
}

export default CompareDocument;