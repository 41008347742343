import './App.scss';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import customTheme from "./styling/theme";
import Router from "./router";
import './styling/fonts/stylesheet.css'
import { LicenseInfo } from '@mui/x-license-pro';

const App = () => {
  const key = process.env.DATA_GRID_KEY ?? "e513e05e717c80ad378c75c17eb1da89T1JERVI6NDE1MDEsRVhQSVJZPTE2ODEwNjM4NjEwMDAsS0VZVkVSU0lPTj0x";
  if (!key)
    throw new Error("No license key found. Please set DATA_GRID_KEY environment variable.");
  
  LicenseInfo.setLicenseKey(key);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
            <Router />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
