import { makeObservable } from "mobx";
import Contractor from "../../domain/contractor/contractorEntity";
import ContractorFilter from "../../domain/contractor/contractorFilter";
import Store from "../../../common/infrastructure/Store";

export default class ContractorStore extends Store<Contractor, ContractorFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}