import { Entity } from './entity';

export type DocumentType = Entity & {
    name: string;
};

export enum AccountingDocumentTypes {
    Deed = 1,
    ConsignmentNote,
    Invoice,
    PackingList,
    Contract,
    AdditionalAgreement,
    GuaranteeLetter,
    Memo,
    Bill,
    UniversalPaymentDocument,
};
