import { Organization } from "../types/entities/organization";
import Api, { EditableEntityApi } from "./api";
import { getMany, GetManyApi, getOne } from "./types/getFunctions";
import { SucceededWithDataResult } from "./types/succeededResult";

export type OrganizationsApiType = EditableEntityApi<Organization, SucceededWithDataResult<number>, SucceededWithDataResult<number>> & GetManyApi<Organization> & {
    getOne: getOne<Organization>;
    getMany: getMany<Organization>;
};

export class OrganizationsApi extends Api implements OrganizationsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'organizations'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal.bind(this);
    getMany = this.getManyInternal.bind(this);

    create = this.createInternal.bind(this);
    update = this.updateInternal.bind(this);
}
