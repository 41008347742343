import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { IEntityStores } from "../../../../stores/entitiesStore";
import { Entity } from "../../../../types/entities/entity";
import Filter from "../../../abstractions/filter";
import { ListControllerProps, ListControllerReturnType, useListController as useDefaultListController } from "../../../controller/entityListController/entityListController";
import { FiltersControllerProps, FiltersControllerReturnType, useFiltersController as useDefaultFiltersController } from "../../../controller/filters/filtersController";
import Store from "../../../infrastructure/Store";
import EntityListView from '../../../view/entityListView';
import ListFiltersView from '../../../view/listFiltersView';
import './style.sass';

const EntityListWithFiltersView = <
    TEntity extends Entity, 
    TFilter extends Filter, 
    TListControllerProps extends ListControllerProps<TEntity, TFilter> = ListControllerProps<TEntity, TFilter>, 
    TFilterControllerProps extends FiltersControllerProps<TEntity, TFilter> = FiltersControllerProps<TEntity, TFilter>>
({ 
    listControllerProps,
    useListController = useDefaultListController,
    filterControllerProps,
    useFiltersController = useDefaultFiltersController,
    ListHeaderView,
    DataGridHeader,
    ListActions,

    storeName,
    filterAttributes
}: EntityListWithFiltersViewProps<TEntity, TFilter, TListControllerProps, TFilterControllerProps>) => {
    return (
        <div>
            {ListHeaderView}
            <ListFiltersView<TEntity, TFilter, TFilterControllerProps>
                filterControllerProps={{...filterControllerProps, storeName: storeName, filterAttributes: filterAttributes} as TFilterControllerProps}
                useFiltersController={useFiltersController}
                ListActions={ListActions}
            />
            <EntityListView<TEntity, TListControllerProps>
                DataGridHeader={DataGridHeader}                    
                listControllerProps={{...listControllerProps, storeName: storeName, filterAttributes: filterAttributes} as TListControllerProps}
                useListController={useListController}
            />
        </div>
    );
};

interface EntityListWithFiltersViewProps<TEntity extends Entity, TFilter extends Filter, TListControllerProps extends ListControllerProps<TEntity, TFilter>, TFilterControllerProps extends FiltersControllerProps<TEntity, TFilter>> {
    listControllerProps: Omit<TListControllerProps, "filterAttributes" | "storeName">;
    useListController?: (props: TListControllerProps) => ListControllerReturnType<TEntity>;
    filterControllerProps?: Omit<TFilterControllerProps, "filterAttributes" | "storeName">;
    useFiltersController?: (props: TFilterControllerProps) => FiltersControllerReturnType<TFilter>;
    ListHeaderView?: JSX.Element;
    DataGridHeader?: ReactElement<ListControllerReturnType<TEntity>>;
    ListActions?: JSX.Element;

    filterAttributes?: TFilter;
    storeName: ((context: IEntityStores) => Store<TEntity, TFilter>) | keyof IEntityStores;
};

const EntityListWithFilters = EntityListWithFiltersView;

export default EntityListWithFilters;