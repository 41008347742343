import { Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api from '../../../../api';
import { DocumentAttribute } from '../../../../types/entities/document';

interface IAddAttributeModalProps {
    anchorElement: Element | null;
    open: boolean;
    documentId?: number;
    setOpen: (open: boolean) => void;
    onAdded: (attribute: DocumentAttribute) => Promise<void>;
};

const AddAttributeModal: React.FC<IAddAttributeModalProps> = (props) => {
    const [attributes, setAttributes] = useState<DocumentAttribute[] | null>(null);
    const [attributesLoadedFor, setAttributesLoadedFor] = useState<number | undefined>(undefined);

    const loadAttributes = async () => {
        if (!props.open)
            return;

        if (attributes != null && attributesLoadedFor != null && attributesLoadedFor === props.documentId)
            return;

        if (props.documentId == null)
            return;

        await updateAttributes();
    }

    const updateAttributes = async () => {
        setAttributes(await api.documents.getAttributesToCreate(props.documentId!));
        setAttributesLoadedFor(props.documentId);
    }

    useEffect(() => {
        loadAttributes();            
    }, [props.open, props.documentId]);

    const added = async (attribute: DocumentAttribute) => {
        props.setOpen(false);
        await props.onAdded(attribute);
        await updateAttributes();
    }

    return props.open ? <div className='box'>
        <Menu
            anchorEl={props.anchorElement}
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            { attributes?.map(a =>
                <MenuItem onClick={() => added(a)}>
                    {a.name}
                </MenuItem>)
            }
        </Menu>
    </div> : <div></div>;
}

export default AddAttributeModal;