import { Modal, Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';
import CloudDownload from '../../../styling/img/cloudDownload.svg';
import { Close, FileDownloadOutlined, InsertDriveFileOutlined, RemoveCircleOutline } from '@mui/icons-material';
import './style.sass';
import { accept } from '../../../helpers/filePickerAcceptFormats';

interface IFileUploadPopUpProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    setFile: (file: File) => void,
}

const FileUploadPopUp: React.FC<IFileUploadPopUpProps> = (props) => {
    const [openFilePicker, { plainFiles, clear }] = useFilePicker({
        accept: accept,
        multiple: false,
    });
    
    const [file, setFile] = useState<File>();

    useEffect(() => {
        let newFile = plainFiles[0];
        setFile(newFile);

    }, [plainFiles.length]);

    const sumbit = () => {
        props.setFile(file!);
    }

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <Box className='modal'>
                <div className="modal_header">
                    <h3>Загрузка документа из файла</h3>
                    <IconButton onClick={() => props.setOpen(false)}>
                        <Close />
                    </IconButton>
                </div>
                {
                    file ?
                        <div className='modal_file_container'>
                            <div className='modal_file'>
                                <div className='modal_file__deleteButton'>
                                    <IconButton onClick={() => clear()}>
                                        <RemoveCircleOutline
                                            fontSize='small'
                                            color='primary'
                                        />
                                    </IconButton>
                                </div>
                                <InsertDriveFileOutlined
                                    className='modal_file__svg'
                                    color='secondary'
                                />
                                {file.name}
                            </div>
                        </div>
                        :
                        <div>
                            <p>Выберите все файлы, относящиеся к одному и только одному документу, к одной его версии.</p>
                            <div
                                className="modal_download"
                                onClick={openFilePicker}
                            >
                                <img src={CloudDownload} />
                                <h2>Выберите файл для загрузки</h2>
                                <p>Для импорта справочника вы можете загрузить одновременно не более 1 файла.</p>
                            </div>
                        </div>
                }
                <Button
                    disabled={!file}
                    className='modal_button'
                    variant="contained"
                    startIcon={<FileDownloadOutlined />}
                    onClick={sumbit}
                >
                    Загрузить
                </Button>
            </Box>
        </Modal>
    )
}

export default FileUploadPopUp;