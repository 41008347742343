import { SignInUserModel } from "../api/types/signInOutput";

export class UserData {
    static setData(accessToken: string, userModel: SignInUserModel) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', JSON.stringify(userModel));
    }

    static cache: SignInUserModel | null = null;

    static clear() {
        UserData.cache = null;
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');        
    }

    static get accessToken() {
        return localStorage.getItem('accessToken');
    }

    static get id() {
        return this.getModel()?.userId;
    }

    static get roles() {
        return this.getModel()?.roles;
    }

    static get primaryOrganizationId() {
        return this.getModel()?.primaryOrganizationId;
    }

    static get organizations() {
        return this.getModel()?.organizations;
    }

    private static getModel = () => {
        if (UserData.cache != null)
            return UserData.cache;

        const serialized = localStorage.getItem('user');
        if (serialized == null || serialized === '')
            return null;

        UserData.cache = JSON.parse(serialized) as SignInUserModel;
        return UserData.cache;
    }
}
