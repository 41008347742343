import dateFormat from 'dateformat';
import { Document } from '../types/entities/document';

export const formatDate = (date?: Date) => date != null ? dateFormat(date, 'dd.mm.yyyy') : null;
export const formatDateTime = (date?: Date) => date != null ? dateFormat(date, 'dd.mm.yyyy HH:mm') : null;
export const getShortName = (firstName: string, lastName: string) => `${firstName.substring(0, 1)}. ${lastName}`;
export const getShortNameForUser = (user: { firstName: string, lastName: string }) => getShortName(user.firstName, user.lastName);

export const getDocumentCaption = (document: Document) => {
    const { documentDate: date, documentNumber: number } = document;
    if (date == null && number == null)
        return '';

    let result = '';
    if (number != null)
        result += '№' + number;

    if (date != null)
        result += (result !== '' ? ' ' : '') + 'от ' + formatDate(date);

    return result;
};

