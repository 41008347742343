import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import ParagraphDocumentEntity from "../../domain/requirements/paragraphDocument/paragraphDocumentEntity";
import ParagraphDocumentFilter from "../../domain/requirements/paragraphDocument/paragraphDocumentFilter";

export default class ParagraphDocumentsStore extends Store<ParagraphDocumentEntity, ParagraphDocumentFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}