export enum RequirementStatus {
    New,
    OnProgress,
    Downloaded,
    Completed,
    Canceled,
}

export const RequirementStatusCaptions = [
    { value: RequirementStatus.New, caption: 'Новое' },
    { value: RequirementStatus.OnProgress, caption: 'В процессе' },
    { value: RequirementStatus.Downloaded, caption: 'Выгружено' },
    { value: RequirementStatus.Completed, caption: 'Отправлено в архив' },
    { value: RequirementStatus.Canceled, caption: 'Отменено' },
];

export const requirementStatusCaption = (status?: RequirementStatus) => 
    RequirementStatusCaptions.find(c => c.value === status)!.caption;
