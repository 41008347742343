import { HistoryOperation } from "../types/entities/historyOperation";
import Api from "./api";
import { getMany, getOne } from "./types/getFunctions";

export type HistoryOperationsApiType = {
    getOne: getOne<HistoryOperation>;
    getMany: getMany<HistoryOperation>;
};

export class HistoryOperationsApi extends Api implements HistoryOperationsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'historyOperations'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal;
    getMany = this.getManyInternal;
};