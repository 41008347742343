import { useHistory } from "react-router-dom";
import Routes, { 
    getCompareDocumentsRoute, getFindDuplicatesRoute, 
    getUploadDocumentRoute, getContractorRoute, 
    getShowDocumentRoute, getValidateDocumentRoute, 
    getRequirementRoute, getParagraphDocumentsRoute,
    getShowUserRoute, getShowOrganizationRoute,
    getShowFrcRoute
} from "./routes";

const useGoToPageProvider = () : GoToPageProviderReturnType => {
    const history = useHistory();

    const goToPage = (page: string) => history.push(page);
    const goToPageWithReplace = (page: string) => history.replace(page);

    const goBack = () => history.goBack();

    const goToFindDublicates = (documentId: number, documentNumber: string, stackId: number, contractorId?: number, documentDate?: string) =>
        goToPage(getFindDuplicatesRoute(documentId, documentNumber, stackId, contractorId, documentDate));

    const goToShowValidation = (documentId: number) =>
        goToPage(getValidateDocumentRoute(documentId));

    const goToShowRequirement = (requirementId?: number) =>
        goToPage(getRequirementRoute(requirementId!));

    const goToShowDocument = (documentId: number) => 
        goToPage(getShowDocumentRoute(documentId));        

    const goToShowContractor = (contractorId?: number) =>
        goToPage(getContractorRoute(contractorId))

    const goToParagraphDocuments = (requirementId: number, paragraphId: number) => 
        goToPage(getParagraphDocumentsRoute(requirementId, paragraphId));    
        
    const goToCompareDocuments = (mainDocumentId: number, compareDocumentId: number, canBind: boolean, needReplace?: boolean) =>
        !needReplace ? goToPage(getCompareDocumentsRoute(mainDocumentId, compareDocumentId, canBind)) : 
        goToPageWithReplace(getCompareDocumentsRoute(mainDocumentId, compareDocumentId, canBind));

    const goToUploadDocument = (documentId?: number) =>
        goToPage(getUploadDocumentRoute(documentId));

    const goToShowUser = (userId?: number) => 
        goToPage(getShowUserRoute(userId));
    
    const goToShowOrganization = (organizationId?: number) => 
        goToPage(getShowOrganizationRoute(organizationId));

    const goToSignInPage = () =>
        goToPage(Routes.signIn);
    
    const goToForgotPassword = () => 
        goToPage(Routes.forgotPassword);

    const goToShowFrc= (frcId?: number) => 
        goToPage(getShowFrcRoute(frcId));
    
    return {
        goBack,
        goToPage,
        goToFindDublicates,
        goToShowValidation,
        goToCompareDocuments,
        goToUploadDocument,
        goToShowRequirement,
        goToParagraphDocuments,
        goToShowDocument,
        goToShowContractor,
        goToShowUser,
        goToShowOrganization,
        goToSignInPage,
        goToForgotPassword,
        goToShowFrc
    };
}

export default useGoToPageProvider;

interface GoToPageProviderReturnType {
    goBack: () => void;
    goToPage: (page: string) => void;
    goToFindDublicates: (documentId: number, documentNumber: string, stackId: number, contractorId?: number, documentDate?: string) => void;
    goToShowValidation: (documentId: number) => void;
    goToCompareDocuments: (mainDocumentId: number, compareDocumentId: number, canBind: boolean, needReplace?: boolean) => void;
    goToUploadDocument: (documentId?: number) => void;
    goToShowRequirement: (requirementId?: number) => void;
    goToParagraphDocuments: (requirementId: number, paragraphId: number) => void;
    goToShowDocument: (documentId: number) => void;
    goToShowContractor: (contractorId?: number) => void;
    goToShowUser: (userId?: number) => void;
    goToShowOrganization: (organizationId?: number) => void;
    goToSignInPage: () => void;
    goToForgotPassword: () => void;
    goToShowFrc: (frcId?: number) => void;
} 