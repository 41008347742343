import { User } from './../../../types/entities/user';
import Filter from "../../abstractions/filter";
import Store from "../Store";
import { makeObservable } from 'mobx';


export default class UserStore extends Store<User, Filter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}