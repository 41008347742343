import {
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@mui/material";
import React from 'react';


const NotificationModal = (props: {
    open: boolean, setOpen: (open: boolean) => void,
    onOk: () => void, onCancel?: () => void,
    text?: string, title?: string, showCancel: boolean,
    okButtonText?: string, cancelButtonText?: string,
}) => {
    return <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
    >
        <DialogTitle>
            { props.title }
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                { props.text }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={() => props.onOk()}>{ props.okButtonText ?? "OK" }</Button>
            { props.showCancel ? <Button color='secondary' onClick={() => props.onCancel!()}>{ props.cancelButtonText ?? "Отмена" }</Button> : null }
        </DialogActions>
    </Dialog>;
}

export default NotificationModal;
