import { DataGridPro } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react-lite";
import { cloneElement, isValidElement, ReactElement } from "react";
import { Entity } from "../../../types/entities/entity";
import { BaseListControllerWithColumnsProps } from "../../controller/entityListController/baseEntityListController";
import { ListControllerReturnType } from "../../controller/entityListController/entityListController";
import './style.sass';

const EntityListView = <TEntity extends Entity, TListControllerProps extends BaseListControllerWithColumnsProps>({ listControllerProps, useListController, DataGridHeader}: EntityListViewProps<TEntity, TListControllerProps>) => {
  const dataGridProps = useListController(listControllerProps);
  const { showFilters, ...rest} = dataGridProps;
  const classes = `data-grid ${showFilters ? 'data-grid-with-filters' : ''}`;

  return (
    <div className={classes} >
      {isValidElement(DataGridHeader) && cloneElement(DataGridHeader, {...dataGridProps})}
      <DataGridPro {...rest} />
    </div>
  );
}

export default observer(EntityListView);

interface EntityListViewProps<TEntity extends Entity, TListControllerProps extends BaseListControllerWithColumnsProps> {
  listControllerProps: TListControllerProps;
  useListController: (props: TListControllerProps) => ListControllerReturnType<TEntity>;
  DataGridHeader?: ReactElement<ListControllerReturnType<TEntity>>;
}
