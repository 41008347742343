import { FC, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from "@mui/material";
import './style.sass';

const UnbindDialoc: FC<UnbindDialocProps> = ({ unbindDocumentHandle }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen} variant="outlined" className="unbind_document-button">
                Отвязать
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                classes={ { paper: 'unbind_document-dialog' } }
            >
                {loading ? <LinearProgress /> :
                    <div className="unbind_document-dialog-content">
                        <DialogTitle className="unbind_document-dialog-header">
                            Отвязать документ
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText className="unbind_document-dialog-content-text" >
                                Данный документ потеряет связь с текущими документами и вернётся в очередь на валидацию
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button 
                                variant="contained" 
                                onClick={async () => {
                                    setLoading(true);
                                    await unbindDocumentHandle();
                                    setLoading(false);
                                    handleClose();
                                }} 
                                autoFocus>
                                Подтверждаю
                            </Button>                        
                            <Button className="unbind_document_cancel-dialog-button" variant="contained" onClick={handleClose}>
                                Отмена
                            </Button>
                        </DialogActions>
                    </div>
                }
            </Dialog>
        </div>
    );
}

interface UnbindDialocProps {
    unbindDocumentHandle: () => Promise<void>;
}

export default UnbindDialoc;