import { MakeArrayFilters } from "../helpers/utils";
import { DocumentType } from "../types/entities/documentType";
import Api from "./api";
import { getMany, getOne, Sorting } from "./types/getFunctions";

export type DocumentTypesApiType = {
    getOne: getOne<DocumentType>;
    getMany: getMany<DocumentType>;
};

export class DocumentTypesApi extends Api implements DocumentTypesApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'accounting/documents/types'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal;
    getMany = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<DocumentType>, search?: string, subroute?: string) => this.getManyInternal(skip, take, sortings, ids, filter, search, subroute);
};
