import { MakeArrayFilters } from "../helpers/utils";
import { Document, DocumentAttribute, DocumentAttributeValue, DocumentStatus } from "../types/entities/document";
import DocumentFile from "../types/entities/documentFile";
import { DocumentType } from "../types/entities/documentType";
import { AttachedFile, DiadocDocument, SbisDocument } from "../types/entities/externalDocument";
import { HistoryOperation } from "../types/entities/historyOperation";
import { UploadDocumentViewModel } from "./../types/entities/uploadDocumentViewModel";
import Api from "./api";
import { AddDocumentAttributeResult, SucceededResult } from "./types";
import { CompareRequisitesModel } from "./types/compareRequisitesModel";
import { CreateDocumentOutput } from "./types/createDocumentOutput";
import { EditDocumentOutput } from "./types/editDocumentOutput";
import { EditDocumentViewModel } from "./types/editDocumentViewModel";
import { ExternalDocumentsOutput } from "./types/externalDocumentsOutput";
import { getMany, GetManyApi, getOne, Sorting } from "./types/getFunctions";
import { SetDocumentStatusOutput } from "./types/setDocumentStatusOutput";

export type SetDocumentTypeOutput = SucceededResult & { attributes?: DocumentAttributeValue[] };

export type DocumentsApiType = GetManyApi<Document> & {
    getOne: getOne<Document>;
    getMany: getMany<Document>;

    create: (organizationId: number, file: File) => Promise<CreateDocumentOutput>;
    addAttribute: (documentId: number, attributeId: number, attributeValue: unknown) => Promise<AddDocumentAttributeResult>;
    editAttribute: (documentAttributeValueId: number, attributeValue: unknown) => Promise<SucceededResult>;
    deleteAttribute: (documentAttributeValueId: number) => Promise<SucceededResult>;

    setType: (documentId: number, typeId: number) => Promise<SetDocumentTypeOutput>;
    setFile: (documentId: number, file: File) => Promise<SucceededResult>;
    setOrganization: (documentId: number, organizationId: number) => Promise<SucceededResult>;
    setStatus: (documentId: number, status: DocumentStatus) => Promise<SucceededResult>;
    setContractor: (documentId: number, contractorId: number) => Promise<SucceededResult>;
    setContract: (documentId: number, contractId: number) => Promise<SucceededResult>;
    setAdditionalAgreement: (documentId: number, additionalAgreementId: number) => Promise<SucceededResult>;
    setFinancialResponsibilityCenter: (documentId: number, frcId: number) => Promise<SucceededResult>;

    getAttributesToCreate: (documentId: number) => Promise<DocumentAttribute[]>;
    getAllTypes: () => Promise<DocumentType[]>;
    getUploadingViewModel: (documentId: number) => Promise<UploadDocumentViewModel>;
    sendToValidation: (documentId: number) => Promise<SucceededResult>;
    getDiadocDocuments: (skip?: number, take?: number, from?: Date, to?: Date) => Promise<ExternalDocumentsOutput<DiadocDocument>>;
    getSbisDocuments: (skip?: number, take?: number, from?: Date, to?: Date) => Promise<ExternalDocumentsOutput<SbisDocument>>;
    createFromDiadocDocument: (organizationId: number, document: DiadocDocument) => Promise<CreateDocumentOutput>;
    createFromSbisDocument: (organizationId: number, document: SbisDocument, attachedFile: AttachedFile) => Promise<CreateDocumentOutput>;
    editFromSbisDocument: (documentId: number, document: SbisDocument, attachedFile: AttachedFile) => Promise<EditDocumentOutput>;
    recognize: (documentId: number) => Promise<CreateDocumentOutput>;
    getEditViewModel: (documentId: number) => Promise<EditDocumentViewModel>;
    swapDocumentSides: (documentId: number) => Promise<SucceededResult>;
    bindToStack: (documentId: number, mainStackDocumentId: number) => Promise<SucceededResult>;
    unbindDocument: (documentId: number) => Promise<SucceededResult>;
    completeValidation: (documentId: number) => Promise<SucceededResult>;
    rejectValidation: (documentId: number) => Promise<SucceededResult>;
    getRequisitesToCompare: (documentId: number) => Promise<CompareRequisitesModel>;
    getDownloadFile: (documentId: number) => Promise<DocumentFile>;
    setAsMainInStack: (documentId: number) => Promise<SucceededResult>;
    getHistory: (documentId: number) => Promise<{ operations: HistoryOperation[] }>;
    hasDuplicates: (documentId: number) => Promise<boolean>;
};

export class DocumentsApi extends Api implements DocumentsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'accounting/documents'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal;
    getMany = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<Document>, search?: string, subroute?: string) => this.getManyInternal(skip, take, sortings, ids, filter, search, subroute);

    public addAttribute = (documentId: number, attributeId: number, attributeValue: unknown) => 
        this.post<AddDocumentAttributeResult>(`${documentId}/addAttribute`, { attributeId, attributeValue });
    
    public editAttribute = (documentAttributeValueId: number, attributeValue: unknown) => 
        this.put<SucceededResult>(`editAttribute/${documentAttributeValueId}`, { value: attributeValue });

    public deleteAttribute = (documentAttributeValueId: number) => 
        this.delete<SucceededResult>(`deleteAttribute/${documentAttributeValueId}`, {});

    public create(organizationId: number, file: File) {
        return this.postForm<CreateDocumentOutput>('', { organizationId, file });
    }

    public setType(documentId: number, typeId: number) {
        return this.put<SetDocumentTypeOutput>(`${documentId}/setType`, { TypeId: typeId });
    }

    public getAttributesToCreate(documentId: number) {
        return this.get<DocumentAttribute[]>(documentId + '/attributesToCreate');
    }

    public async getAllTypes() {
        const { items } = await this.getManyInternal<DocumentType>(undefined, undefined, undefined, undefined, undefined, undefined, 'types');
        return items;
    }

    public setFile(documentId: number, file: File) {
        return this.putForm<SucceededResult>(`${documentId}/setFile`, { file });
    }

    public getUploadingViewModel(documentId: number) {
        return this.get<UploadDocumentViewModel>(documentId + '/uploadingViewModel');
    }

    public sendToValidation(documentId: number) {
        return this.put<SucceededResult>(documentId + '/sendToValidation', {});
    }

    getDiadocDocuments = (skip?: number, take?: number, from?: Date, to?: Date) => {
        return this.get<ExternalDocumentsOutput<DiadocDocument>>('diadoc');
    }

    getSbisDocuments = (skip?: number, take?: number, from?: Date, to?: Date) => {
        return this.get<ExternalDocumentsOutput<SbisDocument>>('sbis');
    }

    createFromDiadocDocument = (organizationId: number, document: DiadocDocument) => {
        return this.post<CreateDocumentOutput>('createFromDiadoc', 
            { organizationId, entityId: document.entityId, boxId: document.boxId, messageId: document.messageId });
    }

    createFromSbisDocument = (organizationId: number, document: SbisDocument, attachedFile: AttachedFile) => {
        return this.post<CreateDocumentOutput>('createFromSbis', 
            { organizationId, id: document.id, idAttachment: attachedFile.idAttachment, entityId: document.entityId, boxId: document.boxId, messageId: document.messageId });
    }

    editFromSbisDocument = (documentId: number, document: SbisDocument, attachedFile: AttachedFile) => {
        return this.put<EditDocumentOutput>(documentId + '/editFromSbis', 
            { id: document.id, idAttachment: attachedFile.idAttachment })
    }

    recognize(documentId: number) {
        return this.put<CreateDocumentOutput>(documentId + '/recognize', {});
    };

    getEditViewModel(documentId: number) {
        return this.get<EditDocumentViewModel>(documentId + '/editViewModel');
    };

    setOrganization(documentId: number, organizationId: number) {
        return this.put<SucceededResult>(documentId + '/setOrganization', { organizationId });
    }

    setStatus(documentId: number, status: DocumentStatus) {
        return this.put<SetDocumentStatusOutput>(documentId + '/setStatus', { status });
    }
    
    setContractor(documentId: number, contractorId: number) {
        return this.put<SucceededResult>(documentId + '/setContractor', { contractorId });
    }
    
    setContract(documentId: number, contractId: number) {
        return this.put<SucceededResult>(documentId + '/setContract', { contractId });
    }
    
    setAdditionalAgreement(documentId: number, additionalAgreementId: number) {
        return this.put<SucceededResult>(documentId + '/setAdditionalAgreement', { additionalAgreementId });
    }
    
    setFinancialResponsibilityCenter(documentId: number, frcId: number) {
        return this.put<SucceededResult>(documentId + '/setFinancialResponsibilityCenter', { financialResponsibilityCenterId: frcId });
    }

    swapDocumentSides(documentId: number) {
        return this.put<SucceededResult>(documentId + '/reverseSides');
    }

    bindToStack(documentId: number, stackId: number) {
        return this.put<SucceededResult>(documentId + '/bindToStack/' + stackId);
    }

    unbindDocument(documentId: number) {
        return this.put<SucceededResult>(documentId + '/unbindDocuments');
    }

    completeValidation(documentId: number) {
        return this.put<SucceededResult>(documentId + '/completeValidation');
    }

    rejectValidation(documentId: number) {
        return this.put<SucceededResult>(documentId + '/rejectValidation');
    }

    getRequisitesToCompare(documentId: number) {
        return this.get<CompareRequisitesModel>(documentId + '/getRequisitesToCompare');
    }

    getDownloadFile(documentId: number) {
        return this.getWithFileResult<DocumentFile>(documentId + `/downloadFile`);
    }

    setAsMainInStack(documentId: number) {
        return this.put<SucceededResult>(documentId + '/setAsMainInStack');
    }

    getHistory(documentId: number) {
        return this.get<{ operations: HistoryOperation[] }>(documentId + '/history');
    }

    hasDuplicates(documentId: number) {
        return this.get<boolean>(documentId + '/hasDuplicates');
    }
}
