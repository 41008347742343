import { createContext } from "react";
import ContractorStore from "../accounting/infrastructure/contractor/contractorStore";
import FindDuplicatesStore from "../accounting/infrastructure/findDuplicates/findDuplicatesStore";
import ParagraphDocumentsStore from "../accounting/infrastructure/requirements/paragraphDocumentsStore";
import RequirementsStore from "../accounting/infrastructure/requirements/requirementsStore";
import UploadsStore from "../accounting/infrastructure/uploads/uploadsStore";
import StorageStore from "../accounting/infrastructure/storage/storageStore";
import ValidationStore from "../accounting/infrastructure/validation/validationStore";
import RequirementParagraphsStore from "../accounting/infrastructure/requirements/requirementParagraphsStore";
import UserStore from "../common/infrastructure/UserStore";
import DocumentStackStore from "../accounting/infrastructure/documentStack";
import OrganizationsStore from "../common/infrastructure/OrganizationsStore";
import { NoOriginalStore } from "./noOriginalStore";
import { StaleStore } from "./staleStorage";
import NoOriginalsStore from "../accounting/infrastructure/noOriginals/noOriginalsStore";
import StalesStore from "../accounting/infrastructure/stales/stalesStorage";
import ExternalDocumentsStore from "../accounting/infrastructure/externalDocuments/externalDocumentsStore";
import FrcsStore from "../common/infrastructure/FrcStore";

interface IEntityStores {
    validationStore: ValidationStore,
    uploadsStore: UploadsStore,
    requirementsStore: RequirementsStore,
    requirementParagraphsStore: RequirementParagraphsStore,
    paragraphDocumentsStore: ParagraphDocumentsStore,
    externalDocumentsStore: ExternalDocumentsStore,
    contractorStore: ContractorStore,
    storageStore: StorageStore,
    usersStore: UserStore,
    documentStack: DocumentStackStore,
    organizationsStore: OrganizationsStore
    noOriginalsStore: NoOriginalsStore,
    stalesStore: StalesStore,
    frcsStore: FrcsStore,

    addNewFindDuplicatesStore: (key: string, findDuplicatesStore: FindDuplicatesStore) => FindDuplicatesStore;
    getFindDuplicatesStore: (key: string) => FindDuplicatesStore | undefined;
}

class EntitiesStore implements IEntityStores {
    validationStore: ValidationStore;
    uploadsStore: UploadsStore;
    storageStore: StorageStore;
    findDuplicatesStores: { [key: string]: FindDuplicatesStore };
    requirementsStore: RequirementsStore;
    requirementParagraphsStore: RequirementParagraphsStore;
    paragraphDocumentsStore: ParagraphDocumentsStore;
    externalDocumentsStore: ExternalDocumentsStore;
    contractorStore: ContractorStore;
    usersStore: UserStore;
    documentStack: DocumentStackStore;
    organizationsStore: OrganizationsStore;
    noOriginalsStore: NoOriginalsStore;
    stalesStore: StalesStore;
    frcsStore: FrcsStore;

    constructor() {
        this.validationStore = new ValidationStore(gridIds.validation);
        this.uploadsStore = new UploadsStore(gridIds.uploads);
        this.storageStore = new StorageStore(gridIds.storage);
        this.findDuplicatesStores = {};
        this.findDuplicatesStores = {};
        this.requirementsStore = new RequirementsStore(gridIds.requirements);
        this.requirementParagraphsStore = new RequirementParagraphsStore(gridIds.requirementParagraphs);
        this.paragraphDocumentsStore = new ParagraphDocumentsStore(gridIds.paragraphDocuments);
        this.externalDocumentsStore = new ExternalDocumentsStore(gridIds.externalDocuments);
        this.contractorStore = new ContractorStore(gridIds.contractor);
        this.usersStore = new UserStore(gridIds.users);
        this.documentStack = new DocumentStackStore(gridIds.documentStack);
        this.organizationsStore = new OrganizationsStore(gridIds.organizations);
        this.frcsStore = new FrcsStore(gridIds.frcs)

        this.noOriginalsStore = new NoOriginalsStore(gridIds.noOriginals);
        this.stalesStore = new StalesStore(gridIds.stales);
    }

    addNewFindDuplicatesStore = (key: string, findDuplicatesStore: FindDuplicatesStore) => {
        if (Object.keys(this.findDuplicatesStores).length >= 5)
            delete Object.values(this.findDuplicatesStores)[0];
        
        this.findDuplicatesStores[key] = findDuplicatesStore;

        return this.findDuplicatesStores[key];
    }
    
    getFindDuplicatesStore = (key: string): FindDuplicatesStore | undefined => {
        return this.findDuplicatesStores[key];
    }
}

const gridIds = {
    validation: "validation",
    uploads: "uploads",
    requirements: "requirements",
    paragraphDocuments: "paragraphDocuments",
    externalDocuments: "externalDocuments",
    contractor: "contractor",
    storage: "storage",
    findDuplicates: "findDuplicates",
    requirementParagraphs: "requirementParagraphs",
    users: "users",
    documentStack: "document_stack",
    organizations: "organizations",
    noOriginals: "noOriginals",
    stales: "stales",
    frcs: "frcs"
}

const entitiesStore = new EntitiesStore();

const EntityStoresContext = createContext<IEntityStores>(entitiesStore);

export { EntityStoresContext, entitiesStore, IEntityStores, gridIds };