import { observer } from "mobx-react-lite";
import EntityView from "../../../../common/pages/blanks/Show";
import { useApi } from "../../../../api";
import { FinancialResponsibilityCenter } from "../../../../types/entities/financialResponsibilityCenter";
import { DataType } from "../../../../types/dataType";
import { RootApiType } from "../../../../api/rootApi";
import { SucceededWithDataResult } from "../../../../api/types/succeededResult";
import { EntityField } from "../../../abstractions/showEntity";

const FrcShowView: React.FC = () => {
    const api = useApi();
    const fields: EntityField<FinancialResponsibilityCenter>[] = [
      {source: 'name', label: 'Имя', required: true, type: DataType.String}
    ];

    return (
        <div>
          <EntityView<FinancialResponsibilityCenter>
              showServiceProps={{storeName: "frcsStore", loadEntity: getFrcById(api), onEntityCreate: saveFrc(api), onEntityUpdate: updateFrc(api)}}
              fields={fields}
          />
        </div>
    )
}

const getFrcById = (api: RootApiType) => async (id: number): Promise<FinancialResponsibilityCenter> => {
  const response = await api.financialResponsibilityCenters.getOne(id);
  return {
    ...response!
  };
}

const saveFrc = (api: RootApiType) => async(frc: FinancialResponsibilityCenter): Promise<SucceededWithDataResult<number>> => {
  return await api.financialResponsibilityCenters.create(frc);
}

const updateFrc  = (api: RootApiType) => async (id: number, frc: FinancialResponsibilityCenter): Promise<SucceededWithDataResult<number>> => {
  return await api.financialResponsibilityCenters.update(id, frc);
}

const FrcShow = observer(FrcShowView);

export default FrcShow;