import { MakeArrayFilters } from "../helpers/utils";
import { Contractor } from "../types/entities/contractor";
import Api from "./api";
import { ContractorOutput } from "./types/contractorOutput";
import { getMany, GetManyResult, Sorting } from "./types/getFunctions";

export type ContractorsApiType = {
    getMany: getMany<Contractor>;

    getById: (id: number) => Promise<Contractor>;
    create: (name: string, tin: string, stagingReasonCode: string, address: string) => Promise<ContractorOutput>;
    edit: (id: number, name: string, tin: string, stagingReasonCode: string, address: string) => Promise<ContractorOutput>;
    remove: (contractorId: number) => Promise<ContractorOutput>;
};

export class ContractorsApi extends Api implements ContractorsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'contractors'), userUnauthorizedEventHandler);
    }

    getMany = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<Contractor>, search?: string, subroute?: string) => this.getManyInternal(skip, take, sortings, ids, filter, search, subroute);

    public getById = async (id: number) =>
        (await this.get<GetManyResult<Contractor>>(`?id=${id}`)).items[0];

    public create = (name: string, tin: string, stagingReasonCode: string, address: string) =>
        this.post<ContractorOutput>("", { name, tin, stagingReasonCode, address });

    public edit = (id: number, name: string, tin: string, stagingReasonCode: string, address: string) =>
        this.put<ContractorOutput>(id.toString(), { id, name, tin, stagingReasonCode, address });

    public remove = (contractorId: number) =>
        this.delete<ContractorOutput>(contractorId.toString());
}
