import { Button, Card, CardActions, CardContent, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useApi } from "../../../api";
import useNotificationStore from "../../../stores/notificationStore";
import Logo from '../../../styling/img/logo.svg';
import './style.sass';

const ForgotPassword: React.FC = (props) => {
    const api = useApi();
    const notifications = useNotificationStore();

    const [email, setEmail] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    const onSubmit = async () => {
        const result = await api.account.forgotPassword(email);
        if (result.succeeded) {

        }
        else {
            if (result.errors) {
                setErrorMessage(result.errors.join("\n"));
                notifications.showError("Ошибка при смене пароля.\nПопробуйте еще раз.");
            }
            else
                notifications.showError("Неизвестная ошибка. Попробуйте снова.");
        }
    };

    return (
        <div className='forgotPassword'>
            <img src={Logo} />
            <form onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
            }}>
                <Card className='forgotPassword_card'>
                    <Typography variant="h6" gutterBottom>Восстановление пароля</Typography>
                    <CardContent className="forgotPassword_card-content">
                        <TextField 
                            className="forgotPassword_input" 
                            type="email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            label="Email"
                        />
                        {errorMessage != null ? <span className="forgotPassword_error-field" style={{ display: 'block' }}>{errorMessage}</span> : null}
                    </CardContent>
                    <CardActions>
                        <Button 
                            disabled={!email}
                            className='forgotPassword_button'
                            size="small"
                            type="submit"
                        >
                            Cбросить пароль
                        </Button>
                    </CardActions>
                </Card>
            </form>
        </div>
    );
}

export default ForgotPassword;