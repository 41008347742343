import { AccountApi, AccountApiType } from "./accountApi";
import { ContractorsApi, ContractorsApiType } from "./contractorsApi";
import { DocumentsApi, DocumentsApiType } from "./documentsApi";
import { DocumentTypesApi, DocumentTypesApiType } from "./documentTypes";
import { OrganizationsApi, OrganizationsApiType } from "./organizationsApi";
import { UsersApi, UsersApiType } from "./usersApi";
import { RequirementsApi, RequirementsApiType } from "./requirements/requirementsApi";
import { RequirementParagraphsApi, RequirementParagraphsApiType } from "./requirements/requirementParagraphsApi";
import { FinancialResponsibilityCentersApi, FinancialResponsibilityCentersApiType } from "./financialResponsibilityCentersApi";
import { HistoryOperationsApi, HistoryOperationsApiType } from "./historyOperations";
import { HistoryChangesApi, HistoryChangesApiType } from "./historyChanges";

export type RootApiType = {
    readonly account: AccountApiType,
    readonly users: UsersApiType,
    readonly documents: DocumentsApiType,
    readonly organizations: OrganizationsApiType,
    readonly contractors: ContractorsApiType,
    readonly types: DocumentTypesApiType,
    readonly requirements: RequirementsApiType,
    readonly requirementParagraphs: RequirementParagraphsApiType,
    readonly financialResponsibilityCenters: FinancialResponsibilityCentersApiType,
    readonly historyOperations: HistoryOperationsApiType,
    readonly historyChanges: HistoryChangesApiType,
};

export default class RootApi implements RootApiType {
    account: AccountApiType;
    users: UsersApiType;
    documents: DocumentsApiType;
    organizations: OrganizationsApiType;
    contractors: ContractorsApiType;
    types: DocumentTypesApiType;
    requirements: RequirementsApiType;
    requirementParagraphs: RequirementParagraphsApiType;
    financialResponsibilityCenters: FinancialResponsibilityCentersApiType;
    historyOperations: HistoryOperationsApiType;
    historyChanges: HistoryChangesApiType;

    constructor(basePath: string, userUnauthorizedEventHandler: () => void) {
        this.account = new AccountApi(basePath, userUnauthorizedEventHandler);
        this.users = new UsersApi(basePath, userUnauthorizedEventHandler);
        this.documents = new DocumentsApi(basePath, userUnauthorizedEventHandler);
        this.organizations = new OrganizationsApi(basePath, userUnauthorizedEventHandler);
        this.contractors = new ContractorsApi(basePath, userUnauthorizedEventHandler);
        this.types = new DocumentTypesApi(basePath, userUnauthorizedEventHandler);
        this.requirements = new RequirementsApi(basePath, userUnauthorizedEventHandler);
        this.requirementParagraphs = new RequirementParagraphsApi(basePath, userUnauthorizedEventHandler);
        this.financialResponsibilityCenters = new FinancialResponsibilityCentersApi(basePath, userUnauthorizedEventHandler);
        this.historyOperations = new HistoryOperationsApi(basePath, userUnauthorizedEventHandler);
        this.historyChanges = new HistoryChangesApi(basePath, userUnauthorizedEventHandler);
    }
}