import { observer } from "mobx-react-lite";
import { useCallback } from 'react';
import api from "../../../../api";
import { MakeArrayFilters } from "../../../../helpers/utils";
import { Sorting } from "../../../../api/types/getFunctions";
import Filter from "../../../../common/abstractions/filter";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import { createReferenceColumn, createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import { GridRowParams } from "@mui/x-data-grid";
import './style.sass';
import ParagraphEntity from "../../../../accounting/domain/requirements/requirementParagraph/paragraphEntity";
import ParagraphFilter from "../../../../accounting/domain/requirements/requirementParagraph/paragraphFilter";
import { FiltersControllerProps } from "../../../../common/controller/filters/filtersController";
import { Requirement } from "../../../../types/entities/requirement/requirement";

interface ParagraphDialogProps {
    requirement?: Requirement;
    setParagraphId: (paragraphId: number) => void;
}

const ParagraphsListView = (props: ParagraphDialogProps) => {
    const { requirement, setParagraphId } = props;

    const getMany = async (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], filter?: MakeArrayFilters<Filter>, search?: string) =>
        await api.requirementParagraphs.getMany(skip, take, sortings, ids, { requirementId: [requirement!.id], ...filter }, search);

    return (
        <div>
            <div className="requirement_paragraphs_title"><label>Выберите пункт требования № {requirement!.number} от {new Date(requirement!.requirementDate).toLocaleDateString()}</label></div>
            <EntityListWithFilters<ParagraphEntity, ParagraphFilter, ParagraphListProps, ParagraphFilterProps>
                filterControllerProps={{ requirementId: requirement!.id }}
                listControllerProps={{
                    columns: [
                        createTextColumn<ParagraphEntity>('number', 'Номер', true),
                        createReferenceColumn(createTextColumn<ParagraphEntity>('lastName', 'Ответственный'), 
                            'responsibleId', 'users'),
                        createTextColumn<ParagraphEntity>('comment', 'Комментарий', true),
                        { field: ' ', sortable: false, filterable: false, flex: 1, align: 'right' }
                    ],
                    loadEntities: getMany,
                    setParagraphId
                }}
                storeName='requirementParagraphsStore'
                useListController={useValidationListController}
            />
        </div>
    );
};

const useValidationListController = (props: ParagraphListProps): ListControllerReturnType<ParagraphEntity> => {
    const listProps = useListController(props); 
    const { setParagraphId } = props;

    const onRowClick = useCallback((props: GridRowParams<ParagraphEntity>) => {
        const { id } = props.row;
        setParagraphId(id);
        
    }, [setParagraphId]);

    return {
        ...listProps,
        disableMultipleSelection: true,
        onRowClick: onRowClick,
    } as ListControllerReturnType<ParagraphEntity>;
}

type ParagraphListProps = ListControllerProps<ParagraphEntity, ParagraphFilter> & {
    setParagraphId: (paragraphId: number) => void;
}

type ParagraphFilterProps = FiltersControllerProps<ParagraphEntity, ParagraphFilter> & {
    requirementId: number;
}

const ParagraphTable = observer(ParagraphsListView);

export default ParagraphTable;