import { RootApiType } from "../api/rootApi";


export enum DataType {
    String,
    Integer,
    Decimal,
    Boolean,
    DateTime,
    Enum,
    Reference,
    ManyReference
};

export type DataValue = {
    dataType: DataType,
    value: unknown,
    captionField?: string,
    getCaption?: ((entity: {[k: string]: any}) => string),
    route?: keyof RootApiType,
    fieldForOrder?: string,
    dictionary?: {key: number, value: string}[]
}