import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { createEnumColumn, createReferenceColumn, createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import Link from "../../../../common/components/Link";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import useGoToPageProvider from "../../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import { User } from "../../../../types/entities/user";
import Filter from "../../../abstractions/filter";
import { UserRole } from "../../../../types/userRole";

const UserListView: React.FC = () => {
    const { goToShowUser: goToShowUsers } = useGoToPageProvider();

    return (
        <EntityListWithFilters<User, Filter>
            ListHeaderView={<Link title={"Пользователи"} />}
            listControllerProps={{
                columns: [
                    createTextColumn<User>('firstName', 'Имя', true),
                    createTextColumn<User>('lastName', 'Фамилия', true),
                    createTextColumn<User>('userName', 'Имя пользователя', true),
                    createReferenceColumn(createTextColumn('name', 'Основная организация', true), 'primaryOrganizationId', 'organizations'),
                    {...createEnumColumn<User>('roles', 'Роли', [
                        {key: UserRole.Superuser, value: 'Суперпользователь'},
                        {key: UserRole.Admin, value: 'Администратор'},
                        {key: UserRole.Accountant, value: 'Бухгалтер'},
                        {key: UserRole.ResponsibleAccountant, value: 'Ответственный бухгалтер'},
                    ]), },
                ],
                loadEntities: 'users'
            }}
            storeName="usersStore"
            useListController={useUserListController}
            ListActions={
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => goToShowUsers()}
                >
                    Добавить
                </Button>
            }
        />
    );
};

const useUserListController = (props: ListControllerProps<User, Filter>): ListControllerReturnType<User> => {
    const listProps = useListController(props); 
    const { goToShowUser: goToShowUsers } = useGoToPageProvider();

    const onRowClick = useCallback((props: GridRowParams<User>) => {
        const { id } = props.row;
        goToShowUsers(id);
    }, [goToShowUsers]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<User>;
}

export default observer(UserListView);
