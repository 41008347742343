import { DataGridProProps, GridCallbackDetails, GridSortModel } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { toJS } from 'mobx';
import { useCallback, useMemo } from 'react';
import { getManyByFilter } from '../../../api/types/getFunctions';
import { Entity } from '../../../types/entities/entity';
import Filter from '../../abstractions/filter';
import ListService from '../../abstractions/listService';
import Store from '../../infrastructure/Store';
import { listService } from '../../services/listService';
import LocalStorageService from '../../services/localStorageService';
import { BaseListControllerProps, useBaseListController } from './baseEntityListController';
import { mapSortingToSortModel, mapSortModel, needSkipFirstRender } from './hooks/common';


const useListController = <TEntity extends Entity, TFilter extends Filter>(props: ListControllerProps<TEntity, TFilter>): ListControllerReturnType<TEntity> => {
  const { columns, filterAttributes, storeName, loadEntities, overrideService } = props;
  const localStorageService = useMemo(() => new LocalStorageService(), []);

  const { columns: cols, initialState: baseInitialState, service, apiRef, ...rest} = useBaseListController<TEntity, getManyByFilter<TEntity, TFilter>, ListService<TEntity, TFilter>, Store<TEntity, TFilter>>({
      storeName, loadEntities, 
      overrideService, columns, 
      listService,
      needSkipFirstRender: (service) => needSkipFirstRender(service, filterAttributes)
    });

  const pageSize = useMemo(() => service.pageSize, [service.pageSize]);
  const page = useMemo(() => service.page, [service.page]);
  const sortings = useMemo(() => toJS(service.sortings), [service.sortings]);

  const saveState = useCallback((gridSet?: GridInitialStatePro) => {
    const {preferencePanel, ...gridSettings} = gridSet ?? apiRef!.current.exportState();
    const gridsSettings = localStorageService.get("gridsSettings");

    if (gridsSettings) { 
      gridsSettings[service.gridId] = gridSettings;
      localStorageService.set("gridsSettings", gridsSettings);
    }
    else
      localStorageService.set("gridsSettings", { [service.gridId]: gridSettings });
  }, [apiRef, service.gridId, localStorageService]);

  const onPageChange = useCallback((newPage: number, details) => {
    service.updatePage(newPage);
    let gridSettings = apiRef?.current.exportState();
    // don't now why but `exportState` export `pagination` as prev state pagination, not current
    gridSettings = { ...gridSettings, pagination: { page: newPage, pageSize } };

    saveState(gridSettings);
  } , [apiRef, pageSize, saveState, service]);

  const onPageSizeChange = useCallback((newPageSize: number) => {
    service.updatePageSize(newPageSize);

    let gridSettings = apiRef?.current.exportState();
    // don't now why but `exportState` export `pagination` as prev state pagination, not current
    gridSettings = { ...gridSettings, pagination: { page: page, pageSize: newPageSize } };

    saveState(gridSettings);
  }, [apiRef, page, saveState, service]);

  const onSortingChange = useCallback((model: GridSortModel, details: GridCallbackDetails) => {
    service.updateSortings(mapSortModel(model));
    saveState();
  }, [saveState, service]);

  const defaultProps = useMemo<Pick<ListControllerReturnType<TEntity>, "paginationMode" | "pagination" | "rowsPerPageOptions" | "sortingMode" | "disableColumnFilter">>(() => ({
    paginationMode: 'server',
    pagination: true,
    rowsPerPageOptions: [1, 10, 20, 50, 100],
    sortingMode: 'server',
    disableColumnFilter: true,
  }), []);

  const initialState = useMemo<GridInitialStatePro>(() => ({
    pagination: baseInitialState?.pagination ? baseInitialState.pagination : {
      page: page,
      pageSize: pageSize,
    },
    ...baseInitialState,
    sorting: baseInitialState?.sorting ?? { sortModel: mapSortingToSortModel(sortings) as GridSortModel }
  }), [baseInitialState, page, pageSize, sortings]);

  return {
    ...rest,
    apiRef,
    columns: cols,
    page: page,
    pageSize: pageSize,
    ...defaultProps,

    onPageChange: onPageChange,
    onPageSizeChange: onPageSizeChange,
    onSortModelChange: onSortingChange,
    disableColumnPinning: true,
    // initial state of the grid has wrong type, so we need to cast it to any
    initialState
  }
}

type GridProps<TEntity extends Entity> = DataGridProProps<TEntity> & React.RefAttributes<HTMLDivElement>

interface ListControllerProps<
  TEntity extends Entity, 
  TFilter extends Filter> extends Omit<BaseListControllerProps<TEntity, getManyByFilter<TEntity, TFilter>, ListService<TEntity, TFilter>, Store<TEntity, TFilter>
>, "needSkipFirstRender" | 'listService'> {
  filterAttributes?: TFilter;
}

interface ListControllerReturnType<TEntity extends Entity> extends GridProps<TEntity> {
  showFilters?: boolean  
}

export { useListController, ListControllerProps, ListControllerReturnType };