import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from "../../../../api";
import { MakeArrayFilters } from "../../../../helpers/utils";
import { Sorting } from "../../../../api/types/getFunctions";
import Filter from "../../../../common/abstractions/filter";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import { createReferenceColumn, createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import useGoToPageProvider from "../../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid";
import Link from "../../../../common/components/Link";
import RequirementEntity from "../../../domain/requirements/requirementEntity";
import RequirementFilter from "../../../domain/requirements/requirementFilter";
import { createDateFilter } from "../../../../common/view/listFiltersView/components/filters/components/dateFilter";
import { createNumberFilter } from "../../../../common/view/listFiltersView/components/filters/components/numberFilter";
import { Button } from "@mui/material";
import './style.sass';
import { formatDate } from "../../../../helpers/formatters";
import { requirementStatusCaption } from "../../../../enums/requirementStatus";

const RequirementsListView: React.FC = () => {
    const { goToShowRequirement } = useGoToPageProvider();

    return (
        <div>
            <EntityListWithFilters<RequirementEntity, RequirementFilter>
                ListHeaderView={<div><Link title={"Требования ФНС"} /></div>}
                ListActions={
                    <div className="tool-bar">
                        <Button
                            size="small"
                            variant="contained"
                            onClick={() => goToShowRequirement()}
                        >
                            Добавить
                        </Button>
                    </div>
                }
                listControllerProps={{
                    columns: [
                        { width: 200, field: 'requirementDate', headerName: 'Дата требования', renderCell: (e) => <div>{formatDate(e.value)}</div> },
                        { width: 130, field: 'number', headerName: '№' },
                        { ...createReferenceColumn(createTextColumn<RequirementEntity>('name', 'Организация'), 'organizationId', 'organizations'), width: 200 },
                        { ...createReferenceColumn(
                            { field: 'responsible', headerName: 'Ответственный', renderCell: (e) => <div>{e.value ? (e.value.firstName ? e.value.firstName.substring(0, 1) : '') + '. ' + e.value.lastName : ''}</div> },
                            'responsibleId', 'users'),
                            width: 200
                        },

                        { flex: 1, field: 'status', headerName: 'Статус', renderCell: (e) => <div>{requirementStatusCaption(e.value)}</div> }
                    ],
                    loadEntities: 'requirements'
                }}
                storeName='requirementsStore'
                useListController={useValidationListController}
                filterAttributes={filterAttributes}
            />
        </div>
    );
};

const useValidationListController = (props: ListControllerProps<RequirementEntity, RequirementFilter>): ListControllerReturnType<RequirementEntity> => {
    const listProps = useListController(props);
    const { goToShowRequirement } = useGoToPageProvider();

    const onRowClick = useCallback((props: GridRowParams<RequirementEntity>) => {
        const { id } = props.row;
        goToShowRequirement(id);
    }, [goToShowRequirement]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<RequirementEntity>;
}

const filterAttributes: RequirementFilter = {
    number: createNumberFilter("Номер"),
    requirementDate: createDateFilter("Дата требования"),
    taxOfficeNumber: createNumberFilter("ИФНС"),
    period: createNumberFilter("Период")
};

const RequirementList = observer(RequirementsListView);

export default RequirementList;
