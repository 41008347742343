import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";
import { ArrowBack } from '@mui/icons-material/';
import { useParams } from 'react-router-dom';
import CompareDocument from './components/CompareDocument';
import './style.sass';
import '../../../styling/common/index.sass';
import useQuery from '../../../router/useQuery';
import api from '../../../api';
import File from '../../../types/entities/documentFile';
import useGoToPageProvider from '../../../router/goToPageProvider';
import { SucceededResult } from '../../../api/types';
import BindDocumentToDocument from '../../components/BindDocumentToDocument';
import { DocumentType } from '../../../types/entities/documentType';

const CompareDocuments: React.FC = () => {
    const { 
        compareDocumentId,
        mainDocumentId,
        canBind
    } = useCompareDocumentsParamsFromQuery();
    const { goBack, goToCompareDocuments } = useGoToPageProvider();

    const [canBindInternal, setCanBindInternal] = useState(canBind);

    const [ mainFile, setMainFile ] = useState<File>();
    const [ reloadDocuments, setReloadDocuments ] = useState(false);
    const [ compareFile, setCompareFile ] = useState<File>();

    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>();

    useEffect(() => {
        (async () => {
            const documentsFile = await api.documents.getDownloadFile(mainDocumentId);
            setMainFile(documentsFile);
        })();
        (async () => {
            const documentsFile = await api.documents.getDownloadFile(compareDocumentId);
            setCompareFile(documentsFile);
        })();
        (async () => {
            const documentTypes = await api.documents.getAllTypes()
            setDocumentTypes(documentTypes);
        })();
    }, []);

    const handleCanBindResult = (res: SucceededResult) => {
        if (res.succeeded) {
            setCanBindInternal(false);
            goToCompareDocuments(mainDocumentId, compareDocumentId, false, true);
        }
    }
    
    return (
        <div >
            <div className="common_header recognition_flex-header">
                <Button
                    color='inherit'
                    startIcon={<ArrowBack />}
                    onClick={goBack}
                >
                    Назад
                </Button>
                <p>Сравнение версий документов</p>
            </div>
            {canBindInternal &&
                <div className="action-buttons show_action-buttons">
                    <BindDocumentToDocument 
                        text="Связать документы"
                        documentId={mainDocumentId}
                        selectedDocs={[compareDocumentId]}
                        afterBindHandler={handleCanBindResult}
                    />
                </div>
            }
            <div className="compare_documents-container">
                <CompareDocument 
                    documentId={mainDocumentId}
                    file={mainFile}
                    reload={reloadDocuments}
                    onDocumentChanged={() => setReloadDocuments(!reloadDocuments)}
                    documentTypes={documentTypes!}
                    canBind={canBind}
                />
                <CompareDocument 
                    documentId={compareDocumentId}
                    button={true} 
                    file={compareFile}
                    reload={reloadDocuments}
                    onDocumentChanged={() => setReloadDocuments(!reloadDocuments)}
                    documentTypes={documentTypes!}
                    canBind={canBind}
                />
            </div>
        </div>
    );
};

const useCompareDocumentsParamsFromQuery = () => {
    const query = useQuery();
    const compareDocumentId = Number(query.get('cId'));
    const canBind = query.get('canBind') === 'true';
    const { mainDocumentId: mainDocumentIdString } = useParams<{ mainDocumentId?: string }>();
    const mainDocumentId = Number(mainDocumentIdString);

    return {
        compareDocumentId,
        mainDocumentId,
        canBind
    }
}

export default CompareDocuments;