import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import ValidationDocument from "../../domain/validation/validationEntity";
import ValidationFilter from "../../domain/validation/validationFilter";

export default class ValidationStore extends Store<ValidationDocument, ValidationFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}