import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField, CircularProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './style.sass';
import '../../../styling/common/index.sass';
import api from '../../../api';
import { EditMode } from '../ParagraphsTable';
import { RequirementParagraph } from '../../../types/entities/requirement/requirement';
import { TitledEntity } from '../../../types/titledEntity';
import { AutocompleteEntityPicker } from '../../../common/components/AutocompleteEntityPicker';
import { User } from '../../../types/entities/user';
import { getShortNameForUser } from '../../../helpers/formatters';

export interface ParagraphDialogProps {
  open: boolean;
  onClose: () => void;

  editMode: EditMode;
  requirementId: number;
  paragraphs: RequirementParagraph[];
  editingParagraph: RequirementParagraph | undefined;

  setRequirementParagraphs: (paragraphs: RequirementParagraph[]) => void;
}

export default function ShowParagraphDialog(props: ParagraphDialogProps) {
  const { onClose, open, requirementId, editingParagraph, editMode, paragraphs, setRequirementParagraphs } = props;

  const [number, setNumber] = useState<string>();
  const [responsible, setResponsible] = useState<TitledEntity>();
  const [comment, setComment] = useState<string | undefined>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open && editingParagraph){
      setNumber(editingParagraph.number);

      setResponsible(editingParagraph.responsible!.id
        ? { id: editingParagraph.responsible!.id, title: getShortNameForUser(editingParagraph.responsible!) }
        : { id: 0, title: '' });

      setComment(editingParagraph.comment);
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const findResponsible = async (text: string) => {
    const { items } = await api.users.getMany(undefined, undefined, undefined, undefined, undefined, text);
    return items.map(c => ({ id: c.id, title: getShortNameForUser(c) }));
  }

  const onResponsibleChanged = async (user: TitledEntity) => {
    setResponsible(user);
  }

  const createParagraph = async (requirementId: number, number: string, responsible?: User, comment?: string) => {
    setLoading(!loading);
    
    await api.requirementParagraphs.create(requirementId, number, responsible!.id, comment)
    .then(e => { 
      setLoading(false);
      
      setRequirementParagraphs([{ id: e.paragraphId, number, responsible, comment, documents: [], documentsCount: 0 }, ...props.paragraphs]);
      
      handleClose();
    })
    .catch(e => {
      setLoading(false);
    })
  }

  const editParagraph = async (paragraphId: number, number: string, responsible?: User, comment?: string) => {
    setLoading(!loading);
    
    await api.requirementParagraphs.edit(paragraphId, number, responsible!.id, comment)
    .then(e => setLoading(false))
    .catch(e => setLoading(false))

    var requirementParagraph = paragraphs.filter(p => p.id == paragraphId)[0];

    if (requirementParagraph){
        requirementParagraph.number = number;

        requirementParagraph.responsible!.id = responsible!.id
        requirementParagraph.responsible!.firstName = responsible!.firstName;
        requirementParagraph.responsible!.lastName = responsible!.lastName;

        requirementParagraph.comment = comment;
    }

    setRequirementParagraphs(paragraphs);

    handleClose();
  }

  const deleteParagraph = async (paragraph: RequirementParagraph) => {
    setLoading(!loading);

    await api.requirementParagraphs.remove(paragraph.id)
    .then(e => setLoading(false))
    .catch(e => setLoading(false))

    setRequirementParagraphs([...paragraphs.filter(p => p.id != paragraph.id)]);

    handleClose();
  }
  
  return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle>{editMode == EditMode.Add ? 'Добавление пункта' : 'Редактирование пункта'}</DialogTitle>
        <div className="dialog_content paragraph_dialog">

        {editMode == EditMode.Add || editMode == EditMode.Edit ?
          <div>
        <div className="common_select_container">
            <p>Номер пункта: <span className='red-label'>*</span></p>
            <FormControl variant="outlined" className='paragraphs_select-style'>
                <TextField placeholder="Введите номер"
                defaultValue={number}
                onChange={(e) => setNumber(e.target.value)}
                className='dialog_textfield'></TextField>
            </FormControl>
        </div>
        <div className="common_select_container">
            <p>Ответственный:</p>
            <FormControl variant="outlined" className='paragraphs_select-style'>
              <AutocompleteEntityPicker
                className='items-search requirements_select-style'
                entitySource={findResponsible}
                onPicked={onResponsibleChanged}
                value={responsible} />
            </FormControl>
        </div>
        <div className="common_select_container">
            <p>Комментарий:</p>
            <FormControl variant="outlined" className='paragraphs_select-style'>
                <TextField
                multiline
                rows={3}
                maxRows={4}
                defaultValue={comment}
                placeholder="Введите комментарий"
                onChange={(e) => setComment(e.target.value)}
                className='dialog_textfield'></TextField>
            </FormControl>
        </div>
        </div>
        : 
        <div>
          <h4>Вы уверены, что хотите удалить пункт {editingParagraph!.number}?</h4>
        </div>
        }
        <div className="dialog_actions">
            <Button 
             disabled={!number}
             variant="contained"
             className='dialog_button' color={loading ? 'secondary' : 'primary'} 
             onClick={() => {
              switch(editMode) {
                case EditMode.Add:
                  createParagraph(requirementId, number!, { id: responsible ? responsible.id : 0, userName: '', firstName: '', lastName: responsible ? responsible.title : '' }, comment);
                  break;
                case EditMode.Edit:
                  editParagraph(
                    editingParagraph ? editingParagraph.id : 0, number!, 
                    { id: responsible ? responsible!.id : 0, userName: '', firstName: '', lastName: responsible ? responsible!.title : '' }, 
                    comment);
                  break;
                case EditMode.Delete:
                  if (props.editingParagraph)
                    deleteParagraph(props.editingParagraph);
                  break;
              }}
              }>

              {loading
                ? <CircularProgress size={25} color='info'></CircularProgress> 
                : <span>{editMode}</span>
              }
              </Button>
        </div>
        </div>
    </Dialog>
  );
}
