import { useApi } from './../../../api/index';
import { useCallback, useContext, useEffect, useState} from "react";
import { EntityStoresContext, IEntityStores } from "../../../stores/entitiesStore";
import { Entity } from "../../../types/entities/entity";
import Filter, { FilterType } from '../../abstractions/filter';
import Store from "../../infrastructure/Store";
import { filterService } from "../../services/filterService";
import { useEffectSetFilterAttributes } from "../entityListController/hooks/setFilterAttributes";
import useDebounce from '../../utils/hooks/debounce';

function useFiltersController<TEntity extends Entity, TFilter extends Filter>({customeFiltersComponents, storeName, filterAttributes, showSearch = true}: FiltersControllerProps<TEntity, TFilter> ): FiltersControllerReturnType<TFilter> {
  const context = useContext(EntityStoresContext);
  let store = typeof storeName === "string" ? context[storeName as keyof IEntityStores] as unknown as Store<TEntity, TFilter> : storeName(context);
  
  const api = useApi();
  const service = filterService(store, api);
  const [searchInput, setSearchInput] = useState<string>();
  const debInputValue  = useDebounce(searchInput, 275);
  
  useEffect(() => {
    service.updateSearch(debInputValue);
  }, [debInputValue]);

  const isFiltersExist = useCallback(
    () => Object.entries(service.filters).filter(([_, { needShowFilter }]) => needShowFilter).length > 0,
    [service]
  );

  const filtersValues = 
    Object.values(service.filters)
      .filter(filter => filter.type === FilterType.Reference)
      .map(filter => filter.values)
      .reduce((a, b) => a.concat(b), []);
  
  useEffect (() => {
    if (filtersValues.length === 0)
      return;
      
    (async () => await service.loadReferenceChips())();

  }, [JSON.stringify(filtersValues)]);

  useEffectSetFilterAttributes(service, filterAttributes);

  return {
    showFilters: service.showFilters,
    filters : service.filters,
    search: searchInput,
    showSearch: showSearch,
    
    isFiltersExist: isFiltersExist,
    updateShowFilters : service.updateShowFilters,
    updateFilter: service.updateFilter,
    resetFilter: service.resetFilter,
    removeFilterValue: service.removeFilterValue,
    updateSearch: setSearchInput,

    customeFiltersComponents
  }
};

interface FiltersControllerProps<TEntity extends Entity, TFilter extends Filter> {
  customeFiltersComponents?: JSX.Element;
  storeName: ((context: IEntityStores) => Store<TEntity, TFilter>) | keyof IEntityStores;
  filterAttributes: TFilter
  showSearch?: boolean;
}

interface FiltersControllerReturnType<TFilter extends Filter> {
  showFilters : boolean;
  filters: TFilter;
  search: string | undefined;
  showSearch: boolean;

  isFiltersExist: () => boolean;
  updateShowFilters : (showFilters: boolean) => void;
  updateFilter: (key: keyof TFilter, value: any, customUpdate?: (filter: Filter, key: keyof TFilter, value: any) => void) => void;
  resetFilter: (key: keyof TFilter) => void;
  removeFilterValue: (key: keyof TFilter, index: number) => void;
  updateSearch: (search: string) => void;

  customeFiltersComponents?: JSX.Element;
}

export { useFiltersController, FiltersControllerReturnType, FiltersControllerProps };