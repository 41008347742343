import { useHistory } from "react-router-dom";
import { UserData } from "../helpers/userData";
import Routes from "../router/routes";
import RootApiMockup from "./mockups/rootApiMockup";
import RootApi, { RootApiType } from "./rootApi";

const mock = false;
const basePath = '/api/v1/';

const api: RootApiType = mock ? new RootApiMockup() : new RootApi(basePath, () => {});

export const useApi: () => RootApiType = () => {
    const history = useHistory();
    const onUserUnauthorized = () => {
        UserData.clear();
        history.push(Routes.signIn);
    };

    return mock ? new RootApiMockup() : new RootApi(basePath, onUserUnauthorized);
}

export default api;