import React, { PropsWithChildren, useEffect, useState } from 'react';
import Logo from '../../../styling/img/logo.svg';
import Profile from '../../../styling/img/profile.svg';
import { IconButton, AppBar, Box, List, ListItemButton as ListItem, ListItemText, Drawer, Menu, MenuItem, Avatar, Tooltip, Collapse, Snackbar, Alert } from '@mui/material';
import './style.sass';
import { HelpOutline as Info, Dehaze, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useHistory, withRouter } from 'react-router-dom';
import { UserData } from '../../../helpers/userData';
import Routes from '../../../router/routes';
import { checkRouteEquals, getRoutesByRoles } from '../../../helpers/layoutRouteOrganization';
import { observer } from 'mobx-react-lite';
import useNotificationStore from '../../../stores/notificationStore';

const Layout: React.FC<PropsWithChildren<any>> = (props) => {
    const notificationStore = useNotificationStore();
    const [showDrawer, setShowDrawer] = useState(false);
    const [itemList, setItemList] = useState(getRoutesByRoles());
    const [showUserSettings, setShowUserSettings] = useState(false);
    const history = useHistory();
    const currentLocation = history.location.pathname;
    const token = UserData.accessToken;

    useEffect(() => {
        configureOpenedElements();
    }, [])

    if (!token)
        return (<div>
            {props.children}
        </div>);

    const showInnerItems = (i: number) => {
        let newItems = [...itemList];
        newItems[i].opened = !newItems[i].opened;
        setItemList(newItems);
    }

    const showDrawerSettings = (newShowDrawer: boolean) => {
        setShowDrawer(newShowDrawer);
        if (newShowDrawer)
            configureOpenedElements()
    }

    const pushToRoute = (route: Routes) => {
        history.push(route);

        configureOpenedElements();
        showDrawerSettings(false);
    }

    const configureOpenedElements = () => {
        const itemList = getRoutesByRoles().map(e => Object.assign(e, { opened:
            e.innerItems?.find(e => checkRouteEquals(e.path, currentLocation)) !== undefined ||
            e.innerItems?.map(iI => iI.innerRoutes?.find(r => checkRouteEquals(r, currentLocation)))?.filter(e => e)?.length! > 0
        }));
        setItemList(itemList);
    }

    const handleLogout = () => {
        UserData.clear();
        history.push(Routes.signIn);
    }

    return (
        <div>
            <AppBar position='static' color='secondary'>
                <div className='header'>
                    <div className='header_flex'>
                        <div className='header_dehaze'>
                            <IconButton
                                color='primary'
                                onClick={() => showDrawerSettings(!showDrawer)}
                            >
                                <Dehaze />
                            </IconButton>
                        </div>
                        <img src={Logo} alt='Logo'/>
                    </div>
                    <div className='header_flex'>
                        <Info color='secondary'/>
                        <Box className='header_avatar'>
                            <Tooltip title="Открыть настройки">
                                <IconButton onClick={() => setShowUserSettings(true)}>
                                    <Avatar src={Profile} className='header_img-profile'/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                className='header_avatar__menu'
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={showUserSettings}
                                onClose={() => setShowUserSettings(false)}
                            >
                                <MenuItem onClick={handleLogout}>
                                    Выйти
                                </MenuItem>
                            </Menu>
                        </Box>
                    </div>
                </div>
            </AppBar>
            <Drawer
                open={showDrawer}
                onClose={() => showDrawerSettings(false)}
            >
                <Box>
                    <List>
                    {itemList.map((e, index) => (
                        <Box key={index}>
                            <ListItem
                                selected={e.path && checkRouteEquals(e.path, currentLocation) || e.innerRoutes?.find(r => checkRouteEquals(r, currentLocation)) !== undefined}
                                className='drawer_listItem'
                                onClick={() => {
                                    if (e.path) pushToRoute(e.path)                                     
                                    else showInnerItems(index)
                                }}
                            >
                                <ListItemText primary={e.name}/>
                                <div>
                                    {e.innerItems?.length && (e.opened ? <ExpandLess /> : <ExpandMore />)}
                                </div>
                            </ListItem>
                            {
                                e.innerItems &&
                                <Collapse in={e.opened} timeout="auto" unmountOnExit>
                                    <List>
                                        {e.innerItems.map(ce =>
                                            <ListItem
                                                selected={checkRouteEquals(ce.path, currentLocation) || ce.innerRoutes?.find(r => checkRouteEquals(r, currentLocation)) !== undefined}
                                                key={ce.name}
                                                className='drawer_listItem__inner'
                                                onClick={() => {
                                                    pushToRoute(ce.path)
                                                }}
                                            >
                                                <ListItemText primary={ce.name}/>
                                            </ListItem>
                                        )}
                                    </List>
                                </Collapse>
                            }
                        </Box>
                    ))}
                    </List>
                </Box>
            </Drawer>
            <div className='container'>{props.children}</div>
            <Snackbar
                open={notificationStore.IsOpen}
                autoHideDuration={notificationStore.HideDuration}
                onClose={() => notificationStore.IsOpen = false}
                children={
                    <Alert severity={notificationStore.Severity} >{notificationStore.Text}</Alert>
                }
            />
        </div>
    )
}

export default withRouter(observer(Layout));