import { makeAutoObservable } from "mobx";
import { Document } from "../types/entities/document";

export class StaleStore {
    private _stales: Document[];

    constructor() {
        this._stales = [];
        makeAutoObservable(this);
    }

    public get stales() {
        return this._stales;
    }

    public set stales(stales: Document[]) {
        this._stales = stales;
    }
}