import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import FindDuplicatesDocument from "../../domain/findDuplicates/findDuplicatesEntity";
import FindDuplicatesDocumentFilter from "../../domain/findDuplicates/findDuplicatesFilter";

export default class FindDuplicatesStore extends Store<FindDuplicatesDocument, FindDuplicatesDocumentFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}