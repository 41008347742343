import { RootApiType } from './../../api/rootApi';
import Filter from "../abstractions/filter";
import ListService, { BaseListService } from "../abstractions/listService";
import Store from '../infrastructure/Store';
import IStore from '../infrastructure/BaseStore';
import { getManyByFilter, GetManyApi, getMany } from "../../api/types/getFunctions";
import { Entity } from "../../types/entities/entity";

const baseListService = <
  TEntity extends Entity, 
  TGetMany extends Function = getMany<TEntity>
>(
  store: IStore<TEntity>, 
  loadEntities: TGetMany | keyof RootApiType,
  api: RootApiType
) : BaseListService<TEntity> => {
  const loadAndSaveEntities = async () => {
    let result = undefined;
    if (typeof loadEntities === "string") {
      const loadFunc = (api[loadEntities as keyof RootApiType] as unknown as GetManyApi<TEntity>).getMany;
      if (!loadFunc)
        throw new Error(`${loadEntities} is not a valid api name in "loadEntities", or it doesn't have getMany function`);

      result = await loadFunc();
    }
    else
      result = await loadEntities();

    store.resetEntities(result.items);
    store.updateTotalCount(result.totalCount);

    return result.items;
  }

  const loadSubEntities = async (subroute: keyof RootApiType, ids: number[]) => {
    const client = api[subroute] as unknown as GetManyApi<TEntity>;

    if (client.getMany === undefined)
      throw new Error(`Can't load sub entities for filter ${subroute}, because it doesn't have getMany method in api`);

    const entities = await client.getMany(undefined, ids.length, undefined, ids);

    if (!entities || entities.items.length === 0) {
      console.warn(`Can't load sub entities for filter ${subroute}, because it doesn't have any items, totalCount: ${entities.totalCount}`);
      return;
    }

    store.updateSubEntities(subroute, entities.items);
  }

  const getLoadingDeps = () => {
    return [];
  }

  return {
    entities: store.entities,
    totalCount: store.totalCount,
    subEntities: store.subEntities,
    gridId: store.gridId,

    loadAndSaveEntities,
    loadSubEntities,
    getLoadingDeps,
  };
}

const listService = <TEntity extends Entity, TFilter extends Filter>(
  store: Store<TEntity, TFilter>, 
  loadEntities: getManyByFilter<TEntity, TFilter> | keyof RootApiType,
  api: RootApiType
) : ListService<TEntity, TFilter> => {
  
  const overrLoadLoadEntities = async () => {
    const filters = {} as { [K in keyof TFilter] : Array<any> };
    for (const key in store.filters) {
      filters[key as keyof TFilter] = store.filters[key].values;
    }

    let result = undefined;
    if (typeof loadEntities === "string") {
      const loadFunc = (api[loadEntities as keyof RootApiType] as unknown as GetManyApi<TEntity>).getMany;
      if (!loadFunc)
        throw new Error(`${loadEntities} is not a valid api name in "loadEntities", or it doesn't have getMany function`);

      result = await loadFunc(store.page * store.pageSize, store.pageSize, store.sortings, undefined, filters as any, store.search);
    }
    else
      result = await loadEntities(store.page * store.pageSize, store.pageSize, store.sortings, undefined, filters, store.search);

    return result;
  }

  const { loadAndSaveEntities, getLoadingDeps, ...rest } = baseListService<TEntity, getManyByFilter<TEntity, TFilter>>(store, overrLoadLoadEntities, api);

  const getLoadingDepsOverrload = () => {
    const filtersValues = Object
      .values(store.filters)
      .map(filter => filter.values)
      .reduce((a, b) => a.concat(b), []);

    return [...getLoadingDeps(), store.page, store.pageSize, store.sortings, filtersValues, store.search];
  }

  return {
    ...rest,

    page: store.page,
    pageSize: store.pageSize,
    sortings: store.sortings,

    filters: store.filters,

    loadAndSaveEntities: loadAndSaveEntities,

    updatePage: store.updatePage,
    updatePageSize: store.updatePageSize,
    updateSortings: store.updateSortings,

    getLoadingDeps: getLoadingDepsOverrload,
  }
}

interface BaseListServiceType<TEntity extends Entity, TGetMany extends Function = getMany<TEntity>, TStore extends IStore<TEntity> = IStore<TEntity>, TListService extends BaseListService<TEntity> = BaseListService<TEntity>> {
  (store: TStore, loadEntities: TGetMany | keyof RootApiType, api: RootApiType) : TListService;
}

export { listService, baseListService, BaseListServiceType };
