import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import { Document } from "../../../types/entities/document";
import UploadedDocumentFilter from "../../domain/uploads/uploadsFilter";

export default class UploadsStore extends Store<Document, UploadedDocumentFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}