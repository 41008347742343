import FilePresentIcon from '@mui/icons-material/FilePresent';
import api from '../../../api';
import './style.sass'

interface IFileLabelWithNameProps {
    fileName: string,
    fileId?: number,
}

export const FileLabelWithName: React.FC<IFileLabelWithNameProps> = (props) => {
    const onFileClick = async () => {
        if (props.fileId){
            const { file, filename } = await api.documents.getDownloadFile(props.fileId);
            require("downloadjs")(file, filename);
        }
    }
    
    const showFileName = (fileName: string) => fileName ? (fileName.length >= 23 ? fileName.substring(0, 23) + '...' : fileName) : '';

    return (
        <div className="common_select_container select-file fileName-label">
            <p>Файл:</p>
            <div onClick={onFileClick} id="filename" className="fileName_container">
                <span className="name-label" title={props.fileName}><FilePresentIcon /><div className="filename_div__trippleDot">{showFileName(props.fileName)}</div></span>
            </div>
        </div>
    )
}