import { User } from "../types/entities/user";
import Api, { EditableEntityApi } from "./api";
import { SucceededResult } from "./types";
import { getMany, GetManyApi, getOne } from "./types/getFunctions";
import { SucceededWithDataResult } from "./types/succeededResult";

export type UsersApiType = EditableEntityApi<User, SucceededWithDataResult<number>, SucceededWithDataResult<number>> & GetManyApi<User> & {
    getOne: getOne<User>;
    getMany: getMany<User>;
};

export class UsersApi extends Api implements UsersApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'users'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal.bind(this);
    getMany = this.getManyInternal.bind(this);

    create = this.createInternal.bind(this);
    update = this.updateInternal.bind(this);
}
