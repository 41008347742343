import { useEffect } from "react";
import Filter from "../../../abstractions/filter";
import { FilterService } from "../../../services/filterService";
import { needSetFilterAttributes } from "./common";

const useEffectSetFilterAttributes = <TFilter extends Filter>(service: FilterService<TFilter>, filterAttributes?: TFilter) => {
  useEffect(() => {
    if (needSetFilterAttributes<TFilter>(service, filterAttributes)) {
      service.updateFilters(filterAttributes as TFilter);
    }
  }, [filterAttributes]);
}

export { useEffectSetFilterAttributes };
