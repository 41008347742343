import { MakeArrayFilters } from "../../helpers/utils";
import { RequirementParagraph } from "../../types/entities/requirement/requirement";
import Api from "../api";
import { getMany, getOne, Sorting } from "../types/getFunctions";
import { AddDocumentsOutput } from "../types/requirements/addDocumentsOutput";
import { CreateRequirementParagraphOutput } from "../types/requirements/createRequirementParagraphOutput";
import { DeleteRequirementParagraphOutput } from "../types/requirements/deleteRequirementParagraphOutput";
import { EditRequirementParagraphOutput } from "../types/requirements/editRequirementParagraphOutput";
import { ParagraphDocumentsOutput } from "../types/requirements/paragraphDocumentsOutput";
import { RemoveDocumentsOutput } from "../types/requirements/removeDocumentsOutput";

export type RequirementParagraphsApiType = {
    getOne: getOne<RequirementParagraph>;
    getMany: getMany<RequirementParagraph>;
    create: (requirementId: number, number: string, responsibleId?: number, comment?: string) => Promise<CreateRequirementParagraphOutput>;
    edit: (paragraphId: number, number: string, responsibleId?: number, comment?: string) => Promise<EditRequirementParagraphOutput>;
    remove: (paragraphId: number) => Promise<DeleteRequirementParagraphOutput>;
    getParagraphDocuments: (paragraphId: number, page: number, size: number) => Promise<ParagraphDocumentsOutput>;
    getDocumentsToAdd: (paragraphId: number, page: number, size: number) => Promise<ParagraphDocumentsOutput>;
    addDocuments: (paragraphId: number, documentIds: number[]) => Promise<AddDocumentsOutput>;
    removeDocuments: (paragraphId: number, documentIds: number[]) => Promise<RemoveDocumentsOutput>;
}

export class RequirementParagraphsApi extends Api implements RequirementParagraphsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'accounting/requirementParagraphs'), userUnauthorizedEventHandler);
    }
    
    getOne = this.getOneInternal;
    getMany = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<RequirementParagraph>, search?: string, subroute?: string) => this.getManyInternal(skip, take, sortings, ids, filter, search, subroute);

    public create = (requirementId: number, number: string, responsibleId?: number, comment?: string) =>
        this.post<CreateRequirementParagraphOutput>('create', { requirementId, number, responsibleId, comment });

    public edit = (paragraphId: number, number: string, responsibleId?: number, comment?: string) =>
        this.put<EditRequirementParagraphOutput>(`${paragraphId}`, { number, responsibleId, comment });

    public remove = (paragraphId: number) =>
        this.delete<DeleteRequirementParagraphOutput>(`delete/${paragraphId}`, {});

    public getParagraphDocuments = (paragraphId: number, page: number, size: number) =>
        this.get<ParagraphDocumentsOutput>(`${paragraphId}/getParagraphDocuments?page=${page}&size=${size}`);

    public getDocumentsToAdd = (paragraphId: number, page: number, size: number) =>
        this.get<ParagraphDocumentsOutput>(`${paragraphId}/getDocumentsToAdd?page=${page}&size=${size}`);

    public addDocuments = (paragraphId: number, documentIds: number[]) =>
        this.post<AddDocumentsOutput>(`${paragraphId}/addDocuments`, { paragraphId, documentIds });

    public removeDocuments = (paragraphId: number, documentIds: number[]) =>
        this.delete<RemoveDocumentsOutput>(`${paragraphId}/removeDocuments`, { paragraphId, documentIds });
}