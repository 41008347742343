
export default class LocalStorageService {
    
    get(key: string) {
        const value = localStorage.getItem(key);
        if (value)
          return JSON.parse(value);
        
        return undefined;
    }
    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    remove(key: string) {
        localStorage.removeItem(key);
    }
    clear() {
        localStorage.clear();
    }
}