import React, { useEffect, useState } from 'react';
import './style.sass';
import '../../../../styling/common/index.sass';
import api from "../../../../api";
import { Button, FormControl, MenuItem, Select, TextField, CircularProgress } from "@mui/material";
import { ArrowBack } from '@mui/icons-material/';
import Routes, { getRequirementRoute } from '../../../../router/routes';
import { useHistory, useParams } from 'react-router-dom';
import { UserData } from '../../../../helpers/userData';
import ParagraphsTable from '../../../../components/Requirements/ParagraphsTable';
import DownloadDocumentsDialog from '../../../../components/Requirements/DownloadDocumentsDialog';
import { RequirementStatus, requirementStatusCaption } from '../../../../enums/requirementStatus';
import { Contractor } from '../../../../types/entities/contractor';
import { User } from '../../../../types/entities/user';
import { FileModel } from '../../../../types/entities/fileModel';
import { RequirementParagraph } from '../../../../types/entities/requirement/requirement';
import { Organization } from '../../../../types/entities/organization';
import { AutocompleteEntityPicker } from '../../../../common/components/AutocompleteEntityPicker';
import { TitledEntity } from '../../../../types/titledEntity';
import { formatDate, getShortNameForUser } from '../../../../helpers/formatters';
import RequirementActionDialog, { RequirementAction } from '../../../../components/Requirements/RequirementActionDialog';
import LoopIcon from '@mui/icons-material/Loop';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import "downloadjs";

const Requirement: React.FC = () => {
    const history = useHistory();

    const { id: initialRequirementId } = useParams<{ id: string }>();
    const requirementId = Number(initialRequirementId);

    const [hasAccess, setAccess] = useState<boolean>(false);
    const [hasAccessAuthor, setAccessAuthor] = useState<boolean>(false);
    const [number, setNumber] = useState<string>();
    const [organization, setOrganization] = useState<Organization>()
    const [requirementDate, setRequirementDate] = useState<string>();
    const [dateReceived, setDateReceived] = useState<string | null>(null);
    const [dateSubmitting, setDateSubmitting] = useState<string>();
    const [period, setPeriod] = useState<string>();
    const [tin, setTin] = useState<string>();
    const [taxOfficeNumber, setTaxOfficeNumber] = useState<string>();

    const [lastDownloadDate, setLastDownloadDate] = useState<string>();
    const [downloadedUser, setDownloadedUser] = useState<User>();
    const [requirement, setRequirement] = useState<any>();

    const [responsibles, setResponsibles] = useState<User[]>([]);

    const [requirementStatus, setRequirementStatus] = useState<RequirementStatus>(RequirementStatus.New);
    const [file, setFile] = useState<File | undefined>();
    const [fileModel, setFileModel] = useState<FileModel>();

    const [requirementParagraphs, setRequirementParagraphs] = useState<RequirementParagraph[]>([]);

    const [organizations, setOrganizations] = useState<Organization[]>([]);

    const [auditors, setAuditors] = useState<Contractor[]>([]);

    const [auditor, setAuditor] = useState<TitledEntity>();
    const [responsible, setResponsible] = useState<TitledEntity>();

    const [auditorId, setAuditorId] = useState<number>();
    const [responsibleId, setResponsibleId] = useState<number>();

    const [requirementActionDialogOpen, setRequirementActionDialogOpen] = useState(false);
    const [action, setAction] = useState<RequirementAction>(RequirementAction.SendToArchive);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!organizations.length)
                await loadUserOrganizations();
        })();
    }, [true]);

    useEffect(() => {
        (async () => {
            if (!requirement && initialRequirementId) {
                await updatePage();
            }
        })();
    }, []);
 
    const loadUserOrganizations = async () => {
        const { items } = await api.organizations.getMany(undefined, undefined, undefined, UserData.organizations);
        setOrganizations(items);
    };

    const findAuditor = async (text: string) => {
        const { items } = await api.contractors.getMany(undefined, undefined, undefined, undefined, undefined, text);
        setAuditors(items);
        return items.map(c => ({ id: c.id, title: c.name, tin: c.tin }));
    }

    const findResponsible = async (text: string) => {
        const { items } = await api.users.getMany(undefined, undefined, undefined, undefined, undefined, text);
        setResponsibles(items);
        return items.map(c => ({ id: c.id, title: getShortNameForUser(c) }));
    }

    const onAuditorChange = (auditor: TitledEntity) => {
        setTin(auditors.filter(a => a.id == auditor.id)[0].taxpayerIdentificationNumber);
        setAuditorId(auditor.id);
        handleAuditorChange(auditor ? auditor!.id : 0);
    }

    const onResponsibleChange = (user: TitledEntity) => {
        setResponsibleId(user.id);
        handleResponsibleChange(user ? user!.id : 0);
    }

    const updatePage = async (id?: number) => {
        const response = await api.requirements.getEditRequirementViewModel(id ?? requirementId);

        setRequirement(response);
        setAction(response.status == RequirementStatus.Completed || response.status == RequirementStatus.Canceled
        ? RequirementAction.ResumeWork : RequirementAction.SendToArchive);

        setAccess(response.hasAccess);
        setAccessAuthor(response.author);
        setFileModel(response.file);
        setNumber(response.number);
        setOrganization(response.organization);

        setAuditor(response.auditor
            ? { id: response.auditor.id, title: response.auditor.name }
            : { id: 0, title: '' });

        setRequirementDate(response.requirementDate.toString().split('T')[0]);

        if (response.dateReceived)
            setDateReceived(response.dateReceived!.toString().split('T')[0]);

        if (response.dateSubmitting)
            setDateSubmitting(response.dateSubmitting!.toString().split('T')[0]);

        setTin(response.tin);
        setPeriod(response.period);
        setTaxOfficeNumber(response.taxOfficeNumber);

        setDownloadedUser(response.downloadedUser);
        setRequirementParagraphs(response.paragraphs);

        const responsible = response.responsible;
        if (responsible != null) {
            setResponsible({ id: response.responsible!.id, title: getShortNameForUser(responsible) });
        }

        setLastDownloadDate(formatDate(response.lastDownloadDate) ?? '');
        console.log(response);
        setRequirementStatus(response.status);
    }

    const downloadFile = async () => {
        await api.requirements.downloadFile(requirementId).then(e => {
            require("downloadjs")(e.file, fileModel!.fileName, e.file.type); });
    }

    const handleNumberChange = async (value: string) => {
        if (requirement) {
            await api.requirements.setNumber(requirementId, value);
            await updatePage();
        }
    }

    const handleOrganizationChange = async (organizationId: number) => {
        if (requirement) {
            await api.requirements.setOrganization(requirementId, organizationId);
            await updatePage();
        }
    }

    const handleAuditorChange = async (contractorId: number) => {
        if (requirement) {
            await api.requirements.setAuditor(requirementId, contractorId);
            await updatePage();
        }
        else {
            const contractor = await api.contractors.getById(contractorId)
            if (contractor) {
                setAuditor({ id: contractor!.id, title: contractor!.name });
                setTin(contractor!.tin);
            }
        }
    }

    const handlePeriodChange = async (period: string) => {
        if (requirement) {
            await api.requirements.setPeriod(requirementId, period);
            await updatePage();
        }
    }

    const handleRequirementDateChange = async (requirementDate: string) => {
        if (requirement) {
            await api.requirements.setRequirementDate(requirementId, requirementDate);
            await updatePage();
        }
    }

    const handleDateReceivedChange = async (dateReceived: string) => {
        if (requirement) {
            await api.requirements.setDateReceived(requirementId, dateReceived);
            await updatePage();
        }
    }

    const handleDateSubmittingChange = async (dateSubmitting: string) => {
        if (requirement) {
            await api.requirements.setDateSubmitting(requirementId, dateSubmitting);
            await updatePage();
        }
    }

    const handleTinChange = async (tin: string) => {
        if (requirement) {
            await api.requirements.setTin(requirementId, tin);
            await updatePage();
        }
    }

    const handleResponsibleChange = async (userId: number) => {
        if (requirement) {
            await api.requirements.setResponsible(requirementId, userId);
            await updatePage();
        }
    }

    const handleTaxOfficeNumberChange = async (taxOfficeNumber: string) => {
        if (requirement) {
            await api.requirements.setTaxOfficeNumber(requirementId, taxOfficeNumber);
            await updatePage();
        }
    }

    const handleFileChange = async (file: File) => {
        setFile(file);

        if (requirement) {
            await api.requirements.setFile(requirementId, file);
            await updatePage();
        }
    }

    const createRequirement = async (organizationId: number, file: File, number: string,
        requirementDate: string, period: string, tin: string, taxOfficeNumber: string, dateReceived: string, 
        dateSubmitting: string, auditorId: number, responsibleId?: number) => {
        if (loading)
            return;

        setLoading(true);
        try {
            const { requirementId } = await api.requirements.create(
                organizationId, file, number, requirementDate,
                period, tin, taxOfficeNumber, dateReceived, dateSubmitting, 
                auditorId, responsibleId);
            
            history.push(getRequirementRoute(requirementId));
            await updatePage(requirementId);
        } finally {
            setLoading(false);
        }
    }

    const requirementActionDialogClose = async () => {
        await updatePage();
        setRequirementActionDialogOpen(false);
    }

    const editable = requirement && hasAccess || action != RequirementAction.ResumeWork;
    const editableForAuthor = requirement && hasAccessAuthor || action != RequirementAction.ResumeWork;

    return (
        <div className='requirement'>
            <div className='common_flex-component'>
                <RequirementActionDialog
                    requirement={requirement}
                    action={action}
                    open={requirementActionDialogOpen}
                    onClose={requirementActionDialogClose}
                    updatePage={updatePage}
                ></RequirementActionDialog>
                <div className="common_header action-buttons">
                    <Button
                        color='inherit'
                        startIcon={<ArrowBack />}
                        onClick={() => history.push(Routes.requirements)}
                    >
                        Назад
                    </Button>
                    {requirement
                        ? <h3>Требование #{number} к "{organization! ? organization!.name : ''}"</h3>
                        : <h3>Новое требование</h3>
                    }
                </div>
                <div className="common_header">
                    <div className='action-buttons'>
                    {(hasAccessAuthor || !requirement) && action != RequirementAction.ResumeWork ?
                                <Button
                                    startIcon={<LoopIcon />}
                                    variant="contained"
                                    component="label"
                                >
                                    { !file && !requirement ? 'Загрузить' : 'Заменить' }
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileChange(e.target.files![0])}
                                        hidden
                                    />
                                </Button> : <div></div>
                            }
                        <Button 
                        disabled={!requirement}
                        onClick={() => setRequirementActionDialogOpen(true)}
                        color='secondary'>{action}</Button>
                    </div>
                    <div className='common_header__buttons action-buttons'>
                        {requirement && action != RequirementAction.ResumeWork ?
                        <DownloadDocumentsDialog
                            requirementId={requirementId}
                            fullPackage={true}
                        /> : <></>
                        }
                        {!requirement ?
                        <Button
                            disabled={!requirement && (!organization || !file || !number || !requirementDate) || loading}
                            onClick={() => !requirement ? createRequirement(organization!.id, file!, number!, requirementDate!,
                                period!, tin!, taxOfficeNumber!, dateReceived!, dateSubmitting!, 
                                auditorId!, responsibleId!) : history.push(Routes.requirements)}
                            color={!loading ? 'primary' : 'secondary'}
                            variant="contained" >
                            {!loading ? 'Сохранить' : <CircularProgress size={25} />}
                        </Button>
                        : <></>}
                    </div>
                </div>

                <div className="table_flex">
                    <div>
                        <div className="common_select_container">
                            <p>Файл: <span className='red-label'>*</span></p>
                            <div className='content-block file-name'>
                                <a className="fileNameLabel" onClick={() => !file ? downloadFile() : () => { }}>
                                    <FilePresentIcon />
                                    <span className='reqiurements_downloadButton'>{!fileModel ?
                                    (!file ? '' : (file.name.length <= 35 ? file.name : `${file.name.substring(0, 20)}...`))
                                    : ((fileModel.fileName.length <= 35 ? fileModel.fileName : `${fileModel.fileName.substring(0, 20)}...`))}</span>
                                </a>
                            </div>
                        </div>
                        <div className="common_select_container">
                            <p>Номер требования: <span className='red-label'>*</span></p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                    onBlur={(e) => handleNumberChange(e.target.value)}
                                    className='requirements_select-style'
                                    disabled={!editable}
                                ></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Дата требования: <span className='red-label'>*</span></p>
                            <FormControl variant="outlined">
                                <TextField type="date" value={requirementDate}
                                    onChange={(e) => setRequirementDate(e.target.value)}
                                    onBlur={(e) => { handleRequirementDateChange(e.target.value) }}
                                    disabled={!editableForAuthor}
                                    className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container common_select-type">
                            <p>Организация: <span className='red-label'>*</span></p>
                            <FormControl variant="outlined">
                                <Select
                                    value={organization ? organization.id : ''}
                                    defaultValue=''
                                    disabled={!editableForAuthor}
                                    className='requirements_select-style'
                                    onChange={(e) => setOrganization(organizations.filter(o => o.id == Number(e.target.value))[0])}
                                    onBlur={(e) => handleOrganizationChange(Number(e.target.value))}
                                >
                                    {organizations.map((o) => (
                                        <MenuItem
                                            className='common_select-text'
                                            value={o.id.toString()}
                                            key={o.id}
                                        >
                                            {o.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="common_select_container common_select-type">
                            <p>Проверяемый:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <AutocompleteEntityPicker
                                    disabled={!editable}
                                    className='items-search requirements_select-style'
                                    entitySource={findAuditor}
                                    onPicked={onAuditorChange}
                                    value={auditor} />
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>ИНН КА:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField value={tin}
                                    disabled={auditor && auditor!.id != 0 || !editable}
                                    onChange={(e) => setTin(e.target.value)}
                                    onBlur={(e) => handleTinChange(e.target.value)} className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Период:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField value={period}
                                    disabled={!editable}
                                    onChange={(e) => setPeriod(e.target.value)}
                                    onBlur={(e) => handlePeriodChange(e.target.value)} className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>ИФНС:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField value={taxOfficeNumber}
                                    disabled={!editable}
                                    onChange={(e) => setTaxOfficeNumber(e.target.value)}
                                    onBlur={(e) => handleTaxOfficeNumberChange(e.target.value)} className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                    </div>
                    <div className="requirement_properties">
                        {requirement ?
                            <div>
                                <div className="common_select_container info">
                                    <p>Статус:</p>
                                    <div className="">{requirementStatusCaption(requirementStatus)}</div>
                                </div>
                                <div className="common_select_container downloaded info">
                                    <p>Выгружен:</p>

                                    <div className="underline-block">
                                        <div className="info-label underline-label">{downloadedUser ? getShortNameForUser(downloadedUser!) : 'Не выгружалось'}</div> 
                                        <div className="info-label underline-label">{lastDownloadDate}</div>
                                    </div>
                                </div>
                            </div> : <div></div>
                        }
                        <div className="common_select_container">
                            <p>Дата получения:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField type="date" value={dateSubmitting}
                                    onChange={(e) => setDateSubmitting(e.target.value)}
                                    onBlur={(e) => { handleDateSubmittingChange(e.target.value) }}
                                    disabled={!editable}
                                    className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Дата сдачи план:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <TextField type="date" value={dateReceived}
                                    onChange={(e) => { setDateReceived(e.target.value) }}
                                    onBlur={(e) => { handleDateReceivedChange(e.target.value) }}
                                    disabled={!editable}
                                    className='requirements_select-style'></TextField>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Ответственный:</p>
                            <FormControl variant="outlined" className='requirements_select-style'>
                                <AutocompleteEntityPicker
                                    className='items-search requirements_select-style'
                                    entitySource={findResponsible}
                                    onPicked={onResponsibleChange}
                                    disabled={!editableForAuthor}
                                    value={responsible} />
                            </FormControl>
                        </div>
                    </div>
                </div>

                {requirement && action != RequirementAction.ResumeWork ?
                <ParagraphsTable
                    requirementId={requirementId}
                    paragraphs={requirementParagraphs}
                    hasAccess={hasAccess}
                    setRequirementParagraphs={setRequirementParagraphs}></ParagraphsTable>
                : <></>}
            </div>
        </div>
    )
}

export default Requirement;