import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from "../../../api";
import { MakeArrayFilters } from "../../../helpers/utils";
import { Sorting } from "../../../api/types/getFunctions";
import Filter from "../../../common/abstractions/filter";
import { listService } from "../../../common/services/listService";
import EntityListWithFilters from "../../../common/pages/blanks/List";
import { createReferenceColumn, createTextColumn } from "../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../common/controller/entityListController/entityListController";
import { FiltersControllerProps } from "../../../common/controller/filters/filtersController";
import useGoToPageProvider from "../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid";
import Link from "../../../common/components/Link";
import { createDateFilter } from "../../../common/view/listFiltersView/components/filters/components/dateFilter";
import { createNumberFilter } from "../../../common/view/listFiltersView/components/filters/components/numberFilter";
import { Button } from "@mui/material";
import './style.sass';
import '../../../styling/common/index.sass';
import { formatDate } from "../../../helpers/formatters";
import { requirementStatusCaption } from "../../../enums/requirementStatus";
import { AttachedFile, ExternalDocument } from "../../../types/entities/externalDocument";
import { ExternalDocumentsOutput } from "../../../api/types/externalDocumentsOutput";
import ExternalDocumentEntity from "../../domain/externalDocuments/externalDocumentEntity";
import ExternalDocumentFilter from "../../domain/externalDocuments/externalDocumentFilter";
import DocumentScrollView from "./DocumentScrollView";
import { getUploadDocumentRoute } from "../../../router/routes";
import { useHistory, useParams } from "react-router-dom";

interface IPickDocumentFromExternalSourceProps<TDocument extends ExternalDocument> {
    name: string;
    source: (skip?: number, take?: number, from?: Date, to?: Date) => Promise<ExternalDocumentsOutput<TDocument>>;
}

const PickDocumentFromExternalSource = <TDocument extends ExternalDocument>(props: IPickDocumentFromExternalSourceProps<TDocument>) => {
    const getMany = useCallback(async (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], filter?: MakeArrayFilters<Filter>, search?: string) =>
        props.source(skip, take),
        []);

    const history = useHistory();

    const [attachedFile, setAttachedFile] = useState<AttachedFile>();

    const [document, setDocument] = useState<TDocument>();
    const { documentId } = useParams<{ documentId?: string }>();

    const accept = (document: TDocument, attachedFile: AttachedFile) => {
        history.push(getUploadDocumentRoute(documentId ? Number(documentId) : undefined), 
        { sbisDocument: { id: document.id }, attachedFile });
    }

    const onClose = () => {
        history.push(getUploadDocumentRoute(documentId ? Number(documentId) : undefined));
    }

    return (
        <div className="externalDocumentsList">
            <EntityListWithFilters<ExternalDocumentEntity, ExternalDocumentFilter, ExternalDocumentListProps<TDocument>, ExternalDocumentFilterProps>
                ListHeaderView={<div><Link title={"Документы из " + props.name} /></div>}
                listControllerProps={{
                    columns: [
                        { width: 200, field: 'number', headerName: 'Номер' },
                        { width: 150, field: 'date', headerName: 'Дата', renderCell: (e) => <div>{e.value}</div> },
                        { width: 150, field: 'contractor', headerName: 'Контрагент' }
                    ],
                    loadEntities: getMany,
                    setDocument
                }}
                storeName='externalDocumentsStore'
                filterAttributes={filterAttributes}
                useListController={useExternalDocumentsListController}
            />

            <DocumentScrollView document={document} setAttachedFile={setAttachedFile} 
            attachedFile={attachedFile!}></DocumentScrollView>
            
            <div className="externalDocumentsFooter">
                <Button color="secondary" onClick={() => onClose()}>Отмена</Button>
                <Button disabled={!attachedFile} variant="contained" onClick={() => accept(document!, attachedFile!)}>OK</Button>
            </div>
        </div>
    );
};

const useExternalDocumentsListController = <TDocument extends ExternalDocument>(props: ExternalDocumentListProps<TDocument>): ListControllerReturnType<ExternalDocumentEntity> => {
    const listProps = useListController(props);
    const { setDocument } = props;

    const onRowClick = useCallback((props: GridRowParams<TDocument>) => {
        setDocument(props.row);
    }, []);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<ExternalDocumentEntity>;
}

type ExternalDocumentListProps<TDocument extends ExternalDocument> = ListControllerProps<ExternalDocumentEntity, ExternalDocumentFilter> & {
    setDocument: (document: TDocument) => void;
}

type ExternalDocumentFilterProps = FiltersControllerProps<ExternalDocumentEntity, ExternalDocumentFilter> & {
    
}

const filterAttributes: ExternalDocumentFilter = {
    number: createNumberFilter("Номер"),
    date: createDateFilter("Дата"),
};

const ExternalDocumentsList = observer(PickDocumentFromExternalSource);

export default ExternalDocumentsList;
