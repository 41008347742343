import { RootApiType } from './../../api/rootApi';
export default interface Filter {
    [key: string]: FilterAttributes<any>
}

export interface FilterAttributes<TValue> extends ReferenceFilterAttributes, ChipsFilterAttributes<TValue>, 
  SelectFilterAttributes<TValue> { 
    type: FilterType;
    label?: string;
    values: Array<TValue>;
    needShowFilter?: boolean;
};

interface ReferenceFilterAttributes {
  route?: keyof RootApiType;
  captionField?: string;
  fieldForOrder?: string;
  getCaption?: (value: any) => string,
}

interface SelectFilterAttributes<TValue> {
  options?: TValue[];
  getLabel?: (value: TValue) => string,
}

interface ChipsFilterAttributes<TValue> {
  valuePresent?: (value: TValue) => string;
  isStaticFilter?: boolean;
  needShowChips?: boolean;
}

export enum FilterType {
  String = 'string',
  Number = 'number',  
  Boolean = "Boolean",
  Date = "Date",
  Currency = "Currency",
  Reference = "Reference",
  Select = 'Select',
  DateRange = "DateRange"
}
