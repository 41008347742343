import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { createTextColumn } from "../../../view/entityListView/columnsFactory";
import EntityListWithFilters from "../../blanks/List";
import Link from "../../../components/Link";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import useGoToPageProvider from "../../../../router/goToPageProvider";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { Button } from "@mui/material";
import Filter from "../../../abstractions/filter";
import { FinancialResponsibilityCenter } from "../../../../types/entities/financialResponsibilityCenter";

const FrcListView: React.FC = () => {
    const { goToShowFrc } = useGoToPageProvider();

    return (
        <EntityListWithFilters<FinancialResponsibilityCenter, Filter>
            ListHeaderView={<Link title={"ЦФО"} />}
            listControllerProps={{
                columns: [
                    createTextColumn<FinancialResponsibilityCenter>('name', 'Имя', true),
                ],
                loadEntities: 'financialResponsibilityCenters',
            }}
            storeName="frcsStore"
            useListController={useFrcListController}
            ListActions={
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => goToShowFrc()}
                >
                    Добавить
                </Button>
            }
        />
    );
}

const useFrcListController = (props: ListControllerProps<FinancialResponsibilityCenter, Filter>): ListControllerReturnType<FinancialResponsibilityCenter> => {
    const listProps = useListController(props); 
    const { goToShowFrc } = useGoToPageProvider();

    const onRowClick = useCallback((props: GridRowParams<FinancialResponsibilityCenter>) => {
        const { id } = props.row;
        goToShowFrc(id);
    }, [goToShowFrc]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<FinancialResponsibilityCenter>;
}

const FrcList = observer(FrcListView);

export default FrcList;