import { Button, CircularProgress } from "@mui/material";
import api from "../../../api";
import { Link as LinkIcon } from "@mui/icons-material";
import { FC, useState } from "react";
import { SucceededResult } from "../../../api/types";
import useNotificationStore from "../../../stores/notificationStore";
import './style.sass';

const BindDocumentToDocument: FC<BindDocumentToDocumentProps> = ({ documentId, text, selectedDocs, afterBindHandler }) => {
    const [isLoading, setIsLoading] = useState(false);
    const notificationStore = useNotificationStore();

    return (
        <Button
            disabled={selectedDocs.length !== 1}
            onClick={async () => {
                setIsLoading(true);
                const result = await api.documents.bindToStack(documentId, selectedDocs[0]);
                setIsLoading(false);
                if (result.succeeded) {
                    notificationStore.setIsOpenAndText(true, 'Документы успешно связаны.');
                }
                else
                    notificationStore.setIsOpenAndText(true, 'Не удалось связать документы.');
                if (afterBindHandler)
                    afterBindHandler(result);
            }}
            variant="contained"
            className="bindButton"
            endIcon={isLoading ? <CircularProgress size={25} className="circularProgress" /> : <LinkIcon />}
        >
            {text}
        </Button>
    );
}

interface BindDocumentToDocumentProps {
    text: string;
    documentId: number;
    selectedDocs: number[];
    afterBindHandler?: (res: SucceededResult) => void;
}

export default BindDocumentToDocument;
