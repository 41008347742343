import React, { useContext, useEffect, useState } from 'react';
import { Button, IconButton } from "@mui/material";
import { ArrowBack, Add, Remove } from "@mui/icons-material";
import './style.sass';
import '../../../styling/common/index.sass';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { Document, Page, pdfjs } from 'react-pdf';
import { MainStore } from '../../../stores/mainStore';
import { useHistory } from 'react-router-dom';
import { getUploadDocumentRoute } from '../../../router/routes';
import { setupPdfjs } from '../../../helpers/setupPdfjs';

const Recognition: React.FC = () => {
    const { uploadedFileStore } = useContext(MainStore);
    const [page, setPage] = useState(1);
    const [pagesTotalCount, setPagesTotalCount] = useState(0);
    const [file, setFile] = useState<File>();
    const [width, setWidth] = useState(400);
    const history = useHistory();

    useEffect(() => {
        setupPdfjs(pdfjs);
    })

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setPagesTotalCount(numPages)
    }

    if (file === undefined){
        history.push(getUploadDocumentRoute());
        return <></>;
    }

    return (
        <div>
            <div className="common_header recognition_flex-header">
                <Button
                    color='inherit'
                    startIcon={<ArrowBack />}
                    onClick={() => history.goBack()}
                >
                    Назад
                </Button>
                <p>Распознавание документа №</p>
            </div>
            <div className="recognition_container">
                <div className="recognition_file">
                    <p>Файл: <span>{file.name}</span></p>
                    <div>
                        <p>Скан:</p>
                        <div className="scan_flex">
                            <div className="scan_document">
                                <Document
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    file={file}
                                >
                                    <Page
                                        pageNumber={page}
                                        width={width}
                                    />
                                </Document>
                            </div>
                            <div className="scan_flex-button">
                                <p>Страница: <span>{page} из {pagesTotalCount}</span></p>
                                <IconButton
                                    disabled={page - 1 === 0}
                                    onClick={() => setPage(prev => prev - 1)}
                                >
                                    <ArrowBack color='secondary'/>
                                </IconButton>
                                <IconButton
                                    disabled={page + 1 > pagesTotalCount}
                                    onClick={() => setPage(prev => prev + 1)}
                                >
                                    <ArrowForwardIcon color='secondary'/>
                                </IconButton>
                                <IconButton
                                    disabled={width - 50 <= 200}
                                    onClick={() => setWidth(prev => prev - 50)}
                                >
                                    <Remove color='secondary'/>
                                </IconButton>
                                <IconButton
                                    disabled={width + 50 >= 700}
                                    onClick={() => setWidth(prev => prev + 50)}
                                >
                                    <Add color='secondary'/>
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="recognition_attribute">
                    <div className="recognition_button">
                        <Button color='secondary'>Отменить</Button>
                        <Button variant="contained">Сохранить</Button>
                    </div>
                    <div className="attribute">
                        <p className="attribute_main">Атрибуты:</p>
                        <div className="attribute_container">
                            <div className="attribute_input">
                                <p>Номер:</p>
                                <TextField className='attribute_textfield' value='002178'/>
                            </div>
                            <div className="attribute_input">
                                <p>Дата:</p>
                                <TextField className='attribute_textfield' value='11.12.2021'/>
                            </div>
                            <div className="attribute_input">
                                <p>Поставщик:</p>
                                <TextField className='attribute_textfield' value='ООО'/>
                            </div>
                            <div className="attribute_input">
                                <p>Получатель:</p>
                                <TextField className='attribute_textfield' value='ИП'/>
                            </div>
                            <Button startIcon={<AddIcon />} variant="outlined">
                                Добавить атрибут
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recognition;