import React from 'react';
import './style.sass';
interface ILinkProps {
    title: string
}
const Link = (props: ILinkProps ) => {
    return (
        <div className='link'>
            <p>АСТ документы •</p>
            <p>Первичная документация •</p>
            <span>{props.title}</span>
        </div>
    );
};

export default Link;