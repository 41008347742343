import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import Filter from "../../../common/abstractions/filter";
import { Document } from "../../../types/entities/document";

export default class DocumentStackStore extends Store<Document, Filter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}