import { createTheme } from '@mui/material';
import ClarikaOfficeGeo2 from "../fonts/ClarikaOfficeGeo-Rg.woff2";
import ClarikaOfficeGeo from '../fonts/ClarikaOfficeGeo-Rg.woff';
import ClarikaOfficeGeoSVG from '../fonts/ClarikaOfficeGeo-Rg.svg';
import { ruRU } from '@mui/material/locale';

type FontFaceFontDisplayProperty = "auto" | "block" | "fallback" | "optional" | "swap";
let swapType: FontFaceFontDisplayProperty = "swap";

const regular = {
  fontFamily: 'Clarika Office Geometric',
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: swapType,
  src: `
    url(${ClarikaOfficeGeo2}) format('woff2'),
    url(${ClarikaOfficeGeo}) format('woff'),
    url(${ClarikaOfficeGeoSVG}) format('svg');
    `,
};

const customTheme = createTheme({
    typography: {
        fontFamily: 'Clarika Office Geometric'
    },
    palette: {
        primary: {
            main : '#FFA839',
            dark : '#ec8400'
        },
        secondary: {
            main: '#FFA839',
        },
        
    },
    
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '@font-face': [regular],
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body2: {
                    fontSize: '14px',
                },
            },
        },
        
        MuiAppBar:{
            styleOverrides: {
                root: {
                    boxShadow:'0px 4px 8px rgba(0, 0, 0, 0.08);',
                },
                colorSecondary: {
                    backgroundColor: '#fff'
                },
            },
        },

        MuiToolbar: {
            styleOverrides: 
            {
                dense: {
                    minHeight: '92px'
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '16px',
                    paddingLeft: '24px',
                },
            },
        },

        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 8,
                    boxShadow: '0px 2px 6px rgba(34, 43, 85, 0.06), 0px 10px 16px rgba(52, 53, 70, 0.06)'
                },
            },
        },

        MuiList: {
            styleOverrides: {
                padding: {
                    padding: '0 !important'
                },
            },
        },

        MuiListItem: {
            styleOverrides: {
                button: {
                    '&:hover':{
                        backgroundColor: '#ffebd2',
                    },
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: 'none',
                    border: '.5px solid #F7F7F8'
                },
            },
        },
        
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#FFA839',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    fontSize: '14px',
                    "&:hover": {
                        boxShadow: 'none',
                    }
                },
                containedPrimary: {
                    color: '#FFFFFF',
                    background :'#FFA839',
                },
                textSecondary: {
                    color: '#323237',
                    background :'#F7F7F8',
                },
                textInherit: {
                    color: 'grey',
                    fontcolor: 'white',
                },
                outlinedPrimary: {
                    color: '#FFA839',
                    border: "solid 1px #FFA839",  
                }
            },
        },

        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: '14px',
                    padding: '9px 16px 11px 16px',
                    lineHeight: '100%',
                    border: '.5px solid #F7F7F8',
                    overflow: "hidden",
                    maxWidth: "15em",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                },
            },
        },

        MuiFormLabel: {
            styleOverrides: {
                root:{
                    color: '#646469',

                    '&.Mui-focused': {
                        color: '#646469 !important',
                    },
                },
            },
        },



        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',

                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },

                    '&.Mui-focused' : {
                        background: 'none'
                    }
                },
                underline: {
                    '&:hover:before': {
                        borderBottom: '2px solid #FFA839'
                    },
                    '&:before' : {
                        borderBottom: '2px solid #DCDCE1'
                    },
                    '&:after' : {
                        borderBottom: '2px solid #FFA839'
                    }
                },
            },
        },

        MuiInputLabel:{
            styleOverrides: {
                root: {
                    color: '#aaa',
                },
                filled: {
                    display: 'none',
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                outlined: {
                    root: {
                        padding: "70.5px 14px",
                    }
                },
                filled: {
                    '&&' : {
                        paddingTop : 7,
                        paddingLeft : 0,
                        paddingRight: 24,
                        background: 'none'
                    }
                },
                icon: {
                    top: 'calc(50% - 12px)'
                },
                iconFilled: {
                    right: -5
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFA839',
    
                    '&.Mui-checked': {
                        color: '#FFA839 !important'
                    }
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    "div": {
                        padding: "4px 12px",
                    },
                    "input": {
                        padding: "8px 0px",
                    }
                }
            }
        },

        MuiIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFA839'
                },
                colorSecondary: {
                    color: '#999999'
                },
            }
        },
        
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFA839'
                },
                colorSecondary: {
                    color: '#999999'
                },
            }
        },

        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F7F7F8'
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFA839',
                    color: 'white',
                    "svg": {
                        color: "white !important"
                    }
                }
            }
        }
    },
}, ruRU);


export default customTheme