import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import api from "../../../api";
import { Sorting } from "../../../api/types/getFunctions";
import Filter from "../../../common/abstractions/filter";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../common/controller/entityListController/entityListController";
import { createReferenceColumn, createTextColumn } from "../../../common/view/entityListView/columnsFactory";
import { MakeArrayFilters } from "../../../helpers/utils";
import useGoToPageProvider from "../../../router/goToPageProvider";
import { useParams } from "react-router-dom";
import { createNumberFilter } from "../../../common/view/listFiltersView/components/filters/components/numberFilter";import ListService from "../../../common/abstractions/listService";
import ParagraphDocumentEntity from "../../domain/requirements/paragraphDocument/paragraphDocumentEntity";
import ParagraphDocumentFilter from "../../domain/requirements/paragraphDocument/paragraphDocumentFilter";
import { createRangeDateFilter } from "../../../common/view/listFiltersView/components/filters/components/dateRangeFilter";
import useQuery from "../../../router/useQuery";
import EntityListWithFilters from "../../../common/pages/blanks/List";
import { IEntityStores } from "../../../stores/entitiesStore";
import './style.sass';

const ParagraphDocumentsListView: React.FC = () => {
    const {
        selectedDocs,
        bindSelectedFields,
        refresh,
        setRefresh,
        loadEntities,
        overrideService,
        storeName
    } = useParagraphDocumentsListController();

    const DataGridHeader = ({ selectedDocs }: { selectedDocs: number[] }) => {
        const { initialParagraphId } = useParagraphDocumentsEndpointData();

        return (
            <div className="table_tool_bar">
                <Button
                    variant="contained"
                    disabled={selectedDocs.length === 0}
                    onClick={() => {
                        api.requirementParagraphs.removeDocuments(Number(initialParagraphId), selectedDocs);
                        setRefresh(!refresh);
                    }}
                >
                    Открепить выделенные
                </Button>
            </div>
        );
    }

    return (
        <EntityListWithFilters<ParagraphDocumentEntity, ParagraphDocumentFilter, ParagraphDocumentsListViewProps>
            ListHeaderView={<ListHeaderView />}
            DataGridHeader={<DataGridHeader selectedDocs={selectedDocs} />}
            listControllerProps={{
                columns: [
                    { field: 'documentDate', headerName: 'Дата документа', width: 150, renderCell: (e) => <div>{e.value ? new Date(e.value).toDateString() : ''}</div> },
                    createTextColumn('documentNumber', '№'),
                    createReferenceColumn(createTextColumn<ParagraphDocumentEntity>('name', 'Тип'), 'typeId', 'types'),
                    createReferenceColumn(createTextColumn<ParagraphDocumentEntity>('name', 'Контрагент'), 'contractorId', 'contractors')
                ],
                bindSelectedFields: bindSelectedFields,
                loadEntities: loadEntities,
                overrideService: overrideService
            }}
            storeName={storeName}
            filterAttributes={filterAttributes}
            useListController={useCustomListController}
        />
    );
};

const ListHeaderView = () => {
    const { goBack } = useGoToPageProvider();
    const { initialRequirementId, initialParagraphId } = useParagraphDocumentsEndpointData();

    return (
        <div className="find_duplicates-header">
            <Button
                color='inherit'
                startIcon={<ArrowBack />}
                onClick={() => goBack()}
            >
                Назад
            </Button>
            <Typography>Документы пункта № {initialParagraphId} требования № {initialRequirementId}</Typography>
        </div>
    );
}

const useParagraphDocumentsListController = () => {
    const { initialParagraphId } = useParagraphDocumentsEndpointData();

    const [refresh, setRefresh] = useState<boolean>(false);

    const [selectedDocs, setSelectedDocs] = useState<number[]>([]);

    const bindSelectedFields = (selectionModel: number[]) => setSelectedDocs(selectionModel);
    
    const overrideService = (serivce: ListService<ParagraphDocumentEntity, ParagraphDocumentFilter>) => ({ ...serivce, getLoadingDeps: () => [...serivce.getLoadingDeps(), refresh] });

    const storeName: keyof IEntityStores = "paragraphDocumentsStore";

    const loadEntities = async (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], filter?: MakeArrayFilters<Filter>, search?: string) =>
        await api.documents.getMany(skip, take, sortings, ids, { paragraphId: [initialParagraphId], ...filter }, search);

    return {
        selectedDocs,
        bindSelectedFields,
        refresh,
        setRefresh,
        loadEntities,
        overrideService,
        storeName
    }
}

const useParagraphDocumentsEndpointData = () => {
    const { requirementId: initialRequirementId } = useParams<{requirementId: string}>();
    const query = useQuery();
    const initialParagraphId = query.get('paragraphId');

    return {
        initialRequirementId,
        initialParagraphId
    }
}

const useCustomListController = (props: ParagraphDocumentsListViewProps): ListControllerReturnType<ParagraphDocumentEntity> => {
    const rest = useListController(props);
    const { bindSelectedFields } = props; 
    const [selectionModel, setSelectionModel] = useState<number[]>([]);

    useEffect(() => {
        bindSelectedFields(selectionModel);
    }, [selectionModel]);

    return {
        ...rest,
        onSelectionModelChange: (selectionModel: number[]) => setSelectionModel(selectionModel),
        checkboxSelection: true,
        disableMultipleSelection: false
    } as ListControllerReturnType<ParagraphDocumentEntity>;
}

interface ParagraphDocumentsListViewProps extends ListControllerProps<ParagraphDocumentEntity, ParagraphDocumentFilter> {
    bindSelectedFields: (selectionModel: number[]) => void;
}

const filterAttributes: ParagraphDocumentFilter = {
    documentNumber: createNumberFilter('Номер документа'),
    documentDate: createRangeDateFilter("Дата документа")
};

const ParagraphDocumentsList = observer(ParagraphDocumentsListView);

export default ParagraphDocumentsList;