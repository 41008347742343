import { format } from 'date-fns';
import { Checkbox, Input, TextField } from "@mui/material";
import { DataType, DataValue } from "../../../types/dataType";
import ReferenceEditor from './components/reference';
import EnumEditor from './components/enum';
import ManyReferenceEditor from './components/manyReference';
import { observer } from 'mobx-react-lite';

interface IAttributeEditorProps {
    dataValue: DataValue;
    onChange: (value: unknown) => void;
    onBlur: (value: unknown) => void;
    errorMessage?: string;
    disabled?: boolean;
}


const ValueEditor: React.FC<IAttributeEditorProps> = (props) => {
    const { dataValue: { dataType, value, captionField, getCaption, route, fieldForOrder, dictionary }, onChange, onBlur, errorMessage, disabled } = props;

    switch (dataType) {
        case DataType.String:
            return <StringEditor disabled={disabled} errorMessage={errorMessage} onBlur={onBlur} onChange={onChange} value={value as string} />;
        case DataType.DateTime:
            return <DateTimeEditor disabled={disabled} errorMessage={errorMessage} onBlur={onBlur} onChange={onChange} value={typeof value == 'string' ? new Date(value) : value as Date} />;
        case DataType.Decimal:
            return <NumberEditor disabled={disabled} errorMessage={errorMessage} onBlur={onBlur} onChange={onChange} value={value as number} />;
        case DataType.Integer:
            return <NumberEditor disabled={disabled} errorMessage={errorMessage} onBlur={onBlur} onChange={onChange} value={value as number} />;
        case DataType.Boolean:
            return <BooleanEditor disabled={disabled} errorMessage={errorMessage} onBlur={onBlur} onChange={onChange} value={value as boolean} />;
        case DataType.Reference:
            return <ReferenceEditor onChange={onChange} value={value as number} captionField={captionField} getCaption={getCaption} route={route} fieldForOrder={fieldForOrder} errorMessage={errorMessage} disabled={disabled} />;
        case DataType.Enum:
            return <EnumEditor onChange={onChange} values={value as any } dictionary={dictionary!} disabled={disabled} errorMessage={errorMessage} />;
        case DataType.ManyReference:
            return <ManyReferenceEditor onChange={onChange} value={value as number[]} captionField={captionField} getCaption={getCaption} route={route} fieldForOrder={fieldForOrder} errorMessage={errorMessage} disabled={disabled} />;
        default: return <></>
    }
}

interface IEditorProps<TValue> {
    value?: TValue;
    onChange: (value: TValue) => void;
    onBlur: (value: TValue) => void;
    errorMessage?: string;
    disabled?: boolean;
}

const StringEditor: React.FC<IEditorProps<string>> = observer(({ value, disabled, onChange, onBlur, errorMessage }) => {
    return <TextField disabled={disabled} value={value}
        onBlur={(e) => onBlur(e.target.value)} onChange={(e) => onChange(e.target.value)}
        helperText={errorMessage} error={errorMessage != null} />;
})

const NumberEditor: React.FC<IEditorProps<number>> = observer(({ value, disabled, onChange, onBlur, errorMessage }) => {
    return <TextField disabled={disabled} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        value={value} onBlur={(e) => onBlur(Number(e.target.value))}
        onChange={(e) => onChange(Number(e.target.value))}
        helperText={errorMessage} error={errorMessage != null} />;
})

const BooleanEditor: React.FC<IEditorProps<boolean>> = observer(({ value, disabled, onChange, onBlur }) => {
    return <Checkbox disabled={disabled} checked={value} onChange={e => {
        onChange(e.target.checked);
        onBlur(e.target.checked);
    }} />;
})

const DateTimeEditor: React.FC<IEditorProps<Date>> = observer(({ value, onBlur, onChange, disabled }) => {
    return <Input disabled={disabled} type="date" value={value == null ? '' : format(value, 'yyyy-MM-dd')}
        onChange={(e) => {
            onChange(new Date(e.target.value));
            onBlur(new Date(e.target.value));
        }} />;
})

ValueEditor.defaultProps = { disabled: false };

export default observer(ValueEditor);