import { makeObservable, observable } from "mobx";
import { IObservableArray } from "mobx/dist/internal";
import { Entity } from "../../types/entities/entity";

export default interface IStore<TEntity extends Entity> {
  get entities(): TEntity[];
  get totalCount(): number;
  get subEntities(): { [key: string]: Entity[] };

  get gridId(): string;

  addEntity(entity: TEntity): void
  addEntities(entities: Array<TEntity>): void;
  updateTotalCount(totalCount: number): void;
  resetEntities(entities: Array<TEntity>): void;
  updateEntity(entity: TEntity): void;
  updateSubEntities(subroute: string, entities: Entity[]): void;

}

export abstract class BaseStore<TEntity extends Entity> implements IStore<TEntity> {
  protected _entities: IObservableArray<TEntity> = observable([]);
  protected _subEntities: { [key: string]: Entity[]; } = {};
  protected _totalCount: number = 0;
  protected _gridId: string;

  constructor(isObservable: boolean = true, gridId: string, initialEntities?: TEntity[]) {
    if (initialEntities)
      this.addEntities(initialEntities);

    this._gridId = gridId;

    if (isObservable)
      makeObservable(this, {...baseStoreProps});
  }

  public get entities(): TEntity[] {
    return this._entities;
  }

  public get totalCount(): number {
    return this._totalCount;
  }

  public get subEntities(): { [key: string]: Entity[] } {
    return this._subEntities;
  }

  public get gridId(): string {
    return this._gridId;
  }

  public addEntity(entity: TEntity): void {
    this._entities.push(entity);
  }

  public addEntities(entities: Array<TEntity>): void {
    entities.forEach(entity => this.addEntity(entity));
  }

  public updateTotalCount(totalCount: number): void {
    this._totalCount = totalCount;
  }

  public resetEntities = (entities: Array<TEntity>): void => {    
    this._entities.replace(entities);
  }

  public updateSubEntities = (subroute: string, entities: Entity[]): void => {
    this._subEntities[subroute] = entities;
  }

  public updateEntity = (entity: TEntity): void => { 
    const index = this._entities.findIndex(e => e.id === entity.id);
    if (index === -1)
      return;
    
    this._entities.splice(index, 1, entity);
  }

  public setGridId (gridId: string): void {
    this._gridId = gridId; 
  }
}

const baseStoreProps = {
  _entities: true,
  addEntities: true,
  addEntity: true,
  _totalCount: true,
  totalCount: true,
  entities: true,
  _gridId: true,
  gridId: true,
  updateTotalCount: true,
  resetEntities: true,
  _subEntities: true,
  subEntities: true,
  updateSubEntities: true,
  updateEntity: true,
  setGridId: true,
};