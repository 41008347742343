import { DataType } from "../types/dataType";

export const presentAttributeValue = (attributeType: DataType, attributeValue?: unknown) => {
    if (attributeValue == null)
        return '';

    switch (attributeType) {
        case DataType.DateTime:
            const date = attributeValue as Date;
            return `${date.getFullYear()}-${datePresent(date.getMonth() + 1)}-${datePresent(date.getDate())}`
        case DataType.Decimal:
            return attributeValue as number;
        case DataType.Integer:
            return attributeValue as number;
        case DataType.String:
            return attributeValue as string;
    }
}

export const getInputTypeFromAttribute = (type: DataType) => {
    switch (type) {
        case DataType.Boolean:
            return "checkbox";
        case DataType.DateTime:
            return "date";
        case DataType.Decimal:
            return "number";
        case DataType.Integer:
            return "number";
        case DataType.String:
            return "text";
    }
}

export const parseToAttributeValues = (attributeType: DataType, attributeValue: string) => {
    switch (attributeType) {
        case DataType.DateTime:
            return new Date(attributeValue);
        case DataType.Decimal:
            return parseFloat(attributeValue)
        case DataType.Integer:
            return parseInt(attributeValue);
        case DataType.String:
            return attributeValue as string;
        default:
            return attributeValue;
    }
}

const datePresent = (dayMonth: number) => {
    return dayMonth >= 10 ? dayMonth : `0${dayMonth}`
}