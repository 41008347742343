import { IObservableArray } from 'mobx/dist/internal';
import { makeObservable, observable } from "mobx";
import { Sorting, SortingOrder } from "../../api/types/getFunctions";
import { Entity } from "../../types/entities/entity";
import Filter from "../abstractions/filter";
import { BaseStore } from "./BaseStore";
import { capitalizeFirstLetter } from '../controller/entityListController/hooks/common';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

export default abstract class Store<TEntity extends Entity, TFilter extends Filter> extends BaseStore<TEntity> {
  protected _entities: IObservableArray<TEntity> = observable([]);
  private _currentEntity?: TEntity;
  private _list: ListStore<TFilter> = {} as any;
  protected _subEntities: { [key: string]: Entity[]; } = {};

  /**
   * `gridId` for saving the grid state(like table column layout, size, etc)
   */
  constructor(gridId: string) { 
    super(true, gridId);

    this._list = {
      search: undefined,
      filters: { } as TFilter, 
      totalCount: 0,
      showFilters: false,
      page: 0,
      pageSize: 10,
      sortings: []
    };

    const gridsSettingsString = localStorage.getItem("gridsSettings") as string | undefined;
    const gridsSettings = gridsSettingsString ? JSON.parse(gridsSettingsString) as {[key: string]: GridInitialStatePro | undefined} : undefined;
    const gridSettings = gridsSettings && gridsSettings[gridId];
    const sortModel = gridSettings && gridSettings.sorting?.sortModel;

    if (gridSettings && sortModel && sortModel.length > 0)
      this.updateSortings(sortModel.map(s => ({ field: capitalizeFirstLetter(s.field), order: s.sort === 'asc' ? SortingOrder.Asc : SortingOrder.Desc })));

    const pagination = gridSettings && gridSettings.pagination;
    if (pagination) {
      this.updatePage(pagination.page);
      this.updatePageSize(pagination.pageSize);
    }

    makeObservable(this, {...storeProps});
  }

  public get currentEntity(): TEntity | undefined {
    return this._currentEntity;
  }

  public get filters(): TFilter {
    return this._list.filters;
  }

  public get showFilters(): boolean {
    return this._list.showFilters;
  }

  public get page(): number {
    return this._list.page;
  }

  public get pageSize(): number {
    return this._list.pageSize;
  }

  public get sortings(): Sorting[] {
    return this._list.sortings;
  }

  public get search (): string | undefined {
    return this._list.search;
  }

  public updatePage = (page: number | undefined): void => {
    this._list.page = page || 0;
  }

  public updatePageSize = (pageSize: number | undefined): void => {
    this._list.pageSize = pageSize || 10;
  }

  public updateSortings = (sortings: Sorting[] | undefined): void => {
    this._list.sortings = sortings || [];
  }

  public updateShowFilters = (showFilters: boolean): void => {
    this._list.showFilters = showFilters;
  }

  public updateFilter = (key: keyof TFilter, value: any, customUpdate?: (filter: Filter, key: keyof TFilter, value: any) => void): void => {
    const filter = this._list.filters[key];
    if (customUpdate) {
      customUpdate(this._list.filters, key, value);
      return;
    }

    if (filter.values.every(v => v !== value))
      filter.values.push(value);
  }

  public resetFilter = (key: keyof TFilter): void => {
    this._list.filters[key].values = [];
  }

  public removeFilterValue = (key: keyof TFilter, index: number): void => {
    const attributes = this._list.filters[key];
      attributes.values = attributes.values.filter((_, i) => i !== index);
  }

  public updateSearch = (search: string | undefined): void => {
    this._list.search = search === "" ? undefined : search;
  }
  
  public updateCurrentEntity = (entity?: TEntity): void => {
    this._currentEntity = entity;
  }

  public updateFilters = (filters: TFilter): void => {
    this._list.filters = filters;
  }
}

export interface ListStore<TFilter extends Filter> {
  filters: TFilter;
  totalCount: number;
  showFilters: boolean;
  page: number;
  pageSize: number;
  sortings: Sorting[];
  search: string | undefined;
}

export const storeProps = {
  _currentEntity: true,
  _list: true,
  filters: true,
  showFilters: true,
  updateShowFilters: true,
  page: true,
  pageSize: true,
  updatePage: true,
  updatePageSize: true,
  sortings: true,
  updateSortings: true,
  updateFilter: true,
  updateCurrentEntity: true,
  resetFilter: true,
  removeFilterValue: true,
  search: true,
  updateSearch: true,
  updateFilters: true,
}