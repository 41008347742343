import { toJS } from "mobx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useGoToPageProvider from "../../../router/goToPageProvider";
import useNotificationStore from "../../../stores/notificationStore";
import { Entity } from "../../../types/entities/entity";
import { Error } from "../../abstractions/error";
import { EntityField } from "../../abstractions/showEntity";
import ShowService from "../../abstractions/showService";

export interface ShowControllerReturnType<TEntity extends Entity> {
    getCurrentEntity: (id: number) => Promise<TEntity>,
    onEntityChange: (key: keyof TEntity, value: any) => void,
    goToList: () => void,
    onSave: () => void,
    onBlur: () => void,

    errors: Error[],
    canSave: boolean,
    entity?: TEntity,
}

export function useShowController<TEntity extends Entity>(service: ShowService<TEntity>, entityFields: EntityField<TEntity>[]): ShowControllerReturnType<TEntity> {
    const { goBack } = useGoToPageProvider();
    const notification = useNotificationStore();
    const { id: entityId } = useParams<{id?: string}>();

    const [canSave, setCanSave] = useState(false);
    const [errors, setErrors] = useState<Error[]>([]);

    const validateKeyValues = useCallback(() => {
        const validationResult = service.validateEntityKeyValues(entityFields);
        setCanSave(validationResult.canSave);
        setErrors(validationResult.errors)
    }, [entityFields, service]);
    
    const getCurrentEntity = useCallback(
        async (id: number) => await service.loadEntity(id) as TEntity,
        [service]
    );

    const onEntityChange = useCallback(
        (key: keyof TEntity, value: any) => {
            if (service.entity) 
                service.updateEntity({...service.entity, [key]: value});
            else
                service.updateEntity({ [key]: value } as unknown as TEntity);

            validateKeyValues();
        },
        [service, validateKeyValues]
    );

    const goToList = useCallback(
        () => {
            goBack();
            service.updateEntity();
        },
        [goBack, service]
    );

    const onSave = useCallback(async () => {
            if (service.entity) {
                const result = await service.saveEntityServer(service.entity);
                if (result !== undefined)
                    notification.showError(`Не удалось ${service.entity?.id ? "изменить" : "создать"} объект. ${result}`);
                else
                    notification.showSuccess(`Объект ${service.entity?.id ? "изменен" : "создан"}`);
            }
        },
        [notification, service]
    )

    const onBlur = useCallback(
        () => validateKeyValues(),
        [validateKeyValues]
    );

    useEffect(() => {
        (async () => {
            if (entityId !== undefined)
                await getCurrentEntity(parseInt(entityId));
            else 
                service.updateEntity()
        })();

        return () => {
            service.updateEntity();
        }
    }, []);

    return {
        getCurrentEntity,
        onEntityChange,
        goToList,
        onSave,
        onBlur,
        
        canSave,
        errors,
        entity: service.entity,
    }
}