import { observer } from 'mobx-react-lite';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddAttributeModal from './AddAttributeModal';
import React, { useState } from 'react';
import ValueEditor from '../ValueEditor';
import { DocumentAttribute, DocumentAttributeValue } from '../../../types/entities/document';
import api from '../../../api';
import { validate } from '../../../helpers/attributesValidation';
import './style.sass';
import { DataValue } from '../../../types/dataType';

interface IAttributesEditorProps {
    disabled?: boolean;
    attributes: DocumentAttributeValue[];
    documentId?: number;
    anchorElement: Element | null;
    onAttributeAdded: (attribute: DocumentAttributeValue) => void;
    onAttributeChanged: (attribute: DocumentAttributeValue) => void;
    onAttributeDeleted: (attribute: DocumentAttributeValue) => void;
    setHasErrors?: (hasErrors: boolean) => void;
    addAttributeDialogOpen: boolean;
    setAddAttributeDialogOpen: (open: boolean) => void;
    forceValidate?: boolean;
}

const AttributesEditor: React.FC<IAttributesEditorProps> = (props) => {
    const [updated, setUpdated] = useState<{ [key: number]: boolean }>({});
    const [errorMessages, setErrorMessages] = useState<{ id: number, message: string }[]>([]);

    const attributeAdded = async (attribute: DocumentAttribute) => {
        const result = await api.documents.addAttribute(props.documentId!, attribute.id, null);
        if (!result.succeeded) {
            console.error('error with adding attribute: ', result);
            return;
        }

        const { id, ...rest } = attribute;
        const value: DocumentAttributeValue = { id: result.value.id, ...rest, value: null };

        props.onAttributeAdded(value);
    }

    const attributeChanged = async (attribute: DocumentAttributeValue, value: unknown) => {
        if (!updated[attribute.id])
            return;

        validateValue(attribute, value);

        const result = await api.documents.editAttribute(attribute.id, value);
        if (!result.succeeded) {
            setErrorMessages([{ id: attribute.id, message: result.errors![0] }, ...errorMessages]);
            return;
        }

        attribute.value = value;
        props.onAttributeChanged(attribute);
        updated[attribute.id] = false;
        setUpdated({ ...updated });

        if (props.setHasErrors)
            props.setHasErrors(false);
    }

    const updateValue = (attribute: DocumentAttributeValue, value: unknown) => {
        if (attribute.value === value)
            return;

        if (errorMessages.some(m => m.id == attribute.id))
            validateValue(attribute, value);

        attribute.value = value;
        props.onAttributeChanged(attribute);
        updated[attribute.id] = true;
        setUpdated({ ...updated });
    }

    const attributeDeleted = async (attirbute: DocumentAttributeValue) => {
        const result = await api.documents.deleteAttribute(attirbute.id);
        if (!result.succeeded) {
            console.error('error with deleting attribute: ', result);
            return;
        }

        props.onAttributeDeleted(attirbute);
    }

    const validateValue = (attribute: DocumentAttributeValue, value: unknown) => {
        const validationMessage = validate(attribute, value);
        if (validationMessage != null) {
            setErrorMessages([{ id: attribute.id, message: validationMessage }, ...errorMessages]);
            if (props.setHasErrors) {
                props.setHasErrors(true);
                return false;
            }

            return true;
        }
        else
            setErrorMessages(errorMessages.filter(m => m.id != attribute.id));

        return true;
    }

    const forceValidateAllFields = () => {
        if (props.attributes == null)
            return;

        const messages = props.attributes
            .map(a => ({ id: a.id, message: validate(a, a.value)! }))
            .filter(m => m.message != null);

        setErrorMessages(messages);
    }

    React.useEffect(() => {
        if (props.forceValidate)
            forceValidateAllFields();
    }, [props.forceValidate, props.attributes]);

    return (
        <div className='attribute_flex'>
            <AddAttributeModal
                anchorElement={props.anchorElement}
                open={props.addAttributeDialogOpen}
                setOpen={props.setAddAttributeDialogOpen}
                onAdded={attributeAdded}
                documentId={props.documentId}
            />
            {props.attributes.map((a) => (
                <div className='attribute'>
                    <div className='attributeEditor_attributeName_containter'>
                        <p className={a.required ? 'atibute_p-required' : 'atibute_p'}>
                            {a.name} {a.required ? '*' : null}
                        </p>
                    </div>
                    <div className='attributeEditor_valueField_containter'>
                        <p id='attribute_editor_input'>
                            <ValueEditor
                                errorMessage={errorMessages.find(m => m.id == a.id)?.message}
                                dataValue={a as DataValue}
                                onChange={v => updateValue(a, v)}
                                onBlur={v => attributeChanged(a, v)}
                                disabled={props.disabled!}
                            />
                        </p>
                    </div>
                    <div className='deleteButton'>
                        {
                            (props.disabled || a.required) ? null :
                            <IconButton onClick={() => attributeDeleted(a)}>
                                <ClearIcon sx={{ fontSize: 18 }}/>
                            </IconButton>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}

export default observer(AttributesEditor);