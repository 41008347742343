import Routes from "../router/routes";
import { UserRole } from "../types/userRole";
import { UserData } from "./userData";

const items = [
    {
        name: 'Документы',
        opened: false,
        innerItems: [
            {
                roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
                name: 'Загрузка',
                path: Routes.uploads,
                innerRoutes: [
                    Routes.uploadDocument,
                    Routes.pickDocumentFromDiadoc,
                    Routes.pickDocumentFromSbis,
                ],
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
                name: 'Валидация',
                path: Routes.documentsOnValidation,
                innerRoutes: [
                    Routes.validateDocument,
                    Routes.findDuplicates,
                    Routes.recognition,
                    Routes.compareDocuments,
                ]
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
                name: 'Хранилище',
                path: Routes.documentsStorage,
                innerRoutes: [
                    Routes.pickDocumentFromDiadoc
                ]
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
                name: 'Просроченные',
                path: Routes.overdueDocuments
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
                name: 'Без оригинала',
                path: Routes.noOriginalDocuments
            },
        ]
    },
    {
        opened: false,
        name: "Администрирование",
        innerItems: [
            {
                roles: [UserRole.Admin, UserRole.Superuser],
                name: 'Пользователи',
                path: Routes.userList,
                innerRoutes: [
                    Routes.userShow,
                ]
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser],
                name: 'Организации',
                path: Routes.organizationList,
                innerRoutes: [
                    Routes.organizationShow,
                ]
            },
        ]
    },
    {
        opened: false,
        name: "Справочники",
        innerItems: [
            {
                roles: [UserRole.Admin, UserRole.Superuser],
                name: 'Контрагенты',
                path: Routes.contractorList,
                innerRoutes: [
                    Routes.contractorShow,
                ]
            },
            {
                roles: [UserRole.Admin, UserRole.Superuser],
                name: 'ЦФО',
                path: Routes.frcList,
                innerRoutes: [
                    Routes.frcShow
                ]
            }
        ]
    },
    {
        roles: [UserRole.Admin, UserRole.Superuser, UserRole.Accountant, UserRole.ResponsibleAccountant],
        name: 'Требования ФНС',
        path: Routes.requirements,
        innerRoutes: [
            Routes.requirementShow,
        ]
    },
]

const removeIdPartFromRoute = (route: string) => {
    let result = '';
    const splitedRoute = route.split('/').filter((e) => e.length && isNaN(parseInt(e)) && !e.includes('(\\d+)'));
    splitedRoute.forEach((c, i, arr) => result += '/' + c);
    return result;
}

const isUserAccessedToRoute = (userRoles: UserRole[], routeAccessedUserRoles: UserRole[]) =>
    userRoles?.filter(r => routeAccessedUserRoles.includes(r)).length > 0;

export const checkRouteEquals = (fR: string, sR: string) => 
    removeIdPartFromRoute(fR) === removeIdPartFromRoute(sR);

export const getRoutesByRoles = () => {
    const userRoles = UserData.roles;
    const routes = [...items];

    if (!userRoles) return [];

    return routes.filter(r => {
        if (r.innerItems) {
            r.innerItems = r.innerItems.filter(ir => isUserAccessedToRoute(userRoles, ir.roles));
            return r.innerItems.length > 0;
        }
        else
            return isUserAccessedToRoute(userRoles, r.roles)
    })
}