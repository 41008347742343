export default [
    {
        id: 0,
        name: "ООО Бест",
        tin: "01"
    },
    {
        id: 1,
        name: "ПАО Лайфстайл",
        tin: "01"
    },
    {
        id: 2,
        name: "ИП Чипинкос",
        tin: "01"
    },
]