import { MakeArrayFilters } from "../../helpers/utils";
import { Requirement } from "../../types/entities/requirement/requirement";
import { EditRequirementViewModel } from "../../types/entities/requirement/requirementEditViewModel";
import Api from "../api";
import { SucceededResult } from "../types";
import { getMany, getOne, Sorting } from "../types/getFunctions";
import { CreateRequirementOutput } from "../types/requirements/createRequirementOutput";
import { DownloadDocumentsOutput } from "../types/requirements/downloadDocumentsOutput";

export type RequirementsApiType = {
    getOne: getOne<Requirement>;
    getMany: getMany<Requirement>;

    getEditRequirementViewModel: (requirementId: number) => Promise<EditRequirementViewModel>;
    downloadFile: (requirementId: number) => Promise<any>;

    create: (organizationId: number, file: File, number: string, requirementDate: string,
        period: string, tin: string, taxOfficeNumber: string, dateReceived?: string, 
        dateSubmitting?: string, auditorId?: number, responsibleId?: number) => Promise<CreateRequirementOutput>;

    setFile: (requirementId: number, file: File) => Promise<SucceededResult>;
    setNumber: (requirementId: number, value: string) => Promise<SucceededResult>;
    setOrganization: (requirementId: number, organizationId: number) => Promise<SucceededResult>;
    setRequirementDate: (requirementId: number, dateTime: string) => Promise<SucceededResult>;
    setAuditor: (requirementId: number, contractorId: number) => Promise<SucceededResult>;
    setDateReceived: (requirementId: number, dateTime: string) => Promise<SucceededResult>;
    setDateSubmitting: (requirementId: number, dateTime: string) => Promise<SucceededResult>;
    setTin: (requirementId: number, taxOfficeNumber: string) => Promise<SucceededResult>;
    setTaxOfficeNumber: (requirementId: number, taxOfficeNumber: string) => Promise<SucceededResult>;
    setPeriod: (requirementId: number, period: string) => Promise<SucceededResult>;
    setResponsible: (requirementId: number, userId: number) => Promise<SucceededResult>;

    downloadDocuments: (requirementId: number, paragraphIds: number[],
        name: string, directory: string, maxFilesSize: number, maxFilesCount: number) => Promise<DownloadDocumentsOutput>;

    sendToArchive: (requirementId: number) => Promise<SucceededResult>;
    resumeWork: (requirementId: number) => Promise<SucceededResult>;
}

export class RequirementsApi extends Api implements RequirementsApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'accounting/requirements'), userUnauthorizedEventHandler);
    }
    
    getOne = this.getOneInternal;
    getMany = (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<Requirement>, search?: string, subroute?: string) => this.getManyInternal(skip, take, sortings, ids, filter, search, subroute);

    public getEditRequirementViewModel(requirementId: number) {
        return this.get<EditRequirementViewModel>(requirementId + '/editViewModel');
    }

    public downloadFile(requirementId: number) {
        return this.getWithFileResult(`${requirementId}/downloadFile`);
    }

    public create(organizationId: number, file: File, number: string, requirementDate: string, period: string,
        tin: string, taxOfficeNumber: string, dateReceived?: string, dateSubmitting?: string, 
        auditorId?: number, responsibleId?: number) {
        return this.postForm<CreateRequirementOutput>('create', { organizationId, file, number, requirementDate,
            auditorId, period, tin, taxOfficeNumber, dateReceived, dateSubmitting, responsibleId });
    }

    public setFile = (requirementId: number, file: File) => 
        this.putForm<SucceededResult>(`${requirementId}/setFile`, { file });

    public setNumber = (requirementId: number, number: string) => 
        this.put<SucceededResult>(`${requirementId}/setNumber`, { number });

    public setOrganization = (requirementId: number, organizationId: number) => 
        this.put<SucceededResult>(`${requirementId}/setOrganization`, { OrganizationId: { Value: organizationId } });

    public setRequirementDate = (requirementId: number, dateTime: string) =>
        this.put<SucceededResult>(`${requirementId}/setRequirementDate`, { dateTime });

    public setAuditor = (requirementId: number, contractorId: number) => 
        this.put<SucceededResult>(`${requirementId}/setAuditor`, { ContractorId: { Value: contractorId } });

    public setDateReceived = (requirementId: number, dateTime: string) => 
        this.put<SucceededResult>(`${requirementId}/setDateReceived`, { dateTime });

    public setDateSubmitting = (requirementId: number, dateTime: string) => 
        this.put<SucceededResult>(`${requirementId}/setDateSubmitting`, { dateTime });

    public setTaxOfficeNumber = (requirementId: number, taxOfficeNumber: string) => 
        this.put<SucceededResult>(`${requirementId}/setTaxOfficeNumber`, { taxOfficeNumber });

    public setTin = (requirementId: number, tin: string) =>
        this.put<SucceededResult>(`${requirementId}/setTin`, { tin });

    public setPeriod = (requirementId: number, period: string) => 
        this.put<SucceededResult>(`${requirementId}/setPeriod`, { period });

    public setResponsible = (requirementId: number, userId: number) => 
        this.put<SucceededResult>(`${requirementId}/setResponsible`, { UserId: { Value: userId } });

    public downloadDocuments = (requirementId: number, paragraphIds: number[], 
        name: string, directory: string, maxFilesSize: number, maxFilesCount: number) =>
        this.post<DownloadDocumentsOutput>(`downloadDocuments`, { requirementId, paragraphIds, 
            name, directory, maxFilesSize, maxFilesCount });

    public sendToArchive = (requirementId: number) => 
        this.put<SucceededResult>(`${requirementId}/sendToArchive`);

    public resumeWork = (requirementId: number) => 
        this.put<SucceededResult>(`${requirementId}/resumeWork`);
}