import { format} from 'date-fns';
import { RootApiType } from './../../../api/rootApi';
import { GridActionsCellItemProps, GridRenderCellParams, GridRowParams, GridValidRowModel } from "@mui/x-data-grid-pro";
import { GridColTypeDef } from "../../abstractions/gridColumn";
import { Chip } from '@mui/material';

// todo: refactoring with GridValidRowModel

const createDateColumn = <TRowModel extends GridValidRowModel>(
  field: string, 
  headerName: string, 
  sortable?: boolean, 
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode
): GridColTypeDef => ({ 
  type: 'dateTime', 
  field: field, 
  headerName: headerName, 
  sortable: sortable, 
  renderCell: renderCell ?? ((params: GridRenderCellParams<any, TRowModel, any>) => params.value ? format(new Date(params.value), "dd.MM.yyyy") : '')
});

const createDateTimeColumn = <TRowModel extends GridValidRowModel>(
  field: string, 
  headerName: string, 
  sortable?: boolean, 
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode,
  width: number = 140
): GridColTypeDef => ({ 
  type: 'dateTime', 
  field: field, 
  headerName: headerName, 
  sortable: sortable, 
  renderCell: renderCell ?? ((params: GridRenderCellParams<any, TRowModel, any>) => params.value ? format(new Date(params.value), "dd.MM.yyyy HH:mm:ss") : ''),
  width: width
});

const createReferenceColumn = <TRowModel extends GridValidRowModel>(
  colDef: GridColTypeDef, 
  referenceIdField: string,
  route: keyof RootApiType,
  ): GridColTypeDef => ({ 
  ...colDef, isReference: true, referenceIdField, route
});

const createTextColumn = <TRowModel extends GridValidRowModel>(
  field: string, 
  headerName: string, 
  sortable?: boolean, 
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode
  ): GridColTypeDef => ({ 
  type: 'string', field: field, headerName: headerName, sortable: sortable, renderCell
});

const createCurrencyColumn = <TRowModel extends GridValidRowModel>(
  field: string,
  headerName: string, 
  sortable?: boolean, 
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode
  ): GridColTypeDef => ({ 
  type: 'number', field: field, headerName: headerName, sortable: sortable, renderCell
});

const createActionColumns = <TRowModel extends GridValidRowModel> (actions: Array<(params: GridRowParams<TRowModel>) => React.ReactElement<GridActionsCellItemProps>>, width: number = 100): GridColTypeDef => {
  return {
    field: 'actions',
    type: 'actions',
    getActions: (params: GridRowParams<TRowModel>) => actions.map(action => action(params)),
    width: width,
    cellClassName: 'actions-cell'
  }
}

const createBoolColumn = <TRowModel extends GridValidRowModel>(
  field: string, 
  headerName: string, 
  sortable?: boolean, 
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode
  ): GridColTypeDef => ({
  type: 'boolean', 
  field: field, 
  headerName: headerName, 
  sortable: sortable,
  renderCell: renderCell ?? ((params: GridRenderCellParams<any, TRowModel, any>) => params.value ? 'Да' : 'Нет')
});

const createEnumColumn = <TRowModel extends GridValidRowModel>(
  field: string,
  headerName: string,
  dictionary: { key: number, value: string }[],
  sortable?: boolean,
  renderCell?: (params: GridRenderCellParams<any, TRowModel, any>) => React.ReactNode,
  ): GridColTypeDef => ({
    type: 'singleSelect',
    field: field,
    headerName: headerName,
    sortable: sortable,
    cellClassName: "multiple-enum-cell",
    renderCell: renderCell ?? ((params: GridRenderCellParams<any, TRowModel, any>) => {
      if (!params.value)
        return '';

      return params.value.map((v: number) => {
        const item = dictionary.find(i => i.key === v);
        if (!item)
          return null;
        
        return <Chip key={item.key} label={item.value} size="small" />
      });
    })
});


export { 
  createDateTimeColumn, createTextColumn, createReferenceColumn, 
  createActionColumns, createBoolColumn, createDateColumn, 
  createCurrencyColumn, createEnumColumn
};