import { GridRowParams } from "@mui/x-data-grid-pro";
import { Dispatch, FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../../api";
import { GetManyResult } from "../../../../../../api/types/getFunctions";
import { createActionColumns, createDateTimeColumn, createReferenceColumn, createTextColumn } from "../../../../../../common/view/entityListView/columnsFactory";
import useGoToPageProvider from "../../../../../../router/goToPageProvider";
import useNotificationStore from "../../../../../../stores/notificationStore";
import { Document, documentStatusCaption } from "../../../../../../types/entities/document";
import { Entity } from "../../../../../../types/entities/entity";
import GoToCompareDocsButton from "../../../../../components/GoToCompareDocsButton";
import FindDuplicatesDocument from "../../../../../domain/findDuplicates/findDuplicatesEntity";
import UnbindDialoc from "../UnbindDialog";
import "./style.sass";
import { BaseListControllerProps, useBaseListController } from "../../../../../../common/controller/entityListController/baseEntityListController";
import { baseListService } from "../../../../../../common/services/listService";
import EntityListView from "../../../../../../common/view/entityListView";
import { observer } from "mobx-react-lite";

const DocumentStackGrid: FC<DocumentGridProps> = ({gridDocs, documentId, stackId, updateGridDocs}): JSX.Element => {
  const notification = useNotificationStore();
  const unbindDocument = useCallback((params: GridRowParams<FindDuplicatesDocument>) => {
    const unbindDocumentHandle = async () => {
      const result = await api.documents.unbindDocument(params.row.id);
      if (!result.succeeded) {
        if (result.errors) {
          notification.showError(result.errors.join('\n'));
        }
        else
          console.error("Unbind document failed", result);
        return;
      }
        
      updateGridDocs(prev => {
        const newState = prev.filter(doc => doc.id !== params.id)
        return newState;
      });
    };

    return <UnbindDialoc unbindDocumentHandle={unbindDocumentHandle} />
  }, [notification, updateGridDocs]);

  const goToCompareDocs = useCallback((params: GridRowParams<FindDuplicatesDocument>) =>
      <GoToCompareDocsButton documentId={documentId!} compareDocumentId={params.row.id} canBind={false} />
  , [documentId]);
  
  const loadEntities = useCallback(async (): Promise<GetManyResult<Entity>> => {
    return { items: gridDocs ?? [], totalCount: gridDocs?.length ?? 0 };
  }, [gridDocs]);

  return (
    <div className="document_stack">
      <div className="document_stack-header">
        Копии/оригинал
      </div>
      <EntityListView<Entity, BaseListControllerProps<Entity>> 
        listControllerProps={{
          columns: [
            createTextColumn("status", "Статус", false, (p) => documentStatusCaption(p.value)),
            createDateTimeColumn<any>('createdAt', 'Дата загрузки'),
            {...createReferenceColumn(createTextColumn<any>('name', 'Организация'), 'organizationId', 'organizations'), width: 130},
            createActionColumns<FindDuplicatesDocument>([goToCompareDocs, unbindDocument], 255)
          ],
          listService: baseListService,
          storeName: "documentStack",
          overrideService: (serivce) => ({...serivce, getLoadingDeps: () => [...serivce.getLoadingDeps(), gridDocs]}),
          loadEntities: loadEntities,
        }} 
        useListController={useBaseListController} 
      />
    </div>
  );
}

export const useDocumentGrid = (
  setStackDocuments: (setter: (prev: Document[]) => Document[]) => void,
  documentNumber?: string, 
  stackId?: number, 
  docs?: Entity[],   
  contractorId?: number, 
  documentDate?: string,
  isMainInStack?: boolean,
) => {
  const { id: documentIdStr } = useParams<{ id?: string }>();
  const documentId = Number(documentIdStr);
  const { goToFindDublicates } = useGoToPageProvider();

  const findDublicatesHandler = useCallback(() => {
    if (!documentNumber || !stackId)
      return;

    goToFindDublicates(documentId, documentNumber, stackId, contractorId, documentDate?.split("T")[0]);
  }, [goToFindDublicates, documentId, documentNumber, stackId, contractorId, documentDate]);
  
  const gridDeps = JSON.stringify({docs, documentId, stackId, setStackDocuments});

  // prevent re-rendering after reloading the view model
  const canShowGrid = useMemo(() => documentId && docs && docs.length > 0 && stackId && isMainInStack, [gridDeps]);
  const gridProps = useMemo(() => ({
    gridDocs: docs,
    documentId: documentId,
    stackId: stackId as number,
    updateGridDocs: setStackDocuments
  }), [gridDeps]);

  const canFindDublicates = useMemo(() => stackId && documentNumber && docs && docs.length === 0, [stackId, documentNumber, docs]);

  return {
    findDublicatesHandler,
    canFindDublicates,
    canShowGrid,
    gridProps
  };
}

interface DocumentGridProps {
  gridDocs?: Entity[];
  documentId?: number;
  stackId?: number;
  updateGridDocs: (setter: (prev: Document[]) => Document[]) => void;
}

export default observer(DocumentStackGrid);
