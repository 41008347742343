import React, { useState } from 'react';
import './style.sass';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, Menu, MenuItem } from "@mui/material";
import { MenuOpen, List, Edit, Close } from '@mui/icons-material';
import DownloadDocumentsDialog from '../DownloadDocumentsDialog';
import { RequirementParagraph } from '../../../types/entities/requirement/requirement';
import ShowParagraphDialog from '../ParagraphDialog';
import ShowDocumentsDialog from '../DocumentsDialog';
import useGoToPageProvider from '../../../router/goToPageProvider';

interface IParagraphsTableProps {
    paragraphs: RequirementParagraph[];
    requirementId: number;
    hasAccess: boolean;
    setRequirementParagraphs: (paragraphs: RequirementParagraph[]) => void;
}

export enum EditMode {
    Add = "Добавить",
    GetList = "Открыть список",
    Edit = "Редактировать",
    Delete = "Удалить"
}

const ParagraphsTable = (props: IParagraphsTableProps ) => {
    const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
    const [uploadAnchorEl, setUploadAnchorEl] = useState<null | HTMLElement>(null);
    
    const [tableRows, setTableRows] = useState<any[]>([]);
    
    const [editingParagraph, setEditingParagraph] = useState<RequirementParagraph>();

    const [open, setOpen] = useState(false);
    const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState<EditMode>(EditMode.Add);

    const [selectedParagraphIds, setSelectedParagraphIds] = useState<number[]>([]);

    const { goToParagraphDocuments } = useGoToPageProvider();

    const handleClickOpen = () => {
        setEditMode(EditMode.Add);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDocumentsDialogClose = () => {
        setDocumentsDialogOpen(false);
    };
    
    const handleActionsMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        setUploadAnchorEl(event.currentTarget);
        setActionsMenuOpen(true);
    }

    const columns: GridColDef[] = [
        { field: 'number', headerName: 'Пункт', minWidth: 130 },
        { field: 'responsible', headerName: 'Ответственный', minWidth: 230,
        renderCell: (params) => <div>{params.value.lastName ? `${params.value.firstName ? `${params.value.firstName.substring(0, 1)}.` : ''} ${params.value.lastName}` : <span className='empty-value-label'>Отсутствует</span>}</div> },
        {
          field: 'comment',
          headerName: 'Комментарий к загрузке',
          minWidth: 350,
          flex: 1
        },
        {
            field: 'documentsCount',
            headerName: 'Кол-во документов',
            minWidth: 80,
            flex: 1,
            renderCell: (params) => <div>{!params.value ? <span className='empty-value-label'>Список пуст</span> : params.value}</div>
        },
        {
          field: 'actions',
          headerName: '',
          description: 'Предназначено для действий',
          
          minWidth: 150,
          renderCell: (params) => {
                return <div>
                {props.hasAccess ?
                <div>
                    <Button 
                className="table_button"
                variant="contained"
                endIcon={<MenuOpen />}
                onClick={(event) => {
                    handleActionsMenuOpen(event);

                    var paragraph = props.paragraphs.find(p => p.id == params.row.id);
                    if (paragraph)
                        setEditingParagraph(paragraph);
                  }}>Действия</Button>
                        <Menu
                            anchorEl={uploadAnchorEl}
                            open={actionsMenuOpen}
                            onClose={() => setActionsMenuOpen(false)}
                        >
                            <MenuItem disabled={!props.hasAccess} onClick={(e) => goToParagraphDocuments(props.requirementId, editingParagraph!.id) }><List fontSize="small" />{EditMode.GetList}</MenuItem>
                            <MenuItem disabled={!props.hasAccess} onClick={(e) => {
                                setEditMode(EditMode.Edit);
                                setActionsMenuOpen(false);
                                setOpen(true);
                            }}>
                                    
                                <Edit fontSize="small" /> {EditMode.Edit}</MenuItem>
                            <MenuItem disabled={!props.hasAccess} onClick={(e) => {
                                setEditMode(EditMode.Delete);
                                setActionsMenuOpen(false);
                                setOpen(true);
                            }}>
                                <Close fontSize="small" color="error" /> {EditMode.Delete}</MenuItem>
                        </Menu>
                </div> : <Button variant="contained" className="table_button"
                onClick={(e) => goToParagraphDocuments(props.requirementId, editingParagraph!.id) }>Открыть список</Button>
                }</div>
                }
            
        }
      ];

    return (
        <div className='paragraphs_table'>
            <div className="paragraphs_flex-header">
                <label className='name-label requirements_paragraphLabel'>Пункты требования</label>
                    <div className='paragraphs_actions'>
                        {
                            tableRows.length > 0 &&
                            <DownloadDocumentsDialog 
                                requirementId={props.requirementId}
                                paragraphIds={selectedParagraphIds}
                                fullPackage={false}
                            />
                        }
                        <Button disabled={!props.hasAccess} onClick={handleClickOpen} variant="contained" >
                            Добавить пункт
                        </Button>
                        <ShowParagraphDialog
                            requirementId={Number(props.requirementId)}
                            editMode={editMode}
                            paragraphs={props.paragraphs}
                            setRequirementParagraphs={props.setRequirementParagraphs}
                            editingParagraph={editingParagraph}
                            open={open}
                            onClose={handleClose}
                        />

                        <ShowDocumentsDialog
                            paragraph={editingParagraph}
                            open={documentsDialogOpen}
                            onClose={handleDocumentsDialogClose}
                        />
                    </div>
            </div>
            <div style={{ width: '100%' }}>
                    <DataGrid

                        rows={props.paragraphs}
                        columns={columns}
                        autoHeight={true}
                        rowHeight={40}
                        disableExtendRowFullWidth={true}
                        checkboxSelection
                        getRowId={(row) => row.id}
                        onSelectionModelChange={(ids) => {
                            const selectedIds = new Set(ids);
                            const selectedRows = props.paragraphs.filter((row) =>
                              selectedIds.has(row.id),
                            );
                            
                            var selectedParagraphIds = new Array();
                            for (var i = 0; i < selectedRows.length; i++)
                                selectedParagraphIds.push(selectedRows[i].id);

                            setSelectedParagraphIds(selectedParagraphIds);

                            setTableRows(selectedRows);
                          }}
                    />
            </div>
        </div>
    );
};

export default ParagraphsTable;