import { FinancialResponsibilityCenter } from "../types/entities/financialResponsibilityCenter";
import Api, { EditableEntityApi } from "./api";
import { getMany, GetManyApi, getOne } from "./types/getFunctions";
import { SucceededWithDataResult } from "./types/succeededResult";

export type FinancialResponsibilityCentersApiType = EditableEntityApi<FinancialResponsibilityCenter, SucceededWithDataResult<number>, SucceededWithDataResult<number>> & GetManyApi<FinancialResponsibilityCenter> & {
    getOne: getOne<FinancialResponsibilityCenter>;
    getMany: getMany<FinancialResponsibilityCenter>;
};

export class FinancialResponsibilityCentersApi extends Api implements FinancialResponsibilityCentersApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'financialResponsibilityCenters'), userUnauthorizedEventHandler);
    }

    getOne = this.getOneInternal.bind(this);
    getMany = this.getManyInternal.bind(this);

    create = this.createInternal.bind(this);
    update = this.updateInternal.bind(this);
};
