import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../common/controller/entityListController/entityListController";
import EntityListWithFilters from "../../../common/pages/blanks/List";
import { createActionColumns, createCurrencyColumn, createDateColumn, createReferenceColumn, createTextColumn } from "../../../common/view/entityListView/columnsFactory";
import FindDuplicatesDocument from "../../domain/findDuplicates/findDuplicatesEntity";
import FindDuplicatesDocumentFilter from "../../domain/findDuplicates/findDuplicatesFilter";
import useGoToPageProvider from "../../../router/goToPageProvider";
import './style.sass';
import { useParams } from "react-router-dom";
import { createNumberFilter } from "../../../common/view/listFiltersView/components/filters/components/numberFilter";
import { createBoolFilter } from "../../../common/view/listFiltersView/components/filters/components/booleanFilter";
import FindDuplicatesStore from "../../infrastructure/findDuplicates/findDuplicatesStore";
import ListService from "../../../common/abstractions/listService";
import useQuery from "../../../router/useQuery";
import GoToCompareDocsButton from "../../components/GoToCompareDocsButton";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { documentStatusCaption } from "../../../types/entities/document";
import { createReferenceFieldFilter } from "../../../common/view/listFiltersView/components/filters/components/referenceFilter/referenceFilter";
import { createRangeDateFilter } from "../../../common/view/listFiltersView/components/filters/components/dateRangeFilter";
import BindDocumentToDocument from "../../components/BindDocumentToDocument";
import { gridIds, IEntityStores } from "../../../stores/entitiesStore";
import { RootApiType } from "../../../api/rootApi";
import { createTextFilter } from "../../../common/view/listFiltersView/components/filters/components/textFilter";

const FindDuplicatesListView: React.FC = () => {
    const { documentId, documentNumber, date, contractorId } = useFindDuplicatesParamsFromQuery();
    const { goBack } = useGoToPageProvider();

    const result = useFindDuplicatesListController(documentId, documentNumber, date, contractorId);
    
    if (result === null)
        return null;

    const {
        selectedDocs,
        bindSelectedFields,
        storeName,
        loadEntities,
        overrideService
    } = result;

    const DataGridHeader = ({ selectedDocs }: { selectedDocs: number[] }) =>
        <div className="findDuplicates_link-button">
            <BindDocumentToDocument text="Привязать к выделенному" documentId={documentId} selectedDocs={selectedDocs} afterBindHandler={() => goBack()} />
        </div>

    const GoToCompareDocs = (params: GridRowParams<FindDuplicatesDocument>) =>
        <GoToCompareDocsButton documentId={documentId} compareDocumentId={params.row.id} canBind={true} />
    
    return (
        <EntityListWithFilters<FindDuplicatesDocument, FindDuplicatesDocumentFilter, FindDuplicatesListViewProps>
            ListHeaderView={<ListHeaderView />}
            DataGridHeader={<DataGridHeader selectedDocs={selectedDocs} />}
            listControllerProps={{
                columns: [
                    createReferenceColumn(createTextColumn<FindDuplicatesDocument>('name', 'Организация'), 'organizationId', 'organizations'),
                    createReferenceColumn(createTextColumn<FindDuplicatesDocument>('name', 'Тип'), 'typeId', 'types'),
                    createTextColumn('status', 'Статус', false, (v) => documentStatusCaption(v.value)),
                    createTextColumn('documentNumber', 'Номер документа', false),
                    createDateColumn<FindDuplicatesDocument>('documentDate', 'Дата документа', true),
                    createCurrencyColumn('sum', 'Сумма'),
                    createReferenceColumn(createTextColumn<FindDuplicatesDocument>('name', 'Контрагент'), 'contractorId', 'contractors'),
                    createReferenceColumn(createTextColumn<FindDuplicatesDocument>('tin', 'ИНН контрагента'), 'contractorId', 'contractors'),
                    createReferenceColumn(createTextColumn<FindDuplicatesDocument>('documentNumber', 'Номер связанного договора'), 'contractId', 'documents'),
                    { ...createActionColumns<FindDuplicatesDocument>([GoToCompareDocs], 120), }
                ],
                bindSelectedFields: bindSelectedFields,
                overrideService: overrideService,
                loadEntities
            }}
            storeName={storeName}
            filterAttributes={{
                excludeDocumentId: createNumberFilter(undefined, [documentId], false, true, false),
                isMainInStack: createBoolFilter(undefined, [true], false, true, false),
                excludeBeforeValidation: createBoolFilter(undefined, [true], false, true, false),
                documentNumber: createTextFilter('Номер документа', documentNumber ? [documentNumber] : []),
                organizationId: createReferenceFieldFilter('Организация', [], 'organizations'),
                documentDateRange: createRangeDateFilter("Дата документа", date ? [[date, date]] : []),
                contractorId: createReferenceFieldFilter('Контрагент', contractorId ? [contractorId] : [], 'contractors'),
                contractorIdByTin: createReferenceFieldFilter('ИНН контрагента', [], 'contractors', 'tin', true, false, true, 'taxpayerIdentificationNumber'),
            }}
            useListController={useCustomListController}
        />
    );
};

const useFindDuplicatesParamsFromQuery = () => {
    const query = useQuery();
    const documentNumber = query.get('dn');
    const { documentId: docIdString } = useParams<{ documentId?: string }>();
    const documentId = Number(docIdString);
    const date = query.get('d');
    const contractorIdText = query.get('cId');
    let contractorId = undefined;

    if (contractorIdText)
        contractorId = Number(contractorIdText);

    return {
        documentNumber,
        documentId,
        date,
        contractorId
    }
} 

const ListHeaderView = () => {
    const { goBack } = useGoToPageProvider();
    const query = useQuery();
    const documentNumber = query.get('dn');

    return (
        <div className="find_duplicates-header">
            <Button
                color='inherit'
                startIcon={<ArrowBack />}
                onClick={() => goBack()}
            >
                Назад
            </Button>
            <Typography>Поиск дубликатов № {documentNumber}</Typography>
        </div>
    );
}

const useFindDuplicatesListController = (documentId: number, documentNumber: string | null, date: string | null, contractorId?: number) => {
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedDocs, setSelectedDocs] = useState<number[]>([]);

    if (documentId === undefined || documentNumber === undefined)
    {
        console.error('Not all params are defined in query string');
        return null;
    }

    const bindSelectedFields = (selectionModel: number[]) => setSelectedDocs(selectionModel);

    const loadEntities: keyof RootApiType = "documents";

    const storeName = (context: IEntityStores): FindDuplicatesStore => getStore(context, documentNumber, documentId, date, contractorId)

    const overrideService = (
        service: ListService<FindDuplicatesDocument, FindDuplicatesDocumentFilter>
    ): ListService<FindDuplicatesDocument, FindDuplicatesDocumentFilter> => ({...service, getLoadingDeps: () => [...service.getLoadingDeps(), refresh]});

    return {
        selectedDocs,
        bindSelectedFields,
        refresh,
        setRefresh,
        storeName,
        loadEntities,
        overrideService
    }
}

const getStore = (context: IEntityStores, documentNumber: string | null, documentId: number, date: string | null, contractorId?: number): FindDuplicatesStore => {
    let storeName = `findDuplicates${documentId}|${documentNumber ?? ""}`;
    if (date)
        storeName += `|${date}`;
        
    if (contractorId)
        storeName += `|${contractorId}`;

    let store = context.getFindDuplicatesStore(storeName);
    if (!store)
        store = context.addNewFindDuplicatesStore(storeName, new FindDuplicatesStore(gridIds.findDuplicates));

    return store;
}

const useCustomListController = (props: FindDuplicatesListViewProps): ListControllerReturnType<FindDuplicatesDocument> => {
    const rest = useListController(props);
    const { bindSelectedFields } = props; 
    const [selectionModel, setSelectionModel] = useState<number[]>([]);

    useEffect(() => {
        bindSelectedFields(selectionModel);
    }, [selectionModel]);

    return {
        ...rest,
        onSelectionModelChange: (selectionModel: number[]) => setSelectionModel(selectionModel),
        checkboxSelection: true,
        disableMultipleSelection: true
    } as ListControllerReturnType<FindDuplicatesDocument>;
}

interface FindDuplicatesListViewProps extends ListControllerProps<FindDuplicatesDocument, FindDuplicatesDocumentFilter> {
    bindSelectedFields: (selectionModel: number[]) => void;
}

const FindDuplicatesList = observer(FindDuplicatesListView);

export default FindDuplicatesList;