import { GridColumns } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from "react";
import { Entity } from '../../../../types/entities/entity';
import { GridColTypeDef } from '../../../abstractions/gridColumn';
import { BaseListService } from "../../../abstractions/listService";

const useEffectLoadingEntities = <TEntity extends Entity>(props: UseEffectLoadingEntitiesProps<TEntity>) => {
  const { service, columns, needSkipFirstRender } = props;
  const [loading, setLoading] = useState(false);
  
  const useEffectDeps = JSON.stringify({...service.getLoadingDeps()});

  const didMountRef = useRef(true);
  
  useEffect(() => {
    // CRUTCH!!! skip first render because need to set filter attributes in store (see useEffectSetFilterAttributes) 
    if (didMountRef.current && needSkipFirstRender)
    {
      didMountRef.current = false;
      return;
    }

    (async () => {
      setLoading(true);
      const entities = await service.loadAndSaveEntities();
      await loadSubEntities<TEntity>(service, entities, columns);
      setLoading(false);
    })();
  }, [useEffectDeps]);


  return loading;
}

const loadSubEntities = async <TEntity extends Entity>(service: BaseListService<TEntity>, entities: TEntity[], columns: GridColTypeDef[]) => {
  const cols = columns.filter(c => c.isReference);

  await Promise.all(cols.map(async col => await loadSubEntitiesForColumn(service, entities, col)));
}


interface UseEffectLoadingEntitiesProps<TEntity extends Entity> {
  service: BaseListService<TEntity>;
  columns: GridColumns;
  needSkipFirstRender?: boolean;
}

export { useEffectLoadingEntities, UseEffectLoadingEntitiesProps, loadSubEntities };


const loadSubEntitiesForColumn = async <TEntity extends Entity>(service: BaseListService<TEntity>, entities: TEntity[], col: GridColTypeDef): Promise<any> => {
  if (!col.route || !col.referenceIdField) 
    throw new Error('Reference column must have route and referenceIdField, trouble with column ' + JSON.stringify(col));
  
  const ids = entities.map((e: any) => e[col.referenceIdField!]).filter(id => id) as number[];
  if (ids.length === 0)
    return;

  const subroute = col.route;
  await service.loadSubEntities(subroute, Array.from(new Set(ids)));
}

