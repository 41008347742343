import { DateRange, DesktopDateRangePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Stack, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { FilterProps } from ".";
import Filter, { FilterAttributes, FilterType } from "../../../../../abstractions/filter";
import { format } from 'date-fns';

const RangeDateFilter = <TFilter extends Filter>({filterKey, attributes, updateFilter}: FilterProps<DateRange<string>, TFilter>) => {
  const { label, type } = attributes;
  const [value, setValue] = useState<DateRange<Date>>([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
          <DesktopDateRangePicker
              startText={`${label} от`}
              endText={`${label} до`}
              value={value}
              onClose={() => {
                  if (!value || !value[0] || !value[1])
                    return;
        
                  updateFilter(filterKey, [format(value[0], 'yyyy-MM-dd'), format(value[1], 'yyyy-MM-dd')]);
                  setValue([null, null]);
              }}
              onChange={(newValue: any) => setValue(newValue)}
              renderInput={(startProps: any, endProps: any) => (
                  <Fragment>
                      <TextField variant="standard" style={{marginRight:24}} {...startProps} />
                      <TextField variant="standard"  {...endProps} />
                  </Fragment>
              )}
          />
      </Stack>
    </LocalizationProvider>
  );
}

export default RangeDateFilter;

const createRangeDateFilter = (label: string, defaultValues: DateRange<string>[] = [], needShow: boolean = true, isStaticFilter: boolean = false, needShowChips: boolean = true): FilterAttributes<DateRange<string>> => ({
  label,
  needShowFilter: needShow,
  values: defaultValues,
  isStaticFilter,
  type: FilterType.DateRange,
  needShowChips,
  valuePresent: (value) => " от: " + dateToRuFormat(value[0]) + " до: " + dateToRuFormat(value[1])
});

const dateToRuFormat = (date: string | null) =>
  date ? format(new Date(date), "dd.MM.yyyy") : "";

export { createRangeDateFilter };