import { MakeArrayFilters } from "../helpers/utils";
import { UserData } from "./../helpers/userData";
import { SucceededResult } from "./types";
import { GetManyResult, Sorting } from "./types/getFunctions";

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export default class Api {
    private _apiBasePath: string;
    private _userUnauthorizedEventHandler: () => void;

    constructor(apiBasePath: string, userUnauthorizedEventHandler: () => void) {
        this._apiBasePath = apiBasePath;
        this._userUnauthorizedEventHandler = userUnauthorizedEventHandler;
    }

    protected getOneInternal = <TEntity>(id: number, subroute?: string) => this.get<TEntity>((subroute ? subroute : '') + id);

    protected async getManyInternal<TEntity>(skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<TEntity>, search?: string, subroute?: string) {
        return this.getManyInternalByArrayFilter<TEntity, TEntity>(skip, take, sortings, ids, filter, search, subroute);
    }

    protected async getManyInternalBySimpleFilter<TResult, TFilter>(subroute: string, filter: TFilter) {
        const params = new URLSearchParams();
        const f = filter as any;

        if (f != null)
            for (const prop in f) {
                const value = f[prop];
                if (value != null)
                    params.append(prop, value.toString());
            }

        const paramsString = params.toString();
        const data = await this.get<TResult>((subroute ? subroute : '') + (paramsString !== '' ? '?' + paramsString : ''));
        
        if (data == null)
            return [];

        return data;        
    }

    protected async getManyInternalByArrayFilter<TEntity, TFilter>(skip?: number, take?: number, sortings?: Sorting[], ids?: number[], 
        filter?: MakeArrayFilters<TFilter>, search?: string, subroute?: string) {
        const params = new URLSearchParams();

        if (skip != null)
            params.append('s', skip.toString());
        
        if (take != null)
            params.append('t', take.toString());

        sortings?.forEach((sorting, idx) => {
            params.append(`o[${idx}].field`, sorting.field);
            params.append(`o[${idx}].order`, sorting.order.toString());
        });

        ids?.forEach((id, idx) => params.append(`id[${idx}]`, id.toString()));

        if (filter != null) {
            const f = filter as any;
            for (const prop in f) {
                const filterArray = f[prop];
                if (filterArray == null)
                    continue;
                    
                for (let i = 0; i < filterArray.length; i++) {
                    const currentFilter = filterArray[i];
                    
                    if (Array.isArray(currentFilter)) {
                        // filters with array value looks like f[0].createdAtRange[0]={data}&f[0].createdAtRange[1]={data} in query string
                        for (let j = 0; j < currentFilter.length; j++) {
                            params.append(`f[${i}].${prop}[${j}]`, currentFilter[j].toString());
                        }
                        continue;
                    }
                    // filters looks like f[0].onValidation=false in query string
                    params.append(`f[${i}].${prop}`, currentFilter.toString());
                }  
            }
        }

        if (search != null && search !== '')
            params.append('q', search);

        const paramsString = params.toString();
        const data = await this.get<GetManyResult<TEntity>>((subroute ? subroute : '') + (paramsString !== '' ? '?' + paramsString : ''));
        if (data == null)
            return { totalCount: 0, items: [] };

        return data;
    }

    protected createInternal = <TEntity = object, TResponse = any>(data: TEntity, subroute?: string) => this.post<TResponse>((subroute ? subroute : ''), data as any);
    protected updateInternal = <TEntity = object, TResponse = any>(id: number, data: TEntity, subroute?: string) => this.put<TResponse>((subroute ? subroute : '') + id, data as any);

    protected get = <TResponse>(endpoint: string) => this.makeRequest<TResponse>(endpoint, 'GET');
    protected post = <TResponse>(endpoint: string, data?: object) => this.makeRequest<TResponse>(endpoint, 'POST', data);
    protected put = <TResponse>(endpoint: string, data?: object) => this.makeRequest<TResponse>(endpoint, 'PUT', data);
    protected delete = <TResponse>(endpoint: string, data?: object) => this.makeRequest<TResponse>(endpoint, 'DELETE', data);

    protected postForm = <TResponse>(endpoint: string, data: object) => this.makeRequest<TResponse>(endpoint, 'POST', data, true);
    protected putForm = <TResponse>(endpoint: string, data: object) => this.makeRequest<TResponse>(endpoint, 'PUT', data, true);

    protected getWithFileResult = <TResponse>(endpoint: string) => this.makeRequest<TResponse>(endpoint, 'GET', undefined, false, this.fileResultPresenter);

    private async makeRequest<TResponse>(endpoint: string, method: Method, data?: object, asForm: boolean = false, resultPresenter = this.jsonResultPresenter) {
        const headers: any = { };

        if (!asForm)
            headers['Content-Type'] = 'application/json;charset=utf-8';

        const token = UserData.accessToken;
        if (token)
            headers['Authorization'] = 'Bearer ' + token;

        const response = await fetch(this.getPath(endpoint), {
            method: method,
            headers,
            body: this.createRequestBody(data, asForm),
        });

        if (response.status === 401) {
            this._userUnauthorizedEventHandler();
            throw new Error("User unauthorized");
        }            

        let obj = await resultPresenter(response);

        return obj as TResponse;
    }

    private createRequestBody(data: any, asForm: boolean) {
        if (data == null)
            return null;

        if (!asForm)
            return JSON.stringify(data);

        const formData = new FormData();
        for (const field in data)
            if (data[field] != null)
                formData.append(field, data[field]);

        return formData;
    }

    private getPath = (endpoint: string) => endpoint.startsWith('?') ? 
        this._apiBasePath + endpoint : 
        Api.combineUrls(this._apiBasePath, endpoint);

    protected static combineUrls = (first: string, second: string) => first + (first.endsWith('/') ? '' : '/') + second;

    private async jsonResultPresenter(response: Response) {
        let obj;

        try {
            obj = await response.json();
        } catch (error) {
            throw new Error("Request failed, response is not json, response status: " + response.status);
        }

        if (obj == null)
            throw new Error("Request failed with empty response");
        return obj;
    }

    private async fileResultPresenter(response: Response) {
        let obj;
        try {
            obj = {} as { [key: string]: any; };
            const stream = await response.blob();
            const header = response.headers.get('content-disposition');
            let filename = header?.split(';')[2].split('=UTF-8\'\'')[1];
            if (filename)
                filename = decodeURIComponent(filename);

            obj['filename'] = filename;
            obj['file'] = stream;
        }
        catch (error) {
            throw new Error("Error while parsing form result");
        }

        if (obj == null)
            throw new Error("Request failed with empty response");

        return obj;
    }
}


interface EditableEntityApi<TEntity, TCreateResponse extends SucceededResult = SucceededResult, TUpdateResponse extends SucceededResult = SucceededResult> {
    create(entity: TEntity): Promise<TCreateResponse>;
    update(id: number, entity: TEntity): Promise<TUpdateResponse>;
}

export { EditableEntityApi }