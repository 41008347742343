import { makeAutoObservable } from "mobx";
import { Pagination } from "../types/pagination";
import { RequirementParagraph } from "../types/entities/requirement/requirement";

export class RequirementParagraphStore {
    private _RequirementParagraphs: RequirementParagraph[];
    private _pagination: Pagination;
    private _totalCount: number;
    private _currentRequirementParagraph?: number;

    constructor() {
        makeAutoObservable(this);
        this._RequirementParagraphs = new Array<RequirementParagraph>();
        this._pagination = { page: 0, perPage: 10 };
        this._totalCount = 0;
    }

    public get RequirementParagraphs () {
        return this._RequirementParagraphs;
    }

    public set RequirementParagraphs (newRequirementParagraphs: RequirementParagraph[]) {
        this._RequirementParagraphs = newRequirementParagraphs;
    }

    public get pagination () {
        return this._pagination;
    }

    public set pagination (newPagination: Pagination) {
        this._pagination = newPagination; 
    }

    public get totalCount () {
        return this._totalCount;
    }

    public set totalCount (newTotalCount: number) {
        this._totalCount = newTotalCount;
    }
    
    public get currentRequirementParagraph () {
        return this._currentRequirementParagraph;
    }

    public set currentRequirementParagraph (id: number | undefined) {
        this._currentRequirementParagraph = id;
    }
}