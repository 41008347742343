
export default [
    {
        id: 0,
        typeId: 1,
        documentNumber: "31233213",
        documentDate: new Date("12.02.2022"),
        contractorId: 42,
        customer: "ПАО НЕКСТ",
        onValidation: false,
        validated: true,
    },
    {
        id: 2,
        typeId: 2,
        documentNumber: "0298312",
        documentDate: new Date("27.05.2020"),
        contractorId: 42,
        customer: "ЗАО СЯОМИ",
        onValidation: false,
        validated: true,
    },
    {
        id: 3,
        typeId: 1,
        documentNumber: "2825646",
        documentDate: new Date("10.02.2022"),
        contractorId: 42,
        customer: "ПроктрИГембл",
        onValidation: false,
        validated: true,
    },
    {
        id: 4,
        typeId: 5,
        documentNumber: "2357694",
        documentDate: new Date("10.02.2022"),
        contractorId: 42,
        customer: "ПАО НЕКСТ",
        onValidation: false,
        validated: true,
    },
    {
        id: 5,
        typeId: 1,
        documentNumber: "3914839",
        documentDate: new Date("01.01.2022"),
        contractorId: 42,
        customer: "ИП Гульбашев",
        onValidation: false,
        validated: true,
    },
    {
        id: 6,
        typeId: 3,
        documentNumber: "1337858",
        documentDate: new Date("22.02.2022"),
        contractorId: 42,
        customer: "ИП Петров",
        onValidation: false,
        validated: true,
    },
    {
        id: 7,
        typeId: 1,
        documentNumber: "3528571",
        documentDate: new Date("01.12.2021"),
        contractorId: 42,
        customer: "ИП Горшок",
        onValidation: false,
        validated: true,
    },
    {
        id: 8,
        typeId: 4,
        documentNumber: "256711",
        documentDate: new Date("19.09.2020"),
        contractorId: 42,
        customer: "ПАО ФТОР",
        onValidation: false,
        validated: true,
    },
    {
        id: 9,
        typeId: 1,
        documentNumber: "3169277",
        documentDate: new Date("10.08.2020"),
        contractorId: 42,
        customer: "ПАО МАШЕНЬКА",
        onValidation: false,
        validated: true,
    },
    {
        id: 10,
        typeId: 2,
        documentNumber: "708626",
        documentDate: new Date("15.02.2017"),
        contractorId: 42,
        customer: "Вконтакте",
        onValidation: false,
        validated: false,
    },
    {
        id: 11,
        typeId: 1,
        documentNumber: "708626",
        documentDate: new Date("15.02.2017"),
        contractorId: 42,
        customer: "Вконтакте",
        onValidation: false,
        validated: true,
    },
];