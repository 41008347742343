enum Routes {
    index = "/",
    signIn = "/signin",
    resetPassword = "/resetPassword",
    forgotPassword = "/forgotPassword",
    uploads='/documents/upload',
    documentsStorage='/documents',
    uploadDocument='/documents/new/:id(\\d+)?',
    recognition='/recognition',
    pickDocumentFromDiadoc='/diadocDocuments/:documentId(\\d+)',
    pickDocumentFromSbis='/sbisDocuments/:documentId(\\d+)?',
    validateDocument = '/documents/validation/:id(\\d+)',
    documentsOnValidation = '/documents/validation',
    findDuplicates = '/documents/validation/:documentId(\\d+)/findDuplicates',
    showDocument = '/documents/:id(\\d+)',
    compareDocuments = '/compareDocuments/:mainDocumentId(\\d+)',
    userList = "/users",
    userShow = "/user/:id(\\d+)?",
    organizationList = "/organizations",
    organizationShow = "/organization/:id(\\d+)?",
    requirements='/requirements',
    requirementShow='/requirement/:id(\\d+)?',
    contractorList='/contractors',
    contractorShow='/contractor/:id(\\d+)?',
    paragraphDocuments='/paragraphDocuments/:requirementId(\\d+)',
    noOriginalDocument='/documents/noOriginal/:id(\\d+)',
    noOriginalDocuments='/documents/noOriginal',
    overdueDocument='/documents/overdue/:id(\\d+)',
    overdueDocuments='/documents/overdue',
    frcList = "/frcs",
    frcShow = "/frc/:id(\\d+)?",
}

export const getUploadDocumentRoute = (documentId?: number) => getRouteById('/documents/new', documentId);
export const getDiadocDocumentsRoute = (documentId?: number) => getRouteById('/diadocDocuments', documentId);
export const getSbisDocumentsRoute = (documentId?: number) => getRouteById('/sbisDocuments', documentId);
export const getContractorRoute = (contractorId?: number) => getRouteById('/contractor', contractorId);
export const getValidateDocumentRoute = (documentId: number) => getRouteWithPatternById(Routes.validateDocument, documentId);
export const getShowDocumentRoute = (documentId: number) => getRouteById('/documents', documentId);
export const getCompareDocumentsRoute = (mainDocumentId: number, compareDocumentId: number, canBind: boolean) => getRouteWithPatternById(Routes.compareDocuments, mainDocumentId, 'mainDocumentId') + "/?cId=" + compareDocumentId.toString() + "&canBind=" + canBind.toString();
export const getRequirementRoute = (requirementId?: number) => getRouteById('/requirement', requirementId);
export const getParagraphRoute = (paragraphId: number) => getRouteById('/paragraphDocuments', paragraphId);
export const getParagraphDocumentsRoute = (requirementId: number, paragraphId: number) => getRouteWithPatternById(Routes.paragraphDocuments, requirementId, 'requirementId') + "/?paragraphId=" + paragraphId;
export const getFindDuplicatesRoute = (documentId: number, documentNumber: string, stackId: number, contractorId?: number, documentDate?: string) => {
    let route = getRouteWithPatternById(Routes.findDuplicates, documentId, 'documentId');
    route += "/?dn=" + documentNumber + "&sId=" + stackId.toString();
    route += contractorId === undefined ? "" : "&cId=" + contractorId.toString();
    route += documentDate === undefined ? "" : "&d=" + documentDate;

    return route;
};
export const getShowUserRoute = (id?: number) => getRouteById('/user', id);
export const getShowOrganizationRoute = (id?: number) => getRouteById('/organization', id);
export const getShowFrcRoute = (id?: number) => getRouteById('/frc', id);


const getRouteById = (route: string, id?: number) => {
    return route + (id != null ? '/' + id : '');
}

const getRouteWithPatternById = (route: string, id: number, pattern: string = 'id') => {
    return route.replace(':' + pattern + '(\\d+)', id.toString());
}

export default Routes;