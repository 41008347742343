import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from 'react';
import useDebounce from '../../../../utils/hooks/debounce';
import { capitalizeFirstLetter } from '../../../../controller/entityListController/hooks/common';
import { GetManyApi, getOne, SortingOrder } from '../../../../../api/types/getFunctions';
import { RootApiType } from '../../../../../api/rootApi';
import { Entity } from '../../../../../types/entities/entity';
import { useApi } from "../../../../../api";


const ReferenceEditor: React.FC<ReferenceEditorProps> = ({ value: externalValue, captionField, getCaption, route, fieldForOrder, onChange: onChangeExternal, errorMessage, disabled }) => {
    const {
        open,
        setOpen,
        options,
        value,
        onChange,
        inputValue,
        setInputValue,
        loading,
    } = useReferenceEditor({onChangeExternal, captionField, getCaption, externalValue: externalValue, route, fieldForOrder });

    return <Autocomplete
        sx={{ width: 300 }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option: any, value) => option.title === value.title}
        getOptionLabel={(option: any) => option?.label ? option.label : ""}
        options={options}
        loading={loading}
        value={value}
        onChange={(_, value) => onChange(value)}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        renderInput={(params) => (
            <TextField
                helperText={errorMessage} 
                error={errorMessage != null}
                {...params}
                label={""}
                variant="standard"
                InputProps={{
                ...params.InputProps,
                className:"reference-filter-input",
                endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                ),
                }}
            />
        )}
    />
};


const useReferenceEditor = ({captionField, route, getCaption, fieldForOrder, onChangeExternal, externalValue}: UseReferenceEditorProps) => {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<any[]>([]);
    const [value, setValue] = useState<{[k: string]: any;}>({});
    const [inputValue, setInputValue] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const debInputValue  = useDebounce(inputValue, 275);

    useEffect(() => {
        if (!externalValue)
            return;

        if (value.value === externalValue)
            return;

        (async () =>  {
            if (!(captionField || getCaption))
                throw new Error(`nameFieldForSearch: "${captionField}" and getCaption: "${getCaption}" are required`);

            setLoading(true);

            if (!route)
                throw new Error("Route is not defined for reference filter");

            const client = api[route] as unknown as {getOne: getOne<Entity>};
            if (client.getOne === undefined)
                throw new Error(`Can't load sub entities for "ValueEditor" ${route}, because it doesn't have getOne method in api`);

            const entity = await client.getOne(externalValue);
            if (entity == null)
                throw new Error(`Can't load sub entities with id: ${externalValue}`);

            const option = ({ value: entity.id, label: getCaption ? getCaption(entity) : entity[captionField!]});
            setOptions([option]);
            setValue(option);
            setLoading(false);
        })();
    }, [externalValue]);

    useEffect(() => {
        (async () => {
            if (!open)
                return;
            
            if (!(captionField || getCaption) || !fieldForOrder)
                throw new Error(`nameFieldForSearch: "${captionField}" and fieldForOrder: "${fieldForOrder}" are required`);

            setLoading(true);

            const sort = [{field: capitalizeFirstLetter(fieldForOrder), order: SortingOrder.Asc }];
            if (!route)
                throw new Error("Route is not defined for reference filter");

            const client = api[route] as unknown as GetManyApi<any>;
            if (client.getMany === undefined)
                throw new Error(`Can't load sub entities for "ValueEditor" ${route}, because it doesn't have getMany method in api`);

            const result = await client.getMany(undefined, 100, sort, undefined, undefined, debInputValue);

            setOptions(result.items.map((entity: {[k: string]: any}) => ({ value: entity.id, label: getCaption ? getCaption(entity) : entity[captionField!]})));
        
            setLoading(false);
            
        })();
    },
    [JSON.stringify({debInputValue, captionField, open, getCaption, fieldForOrder, route, api})]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const onChange = (entity?: {[k: string]: any}) => {
        setValue(entity ?? {});
        onChangeExternal(entity?.value);
    }

    return {
        open,
        setOpen,
        options,
        value,
        onChange,
        inputValue,
        setInputValue,
        loading,
    }
}


interface ReferenceEditorProps {
    captionField?: string;
    route?: keyof RootApiType;
    getCaption?: ((entity: {[k: string]: any}) => string);
    fieldForOrder?: string;
    onChange: (id: number) => void;
    value: number;
    errorMessage?: string;
    disabled?: boolean;
}

interface UseReferenceEditorProps {
    captionField?: string;
    route?: keyof RootApiType;
    getCaption?: ((entity: {[k: string]: any}) => string);
    fieldForOrder?: string;
    onChangeExternal: (id: number) => void;
    externalValue: number;
}

export default ReferenceEditor;

export { ReferenceEditorProps };