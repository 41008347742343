import Filter from "../../abstractions/filter";
import Store from "../Store";
import { makeObservable } from 'mobx';
import { Organization } from '../../../types/entities/organization';


export default class OrganizationsStore extends Store<Organization, Filter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}