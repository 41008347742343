import React from "react";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import { UploadDocumentShow, Recognition, 
  PickDocumentFromExternalSource, Contractor, ContractorList, ShowDocument, 
  FindDuplicates, CompareDocuments, RequirementList, RequirementShow, ParagraphDocuments, 
  UploadsStorageListView, ValidationsStorageListView, StorageListView, NoOriginalListView, StaleListView,
  FrcList, FrcShow
} from "../accounting/pages";
import { SignIn, ResetPassword, RedirectToCorrectPage, UserListView, UserShowView, OrganizationShowView, OrganizationListView, ForgotPassword } from "../common/pages";
import Routes from "./routes";
import { UserData } from "./../helpers/userData";
import api from "../api";
import Layout from "../common/components/Layout";

const Router = () => { 
    return(
      <BrowserRouter>
        <Switch>
          <UnauthenticatedRoute path={Routes.index} exact children={<RedirectToCorrectPage />}/>
          <UnauthenticatedRoute path={Routes.signIn} exact children={<SignIn />}/>
          <UnauthenticatedRoute path={Routes.resetPassword} exact children={<ResetPassword />}/>
          <UnauthenticatedRoute path={Routes.forgotPassword} exact children={<ForgotPassword />}/>
          <AuthenticatedRoute path={Routes.uploads} exact children={<UploadsStorageListView />}/>
          <AuthenticatedRoute path={Routes.documentsStorage} exact children={<StorageListView />}/>
          <AuthenticatedRoute path={Routes.uploadDocument} children={<UploadDocumentShow />}/>
          <AuthenticatedRoute path={Routes.recognition} exact children={<Recognition />}/>
          <AuthenticatedRoute path={Routes.pickDocumentFromDiadoc} exact
            children={<PickDocumentFromExternalSource source={api.documents.getDiadocDocuments} name="DiaDoc" />}/>
          <AuthenticatedRoute path={Routes.pickDocumentFromSbis} exact
            children={<PickDocumentFromExternalSource source={api.documents.getSbisDocuments} name="Sbis" />}/>
          <AuthenticatedRoute path={Routes.recognition} exact children={<Recognition />}/>
          <AuthenticatedRoute path={Routes.contractorShow} exact children={<Contractor />}/>
          <AuthenticatedRoute path={Routes.contractorList} exact children={<ContractorList />}/>
          <AuthenticatedRoute path={Routes.documentsOnValidation} exact children={<ValidationsStorageListView />}/>
          <AuthenticatedRoute key="validate-document" path={Routes.validateDocument} exact children={<ShowDocument />}/>
          <AuthenticatedRoute path={Routes.findDuplicates} exact children={<FindDuplicates />}/>
          <AuthenticatedRoute path={Routes.compareDocuments} exact children={<CompareDocuments />}/>
          <AuthenticatedRoute key="show-document" path={Routes.showDocument} exact children={<ShowDocument />}/>
          <AuthenticatedRoute path={Routes.requirements} exact children={<RequirementList />}/>
          <AuthenticatedRoute path={Routes.requirementShow} exact children={<RequirementShow />}/>
          <AuthenticatedRoute path={Routes.paragraphDocuments} exact children={<ParagraphDocuments />}/>
          <AuthenticatedRoute path={Routes.userList} exact children={<UserListView />} />
          <AuthenticatedRoute path={Routes.userShow} exact children={<UserShowView />} />
          <AuthenticatedRoute path={Routes.organizationList} exact children={<OrganizationListView />} />
          <AuthenticatedRoute path={Routes.organizationShow} exact children={<OrganizationShowView />} />
          <AuthenticatedRoute path={Routes.noOriginalDocuments} exact children={<NoOriginalListView />}/>
          <AuthenticatedRoute path={Routes.overdueDocuments} exact children={<StaleListView />}/>
          <AuthenticatedRoute path={Routes.frcList} exact children={<FrcList />}/>
          <AuthenticatedRoute path={Routes.frcShow} exact children={<FrcShow />}/>
          <Redirect from="*" to={Routes.index}/>
        </Switch>
      </BrowserRouter>
    );
}

const AuthenticatedRoute = (props: any) => {
  const { children, ...rest }: { children: JSX.Element } = props;

  return (
    <Layout>
      <Route
        {...rest}
        render={(props) => isAuthorized() ? children : <Redirect to="/signIn" />}
      />
    </Layout>
  );
};

const isAuthorized = () => {
  const token = UserData.accessToken;
  if (token == null)
    return false;

  try {
    const data: { exp: number } = parseJwt(token);
    return Date.now() < data.exp * 1000;
  }
  catch {
    return false;
  }
}

function parseJwt (token: string) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const UnauthenticatedRoute = (props: any) => {
    const { children, ...rest }: { children: JSX.Element } = props;
  
    return (
      <Route
        {...rest}
        render={(props) => children}
      />
    );
};


export default Router;