import { TextField } from "@mui/material";
import { useState } from "react";
import { FilterProps } from ".";
import Filter, { FilterAttributes, FilterType } from "../../../../../abstractions/filter";

const DateFilter = <TFilter extends Filter>({filterKey, attributes, updateFilter}: FilterProps<string, TFilter>) => {
  const { label } = attributes;
  const [value, setValue] = useState<string>("");

  return (
    <TextField
        label={label}
        variant="standard"
        type="date"
        value={value}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => {
          if (!value)
            return;

          setValue("");
          updateFilter(filterKey, value);
        }}
    />
  );
}

export default DateFilter;

const createDateFilter = (label: string, defaultValues: string[] = [], needShowFilter: boolean = true, isStaticFilter: boolean = false, needShowChips: boolean = true): FilterAttributes<string> => ({
  label,
  needShowFilter,
  values: defaultValues,
  isStaticFilter,
  type: FilterType.Date,
  needShowChips
});

export { createDateFilter };