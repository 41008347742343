
export default {
    account: {
        invalidPassword: 'Неправильный логин или пароль.',
        accountLockedOut: 'Превышено число неудачных попыток логина. Необходимо сбросить пароль.',
        accountBlocked: 'Аккаунт заблокирован.',
    },
    validation: {
        valueMustContainOnlyDigits: "Значение может содержать только цифры.",
        invalidTinLength: "ИНН может состоять только из 10 или 12 цифр.",
        invalidSrcLength: "КПП может состоять только из 9 знаков.",
        invalidSrcContent: "КПП может состоять только из цифр или заглавных букв латинского алфавита от A до Z.",
        invalidSrcFormat: "Неправильнай формат КПП.",
        invalidTinCheckDigit: "Неправильная контрольная цифра ИНН.",
        invalidStringLength: "Максимальная длина строки {0} символов.",
        fieldIsRequired: "Это поле обязательно.",
    }
};