import React, { useEffect, useState } from 'react';
import { Button, FormControl, TextField, Select, CircularProgress, Slide, Stack, LinearProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './style.sass'
import api from '../../../api';
import { EditMode } from '../ParagraphsTable';
import { RequirementParagraph } from '../../../types/entities/requirement/requirement';
import { TitledEntity } from '../../../types/titledEntity';
import { AutocompleteEntityPicker } from '../../../common/components/AutocompleteEntityPicker';
import { UserData } from '../../../helpers/userData';
import { User } from '../../../types/entities/user';
import { Document } from '../../../types/entities/document';
import { DataGrid } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { TransitionProps } from '@mui/material/transitions';
import { AttachFile, BungalowTwoTone, Check, ConstructionOutlined, Delete, List, Remove, Close } from '@mui/icons-material';
import EntityListView from '../../../common/view/entityListView';

export interface DocumentsDialogProps {
  open: boolean;
  onClose: () => void;
  
  paragraph: RequirementParagraph | undefined;
}

export default function ShowDocumentsDialog(props: DocumentsDialogProps) {
  const { onClose, open, paragraph } = props;

  const [addDocumentsLoading, setAddDocumentsLoading] = useState<boolean>(false);
  const [removeDocumentsLoading, setRemoveDocumentsLoading] = useState<boolean>(false);
  
  const [documentsParagraph, setDocumentsParagraph] = useState<RequirementParagraph | undefined>();
  
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);

  const [paragraphDocumentsLoading, setParagraphDocumentsLoading] = useState(true);
  const [paragraphDocuments, setParagraphDocuments] = useState<Document[]>([]);
  const [paragraphDocumentsCount, setParagraphDocumentsCount] = useState<number>();

  const [attachedDocuments, attachDocuments] = useState<any[]>([]);
  const [deletedDocuments, deleteDocuments] = useState<any[]>([]);

  const [page, setPage] = useState<number>(0);
  const [documentsPageSize, setDocumentsPageSize] = useState<number>(10);
  const [documentsCount, setDocumentsCount] = useState<number>();

  const [paragraphPage, setParagraphPage] = useState<number>(0);
  const [paragraphDocumentsPageSize, setParagraphDocumentsPageSize] = useState<number>(10);

  useEffect(() => {
    if (open){
        setDocumentsParagraph(paragraph);
    }
  }, [open]);

  useEffect(() => {
      if (open && documentsParagraph){
        (async () => {
            await getDocumentsToAdd(documentsParagraph);
            await getParagraphDocuments(documentsParagraph);
        })();
      }
  }, [documentsParagraph]);

  useEffect(() => {
    (async () => {
      if (documentsParagraph)
        await getDocumentsToAdd(documentsParagraph);
    })();
  }, [page])

  useEffect(() => {
    (async () => {
      if (documentsParagraph)
        await getDocumentsToAdd(documentsParagraph);
    })();
  }, [documentsPageSize])

  useEffect(() => {
    (async () => {
      if (documentsParagraph)
        await getParagraphDocuments(documentsParagraph);
    })();
  }, [paragraphPage])

  useEffect(() => {
    (async () => {
      if (documentsParagraph)
        await getParagraphDocuments(documentsParagraph);
    })();
  }, [paragraphDocumentsPageSize])

  const handleClose = () => {
    setDocumentsParagraph(undefined);
    setDocuments([]);
    setParagraphDocuments([]);
    clearSelectedDocumentsList();

    onClose();
  };

  const clearSelectedDocumentsList = () => {
    attachDocuments([]);
    deleteDocuments([]);
  }

  const getDocumentsToAdd = async (paragraph: RequirementParagraph | undefined) => {
      setDocumentsLoading(true);
    await api.requirementParagraphs.getDocumentsToAdd(paragraph!.id, page, documentsPageSize)
    .then(e => { 
      clearSelectedDocumentsList();
      setDocuments(e.documents);
      setDocumentsCount(e.totalCount);
      setDocumentsLoading(false);
    })
  }

  const getParagraphDocuments = async (paragraph: RequirementParagraph | undefined) => {
    setParagraphDocumentsLoading(true);

    await api.requirementParagraphs.getParagraphDocuments(paragraph!.id, paragraphPage, paragraphDocumentsPageSize)
    .then(e => {
      clearSelectedDocumentsList();
      setParagraphDocuments(e.documents);
      setParagraphDocumentsCount(e.totalCount);
      setParagraphDocumentsLoading(false);
    })
  }

  const addDocuments = async (paragraphId: number, documentIds: any[]) => {
      setAddDocumentsLoading(true);

      await api.requirementParagraphs.addDocuments(paragraphId, documentIds)
      .then(async e => {
          await getDocumentsToAdd(paragraph);
          await getParagraphDocuments(paragraph);

          setAddDocumentsLoading(false);
      })
      .catch(async e => {
          await getDocumentsToAdd(paragraph);
          await getParagraphDocuments(paragraph);

          setAddDocumentsLoading(false);
      })
  }

  const removeDocuments = async (paragraphId: number, documentIds: number[]) => {
    setRemoveDocumentsLoading(true);

      await api.requirementParagraphs.removeDocuments(paragraphId, documentIds)
      .then(async e => {
          await getDocumentsToAdd(paragraph);
          await getParagraphDocuments(paragraph);

          setRemoveDocumentsLoading(false);
      })
  }

  return (
    <Dialog fullScreen
            onClose={handleClose}
            open={open}
            className='documents_dialog'>
        <DialogTitle><div><AttachFile /> Привязка документов / Пункт <b> # {paragraph ? paragraph!.number : ''}</b></div>
        <Close onClick={() => onClose()} /></DialogTitle>
        <div className="dialog_content">
            <div className="table_flex" style={{ width: '100%' }}>
                <div className="documents_block" style={{ width: '100%' }}>
            <div className="action_buttons"><div>Список документов</div>
            
            <Button onClick={() => addDocuments(paragraph!.id, attachedDocuments)}
            className="submit"
            disabled={addDocumentsLoading || removeDocumentsLoading}>
            {addDocumentsLoading ? <div>Подождите <CircularProgress color="warning" size={20} /></div> : <div>Прикрепить <Check /></div>}
            </Button></div>
            <div className="table_flex" style={{ width: '100%' }}>
                <DataGrid className="documents_table"
                  rows={documents}
                  rowsPerPageOptions={[5, 10, 15]}
                  rowCount={documentsCount}
                  page={page}
                  pagination
                  pageSize={documentsPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(pageSize) => setDocumentsPageSize(pageSize)}
                  autoHeight={true}
                  rowHeight={35}
                  disableExtendRowFullWidth={true}
                  checkboxSelection
                  getRowId={(row) => row.id}
                  loading={documentsLoading}
                  components={{
                    LoadingOverlay: () => <LinearProgress />,
                    NoRowsOverlay: () => (<Stack height="100%" alignItems="center" justifyContent="center">
                                            Документы отсутствуют
                                          </Stack>),
                    NoResultsOverlay: () => (<Stack height="100%" alignItems="center" justifyContent="center">
                    Документы отсутствуют
                    </Stack>)
                  }}
                  onSelectionModelChange={(ids) => {
                      const selectedIds = new Set(ids);
                      const selectedRows = documents.filter((row) => selectedIds.has(row.id));
                      
                      var selectedDocuments = new Array();
                      selectedRows.forEach(r => selectedDocuments.push({ Value: r.id }));

                      attachDocuments(selectedDocuments);
                  } } 
                  columns={[
                    { field: 'documentNumber', headerName: 'Номер документа', minWidth: 180 },
                    { field: 'createdAt', headerName: 'Дата создания', flex: 1,
                    renderCell: (params) => <div>{<span>{new Date(params.value).toLocaleDateString()}</span>}</div> }
                    ]}
                  />
            </div>
            </div>

                    <div className="documents_block">
            <div className="action_buttons"><div>Прикрепленные документы</div>

            <Button className="remove" 
            disabled={addDocumentsLoading || removeDocumentsLoading}
            onClick={() => removeDocuments(paragraph!.id, deletedDocuments)}>
            {removeDocumentsLoading ? <div>Подождите <CircularProgress color="warning" size={20} /></div> : <div>Открепить <Delete /></div>}
            </Button></div>

            <div className="table_flex">
                <DataGrid className="documents_table"
                  rows={paragraphDocuments}
                  rowsPerPageOptions={[5, 10, 15]}
                  rowCount={paragraphDocumentsCount}
                  page={paragraphPage}
                  pagination
                  pageSize={paragraphDocumentsPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => setParagraphPage(newPage)}
                  onPageSizeChange={(pageSize) => setParagraphDocumentsPageSize(pageSize)}
                  autoHeight={true}
                  rowHeight={35}
                  disableExtendRowFullWidth={true}
                  checkboxSelection
                  getRowId={(row) => row.id}
                  loading={paragraphDocumentsLoading}
                  components={{
                    
                    NoRowsOverlay: () => (<Stack height="100%" alignItems="center" justifyContent="center">
                                            Документы отсутствуют
                                          </Stack>),
                    LoadingOverlay: () => <LinearProgress />,
                    NoResultsOverlay: () => (<Stack height="100%" alignItems="center" justifyContent="center">
                                            Документы отсутствуют
                                          </Stack>),
                  }}
                  onSelectionModelChange={(ids) => {
                      const selectedIds = new Set(ids);
                      const selectedRows = paragraphDocuments.filter((row) => selectedIds.has(row.id)
                      );

                      var selectedDocuments = new Array();
                      selectedRows.forEach(r => selectedDocuments.push({ Value: r.id }));
                      deleteDocuments(selectedDocuments);
                  } } 
                  columns={[
                    { field: 'documentNumber', headerName: 'Номер документа', minWidth: 170 },
                    { field: 'createdAt', headerName: 'Дата создания', flex: 1,
                    renderCell: (params) => <div>{<span>{new Date(params.value).toLocaleDateString()}</span>}</div>
                    }
                    ]}
                  />
                  </div>
            </div>
            </div>

        <div className="dialog_actions">
            <Button onClick={() => onClose()}>Сохранить</Button>
        </div>
        </div>
    </Dialog>
  );
}
