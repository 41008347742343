import { KeyboardArrowDown as Arrow, KeyboardArrowUp as ArrowUp, Search } from '@mui/icons-material/';
import { Button, FormControl, InputAdornment, OutlinedInput } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Entity } from '../../../types/entities/entity';
import Filter from "../../abstractions/filter";
import { FiltersControllerProps, FiltersControllerReturnType } from "../../controller/filters/filtersController";
import Filters from "./components/filters";
import FiltersChipsView from "./components/filtersChips";
import './style.sass';

const ListFiltersView = <TEntity extends Entity, TFilter extends Filter, TFilterControllerProps extends FiltersControllerProps<TEntity, TFilter>>({ 
  filterControllerProps, 
  useFiltersController, 
  ListActions 
}: EntityFiltersViewProps<TEntity, TFilter, TFilterControllerProps>) => {
  const {
    showFilters,
    updateShowFilters,
    filters,
    updateFilter,
    resetFilter,
    removeFilterValue,
    updateSearch,
    search,
    isFiltersExist,
    customeFiltersComponents,
    showSearch
  } = useFiltersController(filterControllerProps);

  return (
    <div>
      <div className='search action-buttons'>
        <div className='search-flex'>
          {showSearch && 
            <FormControl variant="outlined">
              <OutlinedInput
                value={search}
                placeholder='Найти'
                className='search_input'
                classes={{ input: 'search_input__height' }}
                onChange={(e) => updateSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
          }
          {isFiltersExist() &&
            <Button
              onClick={() => updateShowFilters(!showFilters)}
              color='inherit'
              endIcon={showFilters ?
                <ArrowUp color='primary' /> :
                <Arrow color='primary' />
              }
            >
              {showFilters ? 'Скрыть фильтры' : 'Показать фильтры'}
            </Button>
          }
        </div>
        <div className='action-buttons list_action-buttons'>
          {ListActions}
        </div>
      </div>
      {showFilters &&
        <Filters
          filters={filters}
          updateFilter={updateFilter}
          customeFiltersComponents={customeFiltersComponents}
        />
      }
      <FiltersChipsView<TFilter>
        filters={filters}
        resetFilter={resetFilter}
        removeFilterValue={removeFilterValue}
      />
    </div>
  );
}

export default observer(ListFiltersView);

interface EntityFiltersViewProps<TEntity extends Entity, TFilter extends Filter, TFilterControllerProps extends FiltersControllerProps<TEntity, TFilter>> {
  filterControllerProps: TFilterControllerProps;
  useFiltersController: (props: TFilterControllerProps) => FiltersControllerReturnType<TFilter>;
  ListActions?: JSX.Element;
}