import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, FormControl, TextField, Fab, CircularProgress } from '@mui/material';
import { Download, Check, Close } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import './style.sass'
import api from '../../../api';

export enum DownloadState {
  OnReady = "onReady",
  Downloading = "downloading",
  DownloadSuccess = "downloadSuccess",
  DownloadError = "downloadError"
}

export interface DownloadDocumentsDialogProps {
  requirementId: number;
  paragraphIds: number[];
  open: boolean;
  onClose: () => void;
}

function ShowDownloadDocumentsDialog(props: DownloadDocumentsDialogProps) {
  const { onClose, open } = props;
  const [title, setTitle] = useState<string>('Выгрузить пакет');
  const [name, setName] = React.useState('');
  const [directory, setDirectory] = React.useState("");
  const [maxFilesCount, setMaxFilesCount] = React.useState<number>(10);
  const [maxFileSize, setMaxFileSize] = React.useState<number>(18);

  const [isFolderNameValid, setFolderNameValid] = React.useState(false);
  const [folderNameError, setFolderNameError] = React.useState('');
  
  const [downloadState, setDownloadState] = useState<DownloadState>(DownloadState.OnReady);
  const [progress, setProgress] = React.useState<number>(0);

  const handleClose = () => {
    onClose();

    setDownloadState(DownloadState.OnReady);
    setTitle('Выгрузить пакет');
    setProgress(0);
  };

  const handleNameChanged = (name: string) => {
    setName(name);
    validateFolderName(name);
    console.log(isFolderNameValid);
  }

  const handleDirectoryChanged = (directory: string) => {
    setDirectory(directory);
  }

  const handleMaxFilesCountChanged = (maxFilesCount: number) => {
    setMaxFilesCount(maxFilesCount);
  }

  const handleMaxFileSizeChanged = (maxFilesSize: number) => {
    setMaxFileSize(maxFilesSize);
  }

  const handleSetDownloadState = () => {
    setDownloadState(DownloadState.Downloading);
    setTitle('');
    handleDownload();
  };

  const downloadDocuments = async (requirementId: number, paragraphIds: number[],
    name: string, directory: string, maxFileSize: number, maxFilesCount: number) => {
    handleSetDownloadState();

    await api.requirements.downloadDocuments(requirementId, paragraphIds, name, directory, maxFileSize, maxFilesCount)
      .then((e) => setDownloadState(DownloadState.DownloadSuccess))
  }

  useEffect(() => {
    if (downloadState == DownloadState.OnReady){
      setProgress(0);
    }
    else if (downloadState == DownloadState.Downloading) {
      var downloadProgress = 0;
      var timer = setInterval(() => {
        downloadProgress += 10;
        setProgress((progress) => (progress + 10));

        if (downloadProgress >= 100){
          clearInterval(timer);
        }
      }, 800);

    return () => clearInterval(timer);
    }
    }, [downloadState]
    );

  const handleDownload = () => {
    
  }

  const validateFolderName = (folderName: string) => {
    var re = /[<>:"\/\\|?*\x00-\x1F]|^(?:aux|con|clock\$|nul|prn|com[1-9]|lpt[1-9])$/i;;
    return setFolderNameError(re.test(folderName)
    ? 'Название папки не должно содержать следующих знаков \ / : * ? " < > |' : '');
  }

  return (
    <Dialog onClose={handleClose} open={open}>
        <DialogTitle className="dialog-title">{title} <Close onClick={handleClose} /></DialogTitle>
        <div className="dialog_content">
        {
          downloadState == DownloadState.Downloading &&
          <div className="downloading_content">
            <div className='flex_content'>
            <CircularProgress size={100} color="info" />
            </div>
            <div className='flex_content labels'>
              <div className='block_content'>
                <p className='downloading_title'>Производится выгрузка</p>
                <div className='flex_content'>Пожалуйста, подождите</div>
                <div className='flex_content downloaded'></div>
                <div className='flex_content'><Button color='secondary'>Отмена</Button></div>
              </div>
            </div>
          </div>
        }
        {
          downloadState == DownloadState.DownloadSuccess &&
          <div>
            <div className='download_result'>
              <div className='flex_content'>
          <div className="circle-icon">
          <Check />
          </div>
          </div>
          <div className='flex_content'><b>Пакет документов сформирован</b></div>
          <div className='flex_content text'>В папке {directory} сформирован 
          пакет документов для отправки в налоговую</div>
          <div className='flex_content'><Button variant="contained">Открыть папку</Button></div>
          </div>
        </div>
        }
          
        {
          downloadState == DownloadState.OnReady &&
        <div className='download_content'>
          <div className="common_select_container">
            <p>Место:</p>
            <FormControl variant="outlined">
                <TextField placeholder="Путь" value={directory} onChange={(e) => handleDirectoryChanged(e.target.value)} className='dialog_input'></TextField>
            </FormControl>
          </div>
          <div className="common_select_container">
            <p>Сохранить как:</p>
            <FormControl variant="outlined">
                <TextField placeholder="Название" value={name} 
                onChange={(e) => { handleNameChanged(e.target.value); }} className='dialog_input'></TextField>
            </FormControl>
          </div>
          <div className="common_select_container error-message">
            <label className="red-label">{folderNameError}</label>
          </div>

          <div className='dialog_document_properties'>
          <div className="common_select_container">
            <p>Кол-во док-тов:</p>
            <FormControl variant="outlined">
                <TextField type="number" value={maxFilesCount} onChange={(e) => handleMaxFilesCountChanged(parseInt(e.target.value))} className='dialog_input'></TextField>
            </FormControl>
          </div> 
          <div className="common_select_container">
            <p>Размер (МБ):</p>
            <FormControl variant="outlined">
                <TextField type="number" value={maxFileSize} onChange={(e) => handleMaxFileSizeChanged(parseFloat(e.target.value))} className='dialog_input'></TextField>
            </FormControl>
          </div>
          </div>
          <div className="dialog_actions">
            <Button color="secondary">Отмена</Button>
            <Button variant="contained" disabled={!directory || !maxFileSize || !maxFilesCount || folderNameError != ''}
              onClick={() => downloadDocuments(props.requirementId, props.paragraphIds, name, directory, maxFileSize, maxFilesCount)}>Выгрузить</Button>
          </div>
        </div>
        }
        </div>
    </Dialog>
  );
}

export interface DialogProps {
  fullPackage: boolean;
  requirementId: number;
  paragraphIds?: number[];
}

export default function DownloadDocumentsDialog(props: DialogProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {
        props.fullPackage &&
      <Button onClick={handleClickOpen} variant="contained" >
        Выгрузить пакет
      </Button>
      }
      {
        !props.fullPackage &&
        <Button 
          onClick={handleClickOpen}
          color='secondary'
          startIcon={<Download />}
        >
          Выгрузить выделенные
        </Button>
      }
      <ShowDownloadDocumentsDialog
        open={open}
        requirementId={props.requirementId}
        paragraphIds={props.paragraphIds!}
        onClose={handleClose}
      />
    </div>
  );
}