import React, { useState } from 'react';
import { throttle } from 'throttle-debounce';
import { Autocomplete, TextField } from "@mui/material";
import { TitledEntity } from '../../../types/titledEntity';
import './style.sass';

interface IAutocompleteEntityPickerProps {
    value?: TitledEntity;
    entitySource: (text: string) => Promise<TitledEntity[]>;
    onPicked: (entity: TitledEntity) => void;
    allowEmpty?: boolean;
    label?: string;
    className?: string;
    disabled?: boolean;
};

export const AutocompleteEntityPicker = function ({ value, entitySource, onPicked, label, className, disabled, allowEmpty }:
    IAutocompleteEntityPickerProps) {

    const [inputValue, setInputValue] = useState('');
    const defaultValue = { id: 0, title: inputValue };
    const [options, setOptions] = useState<TitledEntity[]>([defaultValue]);
    const innerValue = value ?? defaultValue;    

    React.useEffect(() => {
        if (value != null) {
            setOptions([value]);
            setInputValue(value.title);
        } else
            setInputValue('');
    }, [value]);

    const updateOptions = React.useMemo(
        () =>
            throttle(200, async (filter: string) => {
                setOptions(await entitySource(filter));
            }),
        [entitySource],
    );

    const onInputChange = async (text: string) => {
        setInputValue(text);
        updateOptions(text);
    }

    const onValueChange = (value: TitledEntity) => {
        onPicked(value);
    }

    return <div id='autocomplete'>
        <Autocomplete
            disabled={disabled}
            disableClearable={!allowEmpty}
            filterSelectedOptions
            filterOptions={x => x}
            className={className}
            options={options}
            value={innerValue}
            onInputChange={(_, value) => onInputChange(value)}
            onChange={(_, newValue) => onValueChange(newValue!)}
            onFocusCapture={() => onInputChange(inputValue)}
            getOptionLabel={e => e.title}
            inputValue={inputValue}
            isOptionEqualToValue={(o, v) => o.id == v.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                />
            )}
        />
    </div>;
}

AutocompleteEntityPicker.defaultProps = { allowEmpty: false };
