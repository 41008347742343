import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import { Fragment, useEffect, useMemo, useState } from 'react';
import useDebounce from '../../../../utils/hooks/debounce';
import { capitalizeFirstLetter } from '../../../../controller/entityListController/hooks/common';
import { GetManyApi, SortingOrder } from '../../../../../api/types/getFunctions';
import { RootApiType } from '../../../../../api/rootApi';
import { Entity } from '../../../../../types/entities/entity';
import { useApi } from "../../../../../api";


const ManyReferenceEditor: React.FC<ManyReferenceEditorProps> = ({ value: externalValue, captionField, getCaption, route, fieldForOrder, onChange: onChangeExternal, errorMessage, disabled}) => {
    const {
        open,
        setOpen,
        options,
        value,
        onChange,
        inputValue,
        setInputValue,
        loading,
    } = useManyReferenceEditor({ onChangeExternal, captionField, getCaption, externalValue: externalValue, route, fieldForOrder });

    return <Autocomplete
        multiple={true}
        sx={{ width: 300 }}
        size="small"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option: any, value) => option === value}
        getOptionLabel={(option: any) => option ? option : ""}
        options={options}
        loading={loading}
        value={value}
        onChange={(_, value) => onChange(value)}
        disabled={disabled}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                onDelete={undefined}
              />
            ))
        }
        renderInput={(params) => (
            <TextField
                helperText={errorMessage} 
                error={errorMessage != null}
                {...params}
                label={""}
                variant="standard"
                InputProps={{
                ...params.InputProps,
                className:"reference-filter-input",
                endAdornment: (
                    <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                    </Fragment>
                ),
                }}
            />
        )}
    />
};


const useManyReferenceEditor = ({captionField, route, getCaption, fieldForOrder, onChangeExternal, externalValue}: UseManyReferenceEditorProps) => {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<Record<string, any>[]>([]);
    const [value, setValue] = useState<Record<string, any>[]>([]);
    const [helpValue, setHelpValue] = useState<Record<string, any>[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const debInputValue = useDebounce(inputValue, 275);

    useEffect(() => {
        if (!externalValue || externalValue.length === 0)
            return;

        if (value.map(i => i.value) === externalValue)
            return;

        (async () =>  {
            if (!(captionField || getCaption) || !fieldForOrder)
                throw new Error(`nameFieldForSearch: "${captionField}" and getCaption: "${getCaption}" are required`);

            setLoading(true);

            if (!route)
                throw new Error("Route is not defined for reference filter");

            const client = api[route] as unknown as GetManyApi<Entity>;
            if (client.getMany === undefined)
                throw new Error(`Can't load sub entities for "ValueEditor" ${route}, because it doesn't have getOne method in api`);
            
            const sort = [{field: capitalizeFirstLetter(fieldForOrder), order: SortingOrder.Asc }];

            const result = await client.getMany(undefined, 50, sort, externalValue);
            if (result == null)
                throw new Error(`Can't load sub entities with ids: ${JSON.stringify(externalValue)}`);

            const options = result.items.map((entity: {[k: string]: any}) => ({ value: entity.id, label: getCaption ? getCaption(entity) : entity[captionField!]}));
            setOptions(options);
            setValue(options);
            setLoading(false);
        })();
    }, [externalValue]);

    useEffect(() => {
        (async () => {
            if (!open)
                return;
            
            if (!(captionField || getCaption) || !fieldForOrder)
                throw new Error(`nameFieldForSearch: "${captionField}" and fieldForOrder: "${fieldForOrder}" are required`);

            setLoading(true);

            if (!route)
                throw new Error("Route is not defined for reference filter");

            const client = api[route] as unknown as GetManyApi<any>;
            if (client.getMany === undefined)
                throw new Error(`Can't load sub entities for "ValueEditor" ${route}, because it doesn't have getMany method in api`);
                
            const sort = [{field: capitalizeFirstLetter(fieldForOrder), order: SortingOrder.Asc }];
            const result = await client.getMany(undefined, 100, sort, undefined, undefined, debInputValue);

            setOptions(result.items.map((entity: Record<string, any>) => ({ value: entity.id, label: getCaption ? getCaption(entity) : entity[captionField!]})));
        
            setLoading(false);
            
        })();
    },
    [debInputValue, captionField, open, getCaption, fieldForOrder, route]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const onChange = (entities?: Record<string, any>[]) => {
        if (!entities)
            return;
        
        setHelpValue(prev => entities.map(e => options.find(o => o.label === e) ?? prev.find(o => o.label === e)!));
        const mappedValues = entities.map(e => (options.find(o => o.label === e) ?? helpValue.find(o => o.label === e))!.value);

        setValue(mappedValues);
        onChangeExternal(mappedValues);
    }

    const values = useMemo(() => value.map(v => v.label), [value]);
    const optionsForDisplay = useMemo(() => options.map(o => o.label), [options]);

    return {
        open,
        setOpen,
        options: optionsForDisplay,
        value: values,
        onChange,
        inputValue,
        setInputValue,
        loading,
    }
}

interface ManyReferenceEditorProps {
    captionField?: string;
    route?: keyof RootApiType;
    getCaption?: ((entity: {[k: string]: any}) => string);
    fieldForOrder?: string;
    onChange: (id: number[]) => void;
    value: number[];
    errorMessage?: string;
    disabled?: boolean;
}

interface UseManyReferenceEditorProps {
    captionField?: string;
    route?: keyof RootApiType;
    getCaption?: ((entity: {[k: string]: any}) => string);
    fieldForOrder?: string;
    onChangeExternal: (id: number[]) => void;
    externalValue: number[];
}

export default ManyReferenceEditor;

export { ManyReferenceEditorProps };