import { observer } from "mobx-react-lite";
import { useCallback } from 'react';
import api from "../../../../api";
import { MakeArrayFilters } from "../../../../helpers/utils";
import { Sorting } from "../../../../api/types/getFunctions";
import Filter from "../../../../common/abstractions/filter";
import EntityListWithFilters from "../../../../common/pages/blanks/List";
import { createDateTimeColumn, createReferenceColumn, createTextColumn } from "../../../../common/view/entityListView/columnsFactory";
import { ListControllerProps, ListControllerReturnType, useListController } from "../../../../common/controller/entityListController/entityListController";
import { GridRowParams } from "@mui/x-data-grid";
import { createDateFilter } from "../../../../common/view/listFiltersView/components/filters/components/dateFilter";
import { createNumberFilter } from "../../../../common/view/listFiltersView/components/filters/components/numberFilter";
import './style.sass';
import RequirementEntity from "../../../../accounting/domain/requirements/requirementEntity";
import RequirementFilter from "../../../../accounting/domain/requirements/requirementFilter";
import { formatDate } from "../../../../helpers/formatters";
import { FiltersControllerProps } from "../../../../common/controller/filters/filtersController";
import { Requirement } from "../../../../types/entities/requirement/requirement";

interface RequirementProps {
    setRequirement: (requirement: Requirement) => void;
    attachedDocumentId: number;
}

const RequirementsListView = (props: RequirementProps) => {
    const { setRequirement, attachedDocumentId } = props;
    
    const getMany = async (skip?: number, take?: number, sortings?: Sorting[], ids?: number[], filter?: MakeArrayFilters<Filter>, search?: string) =>
        await api.requirements.getMany(skip, take, sortings, ids, { attachedDocumentId: [attachedDocumentId], ...filter }, search);

    return (
        <div>
            <EntityListWithFilters<RequirementEntity, RequirementFilter, RequirementListProps, RequirementFilterProps>
                listControllerProps={{
                    loadEntities: getMany,
                    columns: [
                        createTextColumn<RequirementEntity>('number', 'Номер', true),
                        createDateTimeColumn<RequirementEntity>('requirementDate', 'Дата', true,
                            (params) => <div>{formatDate(params.value)}</div>),
                        createReferenceColumn(createTextColumn<RequirementEntity>('name', 'Организация'), 
                            'organizationId', 'organizations'),
                        createReferenceColumn(createTextColumn<RequirementEntity>('name', 'Проверяемый'), 
                            'auditorId', 'contractors'),
                        createTextColumn<RequirementEntity>('taxOfficeNumber', 'ИФНС', true),
                        createTextColumn<RequirementEntity>('period', 'Период', true),
                        { field: ' ', sortable: false, filterable: false, flex: 1, align: 'right' }
                    ],
                    setRequirement
                }}
                storeName='requirementsStore'
                filterAttributes={filterAttributes}
                useListController={useValidationListController}
            />
        </div>
    );
};

const useValidationListController = (props: RequirementListProps): ListControllerReturnType<RequirementEntity> => {
    const listProps = useListController(props);
    const { setRequirement } = props;

    const onRowClick = useCallback((props: GridRowParams<RequirementEntity>) => {
        const requirement = props.row;
        setRequirement(requirement);
    }, [setRequirement]);

    return {
        ...listProps,
        onRowClick: onRowClick,
    } as ListControllerReturnType<RequirementEntity>;
}

type RequirementFilterProps = FiltersControllerProps<RequirementEntity, RequirementFilter> & {
    
}

type RequirementListProps = ListControllerProps<RequirementEntity, RequirementFilter> & {
    setRequirement: (requirement: Requirement) => void;
}

const filterAttributes: RequirementFilter = {
    number: createNumberFilter("Номер"),
    requirementDate: createDateFilter("Дата требования"),
    taxOfficeNumber: createNumberFilter("ИФНС"),
    period: createNumberFilter("Период")
};

const RequirementTable = observer(RequirementsListView);

export default RequirementTable;

