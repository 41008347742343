import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Entity } from "../../../types/entities/entity";
import { EntityField } from "../../abstractions/showEntity";
import ShowService from "../../abstractions/showService";
import ValueEditor from "../../components/ValueEditor";
import { ShowControllerReturnType, useShowController as useDefaultShowController } from "../../controller/entityShowController/entityShowController";
import { ShowServiceProps, useShowService as useDefaultShowService } from "../../services/showService";
import './style.sass';

interface EntityShowViewProps<TEntity extends Entity> {
  useShowService?: (props: ShowServiceProps<TEntity>) => ShowService<TEntity>,
  showServiceProps: ShowServiceProps<TEntity>,
  fields: EntityField<TEntity>[],
  useShowController?: (service: ShowService<TEntity>, entityFields: EntityField<TEntity>[]) => ShowControllerReturnType<TEntity>,
}

const EntityShowView = <TEntity extends Entity>({ useShowService = useDefaultShowService, showServiceProps, fields, useShowController = useDefaultShowController }: EntityShowViewProps<TEntity>) => {
  const { 
    errors,
    entity,
    canSave,
    onSave,
    goToList,
    onEntityChange,
    onBlur,
  } = useShowController(useShowService(showServiceProps), fields);

  return (
    <div>
      <div className="entityShow_buttons action-buttons">
        <Button
          color='inherit'
          startIcon={<ArrowBack />}
          onClick={goToList}
        >
          Назад
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={!canSave}
          onClick={onSave}
        >
          Сохранить
        </Button>
      </div>
      <div className="entityShow_input_container">
        {fields.map((k, i) =>
          <div className="entityShow_input" key={i}>
            <div className="entityShow_input__label">
              {k.label}
            </div>
            <div className="entityShow_input__valueEditor">
              <ValueEditor
                //todo: refactor this
                errorMessage={errors.find(e => e.source === k.source)?.errorMessage}
                dataValue={{
                  dataType: k.type, 
                  value: entity ? entity[k.source] : undefined, 
                  captionField: k.captionField, 
                  fieldForOrder: k.fieldForOrder, 
                  getCaption: k.getCaption, 
                  route: k.route,
                  dictionary: k.dictionary,
                }}
                onChange={(e: any) => onEntityChange(k.source, e)}
                onBlur={() => onBlur()}
                disabled={k.disabled}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(EntityShowView);

export { EntityShowViewProps };
