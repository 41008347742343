import { RootApiType } from './../../api/rootApi';
import Filter, { FilterType } from "../abstractions/filter";
import Store from '../infrastructure/Store';
import { Entity } from "../../types/entities/entity";
import { GetManyApi } from "../../api/types/getFunctions";

const filterService = <TEntity extends Entity, TFilter extends Filter> (store: Store<TEntity, TFilter>, api: RootApiType) : FilterService<TFilter> => {  
  const loadReferenceChips = async () => {
    const referenceFilters = Object.entries(store.filters).filter(f => f[1].type === FilterType.Reference);

    // loads sub entities and adds custom value presenter for filter chips
    await Promise.all(referenceFilters.map(async filter => {
      const filterAttributes = filter[1];
      if (!filterAttributes.captionField || !filterAttributes.route)
        return;

      //todo: extract base api interface for routes that can load sub entities
      const client = api[filterAttributes.route] as unknown as GetManyApi<TEntity>;
      if (client.getMany === undefined) {
        throw new Error(`Can't load sub entities for filter ${filterAttributes.route}, because it doesn't have getMany method in api`);
      }

      const result = await client.getMany(undefined, undefined, undefined, filterAttributes.values);

      filterAttributes.valuePresent = (value: any) => {
        const item = result.items?.find(i => i.id === value) as any;
        if (!item || !filterAttributes.captionField)
          return value;
          
        return item[filterAttributes.captionField];
      }
    }));
  }


  return {
    showFilters: store.showFilters,
    filters: store.filters,
    search: store.search,

    updateShowFilters: store.updateShowFilters,
    updateFilter: store.updateFilter,
    resetFilter: store.resetFilter,
    removeFilterValue: store.removeFilterValue,
    
    updateSearch: store.updateSearch,
  
    updateFilters: store.updateFilters,

    loadReferenceChips
  }
}

interface FilterService<TFilter extends Filter> extends FilterServiceBase<TFilter>, ListServiceWithSearch {
  loadReferenceChips(): Promise<void>;
}

interface FilterServiceBase<TFilter extends Filter> { 
  showFilters: boolean;
  filters: TFilter,
  
  updateShowFilters(showFilters: boolean): void;
  updateFilter(key: keyof TFilter, value: any, customUpdate?: (filter: Filter, key: keyof TFilter, value: any) => void): void;
  resetFilter(key: keyof TFilter): void;
  removeFilterValue: (key: keyof TFilter, index: number) => void;

  updateFilters(filters: TFilter): void;
}


interface ListServiceWithSearch { 
  search: string | undefined;

  updateSearch: (search: string) => void;
}


export { filterService, FilterService, FilterServiceBase, ListServiceWithSearch };
