import { AlertColor } from "@mui/material";
import { makeAutoObservable } from "mobx";
import { createContext, useContext } from "react";

export class NotificationStore {
    private _text: string = '';
    private _isOpen = false;
    private _hideDuration = 5000;
    private _severity: AlertColor = 'success';

    constructor() {
        makeAutoObservable(this);
    }

    public get Text() {
      return this._text;
    }

    public get IsOpen() {
      return this._isOpen;
    }

    public get HideDuration() {
      return this._hideDuration;
    }

    public get Severity() {
      return this._severity;
    }

    //todo: make it private
    public setIsOpenAndText(isOpen: boolean, text: string, hideDuration: number = 5000, severity: AlertColor = 'success') {
      this.Text = text;
      this.IsOpen = isOpen;
      this._severity = severity;
      if (hideDuration)
        this.HideDuration = hideDuration;
    }

    public set Text(text: string) {
      this._text = text;
    }

    public set IsOpen(isOpen: boolean) {
      this._isOpen = isOpen;
    }

    public set HideDuration(hideDuration: number) {
      this._hideDuration = hideDuration;
    }

    public showError(text: string) {
      this.setIsOpenAndText(true, text, 7000, 'error');
    }

    public showSuccess(text: string) {
      this.setIsOpenAndText(true, text, 5000);
    }
}

const NotificationStoreContext = createContext(new NotificationStore());

const useNotificationStore = () => {
  const notificationStore = useContext(NotificationStoreContext);

  return notificationStore;
}

export default useNotificationStore;