import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FilterProps } from ".";
import Filter, { FilterAttributes, FilterType } from "../../../../../abstractions/filter";

const BooleanFilter = <TFilter extends Filter>({filterKey, attributes, updateFilter}: FilterProps<Boolean, TFilter>) => {
  const { label } = attributes;

  return (
    <FormControl variant="standard" sx={{ mr: 2, minWidth: 120 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        variant="standard"
        value={""}
        onChange={(e) => {
          const value = e.target.value;
          if (!value)
            return;
          
          updateFilter(filterKey, value === "true");
        }}
        displayEmpty={true}
        sx={{minHeight: "47px"}}
      >
        <MenuItem value="false">Нет</MenuItem>
        <MenuItem value="true">Да</MenuItem>
      </Select>
      </FormControl>
  );
}

export default BooleanFilter;

const createBoolFilter = (label?: string, defaultValues: boolean[] = [], needShowFilter: boolean = true, isStaticFilter: boolean = false, needShowChips: boolean = true): FilterAttributes<boolean> => ({
  label,
  needShowFilter,
  values: defaultValues,
  isStaticFilter,
  type: FilterType.Boolean,
  needShowChips
});

export { createBoolFilter };