import { DataType } from '../dataType';
import { Entity } from './entity';

export type Document = Entity & {
    organizationName: string;
    createdAt: Date;
    documentDate: Date;
    documentNumber: string;
    typeId: number;
    contractorId?: number;
    contractId?: number;
    additionalAgreementId?: number;
    onValidation: boolean;
    validated: boolean;
};

export type DocumentAttribute = Entity & {
    name: string;
    dataType: DataType;
    kind: DocumentAttributeKind;
    required: boolean;
}

export type DocumentAttributeValue = DocumentAttribute & {    
    value?: unknown;
};

export enum DocumentStatus {
    Draft,
    Rejected,
    Copy,
    WorkCopy,
    Original,
};

export const DocumentStatusCaptions = [
    { value: DocumentStatus.Draft, caption: 'Черновик' },
    { value: DocumentStatus.Rejected, caption: 'Отклонён' },
    { value: DocumentStatus.Copy, caption: 'Копия' },
    { value: DocumentStatus.WorkCopy, caption: 'Рабочая копия' },
    { value: DocumentStatus.Original, caption: 'Оригинал' },
];

export const documentStatusCaption = (status: DocumentStatus) => DocumentStatusCaptions.find(c => c.value === status)!.caption;

export enum DocumentSide {
    Customer,
    Supplier,
};

export enum DocumentAttributeDataType {
    String,
    Integer,
    Decimal,
    Boolean,
    DateTime,
};

export enum DocumentAttributeKind {
    Custom,
    DocumentNumber,
    DocumentDate,
    CurrencySum,
    ContractorName,
    ContractorTin,
    ContractorSrc,
    ContractorAddress,
    ContractorStampAvailability,
    ContractorSignatureAvailability,
    CustomerName,
    CustomerTin,
    CustomerSrc,
    CustomerAddress,
    CustomerStampAvailability,
    CustomerSignatureAvailability,
    SignatureAvailability,
    StampAvailability,
    Text,
};