import Api from "./api";
import { SucceededResult } from "./types";
import { SignInOutput } from "./types/signInOutput";

export type AccountApiType = {
    signIn: (userName: string, password: string) => Promise<SignInOutput>;
    
    resetPassword: (userName: string, token: string, newPassword: string) => Promise<SucceededResult>;
    forgotPassword: (userName: string) => Promise<SucceededResult>;
};

export class AccountApi extends Api implements AccountApiType {
    constructor(baseApiPath: string, userUnauthorizedEventHandler: () => void) {
        super(Api.combineUrls(baseApiPath, 'account'), userUnauthorizedEventHandler);
    }

    public signIn = (userName: string, password: string) => 
        this.post<SignInOutput>('signIn', { userName, password });

        
    public async resetPassword(userName: string, token: string, newPassword: string) {
        return await this.post<SucceededResult>('resetPassword', { userName, token, newPassword });
    }

    public async forgotPassword(email: string) {
        return await this.post<SucceededResult>('forgotPassword', { email });
    }
}
