import { makeAutoObservable } from "mobx";
import { Pagination } from "../types/pagination";
import { Requirement } from "../types/entities/requirement/requirement";

export class RequirementStore {
    private _Requirements: Requirement[];
    private _pagination: Pagination;
    private _totalCount: number;
    private _currentRequirement?: number;

    constructor() {
        makeAutoObservable(this);
        this._Requirements = new Array<Requirement>();
        this._pagination = { page: 0, perPage: 10 };
        this._totalCount = 0;
    }

    public get Requirements () {
        return this._Requirements;
    }

    public set Requirements (newRequirements: Requirement[]) {
        this._Requirements = newRequirements;
    }

    public get pagination () {
        return this._pagination;
    }

    public set pagination (newPagination: Pagination) {
        this._pagination = newPagination; 
    }

    public get totalCount () {
        return this._totalCount;
    }

    public set totalCount (newTotalCount: number) {
        this._totalCount = newTotalCount;
    }
    
    public get currentRequirement () {
        return this._currentRequirement;
    }

    public set currentRequirement (id: number | undefined) {
        this._currentRequirement = id;
    }
}