import React, { PropsWithChildren } from 'react';
import { HighlightOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './style.sass'

interface ICustomChipProps {
    onDelete?: () => void
}

export const CustomChip: React.FC<PropsWithChildren<ICustomChipProps>> = (props) => {
    return (
        <div className='customChip'>
            <div>
                {props.children}
            </div>
            {props.onDelete &&
                <IconButton
                    size='small'
                    onClick={props.onDelete}
                >
                    <HighlightOff
                        color='secondary'
                    />
                </IconButton>
            }
        </div>
    )
}