import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import ExternalDocumentEntity from "../../domain/externalDocuments/externalDocumentEntity";
import ExternalDocumentFilter from "../../domain/externalDocuments/externalDocumentFilter";

export default class ExternalDocumentsStore extends Store<ExternalDocumentEntity, ExternalDocumentFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}