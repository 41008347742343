import { makeAutoObservable } from "mobx";
import { Organization } from "../types/entities/organization";

export class OrganizationStore {
    private _organizations: Organization[];

    constructor() {
        this._organizations = [];
        makeAutoObservable(this);
    }

    public get organizations() {
        return this._organizations;
    }

    public set organizations(newOrganizations: Organization[]) {
        this._organizations = newOrganizations;
    }
}