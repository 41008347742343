import { makeObservable } from "mobx";
import Store from "../../../common/infrastructure/Store";
import { Requirement } from "../../../types/entities/requirement/requirement";
import RequirementFilter from "../../domain/requirements/requirementFilter";

export default class RequirementsStore extends Store<Requirement, RequirementFilter> {
  constructor(gridId: string) {
    super(gridId);
    makeObservable(this, {});
  }
}