import React, { useEffect, useState } from 'react';
import {
    Button,
    FormControl,
    MenuItem, Checkbox, Link,
    Select, Menu, ListItemText,
    FormLabel, RadioGroup, FormControlLabel, Radio,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TableContainer, Table, TableBody, TableRow, TableCell, Paper, TableHead
} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from "react-router-dom";
import AttributesEditor from '../../../../common/components/AttributesEditor';
import NotificationModal from '../../../../common/components/NotificationDialog';
import { AutocompleteEntityPicker } from '../../../../common/components/AutocompleteEntityPicker';
import { formatDate, getDocumentCaption } from '../../../../helpers/formatters';
import { useApi } from '../../../../api';
import Routes, { getShowDocumentRoute } from '../../../../router/routes';
import { Document, DocumentAttributeKind, DocumentAttributeValue, DocumentSide, DocumentStatus, DocumentStatusCaptions } from '../../../../types/entities/document';
import { AccountingDocumentTypes } from '../../../../types/entities/documentType';
import { TitledEntity } from '../../../../types/titledEntity';
import DocumentStackGrid, { useDocumentGrid } from './compontents/DocumentStackGrid';
import './style.sass';
import '../../../../styling/common/index.sass';
import { EditDocumentRequirementViewModel, EditDocumentViewModel } from '../../../../api/types/editDocumentViewModel';
import useGoToPageProvider from '../../../../router/goToPageProvider';
import { UserData } from '../../../../helpers/userData';
import { RequisitesToCompare } from '../../../../api/types/compareRequisitesModel';
import AddDocumentsDialog from '../../../../components/Requirements/AddDocumentDialog';
import { FileLabelWithName } from '../../../../common/components/FileLabelWithName';
import { ChangesModal } from './compontents/ChangesModal';

let continueEditing: (() => Promise<void>) | null = null;

const setContinueEditing = (callback: typeof continueEditing) => {
    continueEditing = callback;
}

const DocumentView: React.FC = () => {
    const api = useApi();
    const { id: documentIdStr } = useParams<{ id?: string }>();
    const documentId = Number(documentIdStr);
    const [validate, setValidate] = useState(false);
    const readonly = !validate;

    const history = useHistory();
    const [viewModel, setViewModel] = useState<EditDocumentViewModel>();

    const [attributeAnchorEl, setAttributeAnchorEl] = useState<null | HTMLElement>(null);
    const [addAttributeDialogOpen, setAddAttributeDialogOpen] = useState(false);
    const [errorText, setErrorText] = useState<string>();
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [changeResponsibleModalOpen, setChangeResponsibleModalOpen] = useState(false);
    const [compareRequisitesModalOpen, setCompareRequisitesModalOpen] = useState(false);
    const [changesModalOpen, setChangesModalOpen] = useState(false);
    const [dublicatesFoundModalOpen, setDublicatesFoundModalOpen] = useState(false);

    const { attributes, dateUploaded, documentsTypes, fileName, typeId,
        organizations, status, organizationId, uploadedBy, additionalAgreement,
        contract, contractor, dateValidated, frcId, validatedBy, documentNumber,
        side, financialResponsibilityCenters, stackId, stackDocuments,
        requirements, availableRequirements, isMainInStack, mainStackDocumentId } = viewModel ?? { 
            attributes: [], stackDocuments: [], requirements: [], 
            financialResponsibilityCenters: [],documentsTypes: [], 
            organizations: [], availableRequirements: [], side: DocumentSide.Customer };

    const differentResponsible = validatedBy != null && validatedBy.id != UserData.id;

    const setStackDocuments = (setter: (prev: Document[]) => Document[]) => {
        const newDocs = setter(stackDocuments);
        setViewModel({ ...(viewModel!), stackDocuments: newDocs });
    }

    const setAttributes = (attributes: DocumentAttributeValue[]) => {
        setViewModel({ ...(viewModel!), attributes: attributes });
    }

    const {
        findDublicatesHandler,
        canFindDublicates,
        canShowGrid,
        gridProps
    } = useDocumentGrid(
        setStackDocuments,
        documentNumber, 
        stackId, 
        stackDocuments, 
        contractor?.id, 
        attributes.find(a => a.kind === DocumentAttributeKind.DocumentDate)?.value as string,
        isMainInStack,
    );

    const loadViewModel = async () => {
        const vm = await api.documents.getEditViewModel(documentId);

        setValidate(vm.dateValidated == null);
        setViewModel(vm);
    };

    useEffect(() => {
        setContinueEditing(null);

        if (documentIdStr != null)
            loadViewModel();
    }, [documentId]);

    const stateModifier = function <T, V>(action: (value: NonNullable<T>) => Promise<V>) {
        return async (value: T) => {
            if (differentResponsible) {
                setContinueEditing(async () => {
                    await action(value!);
                    loadViewModel();
                });

                setChangeResponsibleModalOpen(true);
                return;
            }

            await action(value!);
            loadViewModel();
        }
    }

    const onOrganizationChanged = stateModifier(async (organizationId: number) => await api.documents.setOrganization(documentId, organizationId));
    const onTypeChanged = stateModifier(async (typeId: number) => await api.documents.setType(documentId, typeId));
    const onSideChanged = stateModifier(async _ => await api.documents.swapDocumentSides(documentId));
    const onContractorChanged = stateModifier(async (contractor: TitledEntity) => await api.documents.setContractor(documentId, contractor.id));
    const onFrcChanged = stateModifier(async (frcId: number) => await api.documents.setFinancialResponsibilityCenter(documentId, frcId));
    const onContractChanged = stateModifier(async (contract: TitledEntity) => await api.documents.setContract(documentId, contract.id));
    const onAdditionalAgreementChanged = stateModifier(async (a: TitledEntity) => await api.documents.setAdditionalAgreement(documentId, a.id));
    const onStatusChanged = stateModifier(async (status: DocumentStatus) => api.documents.setStatus(documentId, status));

    const attributeAdded = (value: DocumentAttributeValue) => {
        setAttributes([...attributes, value]);
    }

    const attributeChanged = (value: DocumentAttributeValue) => {
        setAttributes([...attributes]);
    }

    const attributeDeleted = (attirbute: DocumentAttributeValue) => {
        setAttributes(attributes.filter(a => a.id !== attirbute.id));
    }

    const findContractor = async (text: string) => {
        const { items } = await api.contractors.getMany(undefined, undefined, undefined, undefined, undefined, text);
        return items.map(c => ({ id: c.id, title: c.name }));
    }

    const findContract = async (text: string) => {
        if (contractor == null)
            return [];

        const { items } = await api.documents.getMany(undefined, undefined, undefined, undefined,
            { contractorId: [contractor!.id], typeId: [AccountingDocumentTypes.Contract], validated: [true] }, text);

        return items.map(d => ({ id: d.id, title: getDocumentCaption(d) }));
    }

    const findAdditionalAgreement = async (text: string) => {
        if (contract == null)
            return [];

        const { items } = await api.documents.getMany(undefined, undefined, undefined, undefined,
            { contractId: [contract!.id], typeId: [AccountingDocumentTypes.AdditionalAgreement], validated: [true] }, text);

        return items.map(d => ({ id: d.id, title: getDocumentCaption(d) }));
    }

    const completeValidation = async (searchDuplicates: boolean = true) => {
        if (canFindDublicates && searchDuplicates) {
            const hasDuplicates = await api.documents.hasDuplicates(documentId);
            if (hasDuplicates) {
                setDublicatesFoundModalOpen(true);
                return;
            }
        }

        if (differentResponsible) {
            setContinueEditing(async () => {
                const { succeeded, errors } = await api.documents.completeValidation(documentId);
                if (succeeded) {
                    history.push(Routes.documentsOnValidation);
                    return;
                }

                setErrorText(errors![0]);
                setErrorModalOpen(true);
            });

            setChangeResponsibleModalOpen(true);
            return;
        }

        const { succeeded, errors } = await api.documents.completeValidation(documentId);
        if (succeeded) {
            history.push(Routes.documentsOnValidation);
            return;
        }

        setErrorText(errors![0]);
        setErrorModalOpen(true);
    }

    const rejectValidation = async () => {
        if (differentResponsible) {
            setContinueEditing(async () => {
                await api.documents.rejectValidation(documentId);
                history.push(Routes.documentsOnValidation);
            });

            setChangeResponsibleModalOpen(true);
            return;
        }

        await api.documents.rejectValidation(documentId);
        history.push(Routes.documentsOnValidation);
    }

    const isContract = typeId === AccountingDocumentTypes.Contract;
    const isAdditionalAgreement = typeId === AccountingDocumentTypes.AdditionalAgreement;

    const canCompleteValidation = () => {
        const hasEmptyRequiredAttributes = attributes.some(a => a.required && (a.value == null || a.value == ''));
        if (hasEmptyRequiredAttributes)
            return false;

        if (!isContract && contract == null)
            return false;

        if (contractor == null)
            return false;

        if (isContract && frcId == null)
            return false;

        return true;
    }

    const onAttributeDialogOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAddAttributeDialogOpen(true);
        setAttributeAnchorEl(e.currentTarget);
    }

    const customerName = attributes.find(a => a.kind === DocumentAttributeKind.CustomerName)?.value as string | null ?? '';
    const supplierName = attributes.find(a => a.kind === DocumentAttributeKind.ContractorName)?.value as string | null ?? '';

    const isSingleInStack = stackDocuments.length === 0;

    const goBack = () => {
        const canGoBack = history.action !== 'POP';
        if (canGoBack)
            return history.goBack();

        return validate ? history.push(Routes.documentsOnValidation) : history.push(Routes.uploads);
    }

    return (
        <div>
            <div>
                <div className="common_header recognition_flex-header">
                    <Button
                        color='inherit'
                        startIcon={<ArrowBack />}
                        onClick={() => goBack()}
                    >
                        Назад
                    </Button>
                    <p> {validate ? "Валидация" : "Просмотр"} документа №{documentNumber}</p>
                </div>
                <div className="action-buttons show_action-buttons validate_button">
                    <div className="validate_button-content">
                        {validate ? <Button variant="contained">Распознать</Button> : null}
                        <Button onClick={() => setCompareRequisitesModalOpen(true)} variant="contained">Cверить реквизиты</Button>
                        <Button variant="contained" disabled={!canFindDublicates} onClick={findDublicatesHandler}>Найти дубликаты</Button>
                    </div>
                    <div className="validate_button-content">
                        <Button onClick={() => setChangesModalOpen(true)} color='secondary'>История изменений</Button>
                        {!validate ? <AddDocumentToRequirementButton documentId={documentId}
                            availableRequirements={availableRequirements} onAddedToParagraph={() => loadViewModel()} /> : null}
                        {validate ? <Button onClick={rejectValidation} color='secondary'>Отклонить</Button> : null}
                        {validate ? <Button onClick={() => completeValidation()} disabled={!canCompleteValidation()} variant="contained">Утвердить</Button> : null}
                    </div>
                </div>
                <div className="validate_select">
                    <div className="validate_select-content">
                        <FileLabelWithName fileName={fileName!} fileId={documentId}/>
                        <div className="common_select_container">
                            <p>Организация:</p>
                            <FormControl variant="outlined" className='common_select-style'>
                                <Select
                                    disabled={readonly}
                                    value={organizationId ?? ''}
                                    className='common_select-style'
                                    onChange={(e) => onOrganizationChanged(Number(e.target.value))}
                                >
                                    {organizations.map(o => <MenuItem key={o.id} className='common_select-text' value={o.id.toString()}>{o.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="common_select_container validate_select-type">
                            <p>Тип:</p>
                            <FormControl variant="outlined" className='common_select-style'>
                                <Select
                                    disabled={readonly}
                                    value={typeId ?? ''}
                                    className='common_select-style'
                                    onChange={(e) => onTypeChanged(Number(e.target.value))}
                                >
                                    {documentsTypes.map(o => <MenuItem key={o.id} className='common_select-text' value={o.id.toString()}>{o.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="common_select_container validate_select-status-style">
                            <p>Статус:</p>
                            <FormControl variant="outlined" className='common_select-style'>
                                <Select
                                    value={status ?? ''}
                                    className='common_select-style'
                                    onChange={(e) => onStatusChanged(Number(e.target.value))}
                                >
                                    {DocumentStatusCaptions.map(c =>
                                        <MenuItem key={c.value} className='common_select-text' value={c.value.toString()}>{c.caption}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Контрагент:</p>
                            <FormControl variant="outlined" className='common_select-style validate_autocomplete' sx={{ padding: '0px 0px !important', '& .div': { padding: '0px 0px' } }}>
                                <AutocompleteEntityPicker
                                    disabled={readonly}
                                    className='common_select-style validate_autocomplete'
                                    entitySource={findContractor}
                                    onPicked={onContractorChanged}
                                    value={contractor} />
                            </FormControl>
                        </div>
                        <div className="common_select_container validate_select-status">
                            <p>Договор:</p>
                            <FormControl variant="outlined" className='common_select-style'>
                                <AutocompleteEntityPicker
                                    disabled={readonly || isContract || contractor == null}
                                    className='common_select-style'
                                    entitySource={findContract}
                                    onPicked={onContractChanged}
                                    value={contract} />
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>Д/С:</p>
                            <FormControl variant="outlined" className='common_select-style validate_autocomplete'>
                                <AutocompleteEntityPicker
                                    disabled={readonly || isAdditionalAgreement || contract == null}
                                    className='common_select-style validate_autocomplete'
                                    entitySource={findAdditionalAgreement}
                                    onPicked={onAdditionalAgreementChanged}
                                    value={additionalAgreement} />
                            </FormControl>
                        </div>
                        <div className="common_select_container">
                            <p>ЦФО:</p>
                            {isContract || isAdditionalAgreement ?
                                <FormControl variant="outlined" className='common_select-style'>
                                    <Select
                                        disabled={readonly}
                                        value={frcId ?? ''}
                                        className='common_select-style'
                                        onChange={(e) => onFrcChanged(Number(e.target.value))}
                                    >
                                        {financialResponsibilityCenters.map(c =>
                                            <MenuItem key={c.id} className='common_select-text' value={c.id.toString()}>{c.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                : <span>{financialResponsibilityCenters.find(c => c.id === frcId)?.name}</span>
                            }
                        </div>
                        <div className="common_select_container">
                            <DocumentStackState mainStackDocumentId={mainStackDocumentId!} isMainInStack={isMainInStack!} isSingleInStack={isSingleInStack} />
                        </div>                        
                    </div>
                    <div className="validate_select-attribute">
                        <div className="validate_select-content-attribute">
                            <div className="validate_select-attribute-flex">
                                <div className="common_select_container document_info__span">
                                    <LabeledText label="Загрузил" text={uploadedBy} />
                                </div>
                                <div className="common_select_container document_info__span validate_select-text">
                                    <LabeledText label="Провалидировал" text={validatedBy?.title} />
                                </div>
                                {!validate ?
                                    <div className="common_select_container document_info__span validate_select-text">
                                        <DocumentRequirements requirements={requirements} />
                                    </div> : null}
                            </div>
                            <div className="validate_select-content">
                                <div className="common_select_container document_info__span validate_select-date">
                                    <LabeledText label="Дата загрузки" text={formatDate(dateUploaded)} />
                                </div>
                                <div className="common_select_container document_info__span validate_select-text">
                                    <LabeledText label="Дата валидации" text={formatDate(dateValidated)} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <FormControl>
                                <FormLabel>Контрагент</FormLabel>
                                <RadioGroup
                                    value={side}
                                    onChange={(_, value) => onSideChanged(Number(value))}
                                >
                                    <FormControlLabel disabled={readonly} value={DocumentSide.Supplier.toString()} control={<Radio />} label={'Покупатель: ' + customerName} />
                                    <FormControlLabel disabled={readonly} value={DocumentSide.Customer.toString()} control={<Radio />} label={'Поставщик: ' + supplierName} />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="common_attribute">
                            <div className="common_attribute-content">
                                <div className="common_attribute__label">
                                    <FormLabel>Первичные атрибуты:</FormLabel>
                                    <Button disabled={readonly} onClick={onAttributeDialogOpen} variant='outlined'>Добавить</Button>
                                </div>
                                <div className="common_attribute__editor">
                                    <AttributesEditor
                                        anchorElement={attributeAnchorEl}
                                        documentId={documentId}
                                        disabled={readonly}
                                        attributes={attributes}
                                        onAttributeAdded={attributeAdded}
                                        onAttributeChanged={attributeChanged}
                                        onAttributeDeleted={attributeDeleted}
                                        addAttributeDialogOpen={addAttributeDialogOpen}
                                        setAddAttributeDialogOpen={(open) => setAddAttributeDialogOpen(open)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {canShowGrid && 
                    <DocumentStackGrid {...gridProps}/>
                }
            </div>
            <ChangesModal documentId={documentId} open={changesModalOpen} setOpen={setChangesModalOpen} />
            <CompareRequisitesModal documentId={documentId} open={compareRequisitesModalOpen} setOpen={setCompareRequisitesModalOpen} />
            <NotificationModal
                open={errorModalOpen}
                title="Ошибка"
                onOk={() => setErrorModalOpen(false)}
                text={errorText}
                showCancel={false}
                setOpen={setErrorModalOpen}
            />
            <NotificationModal
                open={changeResponsibleModalOpen}
                title="Подтверждение"
                onOk={async () => {
                    setChangeResponsibleModalOpen(false);
                    await continueEditing!();
                }}
                onCancel={() => {
                    setChangeResponsibleModalOpen(false);
                    setContinueEditing(null);
                }}
                text="Данный документ уже имеет ответственного. Взять его в работу?"
                showCancel={true}
                setOpen={setChangeResponsibleModalOpen}
            />
            <NotificationModal
                open={dublicatesFoundModalOpen}
                title="Подтверждение"
                onOk={() => {
                    setDublicatesFoundModalOpen(false);
                    findDublicatesHandler();
                }}
                onCancel={async () => {                    
                    await completeValidation(false);
                }}
                text="Документ с одинаковым номером, датой и контрагентом уже находится в хранилище, но не связан с текущим. Во избежание появления дубликатов одного и того же документа, необходимо связать эти документы или убедиться в том, что это разные документы."
                showCancel={true}
                setOpen={setDublicatesFoundModalOpen}
                okButtonText="Найти дубликаты"
                cancelButtonText="Утвердить, в хранилище нет дубликатов данного"
            />            
        </div>
    );
};

const DocumentStackState = ({ isMainInStack, isSingleInStack, mainStackDocumentId }: 
    { isMainInStack: boolean, isSingleInStack: boolean, mainStackDocumentId: number }) => {    
    if (isMainInStack)
        return <FormControlLabel disabled={true} control={<Checkbox disabled={true} checked={true} />} label="Лучшая копия документа" />;

    if (!isSingleInStack)
        return <Link target="_blank" href={getShowDocumentRoute(mainStackDocumentId)}>Перейти к главному</Link>;

    return null;
}

const DocumentRequirements: React.FC<{ requirements: TitledEntity[] }> = ({ requirements }) => {
    const { goToShowRequirement } = useGoToPageProvider();

    if (requirements.length === 0)
        return null;

    return <>
        <p>Требования ФНС:</p>
        {requirements.map((r, i) => <><span style={{ cursor: 'pointer' }}
            onClick={() => goToShowRequirement(r.id)}>{r.title}</span> {i === requirements.length - 1 ? '' : ', '}</>)}
    </>;
}

interface IAddToRequirementButtonProps {
    documentId: number;
    availableRequirements: EditDocumentRequirementViewModel[];
    onAddedToParagraph: (paragraphId: number) => void;
};

const AddDocumentToRequirementButton: React.FC<IAddToRequirementButtonProps> = ({ documentId,
    availableRequirements, onAddedToParagraph }) => {

    const [open, setOpen] = useState(false);
    const [addDocumentsDialogOpen, setAddDocumentsDialogOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState<Element>();
    const api = useApi();
    const { id: documentIdStr } = useParams<{ id?: string }>();

    const addToParagraph = async (paragraphId: number) => {
        await api.requirementParagraphs.addDocuments(paragraphId, [documentId]);
        onAddedToParagraph(paragraphId);
        setOpen(false);
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(e.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setAddDocumentsDialogOpen(false);
    }

    return <>
        <AddDocumentsDialog 
        open={addDocumentsDialogOpen}
        onClose={handleClose}
        documentId={Number(documentIdStr)}></AddDocumentsDialog>

        <Button disabled={availableRequirements.length === 0} variant="contained" onClick={onClick}>Добавить к требованию</Button>
        <div className='box'>
            <Menu
                anchorEl={anchorElement}
                open={open}
                onClose={() => setOpen(false)}
            >
                {
                    availableRequirements?.flatMap(r => [<MenuItem style={{ pointerEvents: 'none' }}>{r.title}</MenuItem>,
                    ...r.paragraphs.map(p =>
                        <MenuItem onClick={() => addToParagraph(p.id)}>
                            <ListItemText inset>Пункт {p.title}</ListItemText>
                        </MenuItem>)])
                }
                <MenuItem onClick={() => { setAddDocumentsDialogOpen(true); setOpen(false) }}>
                    <ListItemText>Выбрать...</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    </>;
}

const LabeledText = ({ label, text }: { label: string, text?: string | null }) => {
    return <>
        <p>{label}:</p>
        <span> {text == null ? <><InfoIcon /> Отсутствует</> : text} </span>
    </>
}

const CompareRequisitesModal: React.FC<{ documentId: number, open: boolean, setOpen: (open: boolean) => void }> = ({ documentId, open, setOpen }) => {
    const api = useApi();
    const [requisites, setRequisites] = useState<RequisitesToCompare[]>();

    useEffect(() => {
        if (open)
            loadRequisites();
    }, [open]);

    const loadRequisites = async () => {
        const { requisites } = await api.documents.getRequisitesToCompare(documentId);
        setRequisites(requisites);
    }

    const isDiffers = ({ documentValue, contractorValue }: RequisitesToCompare) => {
        if (documentValue == null || contractorValue == null)
            return false;

        return documentValue !== contractorValue;
    }

    return <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => setOpen(false)}
        fullWidth={true}
        maxWidth='md'
        className="compareDialog"
    >
        <DialogTitle id="alert-dialog-title">
            Сравнение реквизитов
        </DialogTitle>
        <DialogContent>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Атрибут</TableCell>
                            <TableCell align="right">Контрагент</TableCell>
                            <TableCell align="right">Документ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requisites?.map((r) => (
                            <TableRow
                                key={r.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {r.name.trim().split(/\s+/)[0]}
                                </TableCell>
                                <TableCell align="right">{r.contractorValue}</TableCell>
                                <TableCell sx={ isDiffers(r) ? {backgroundColor: '#FFE9CD'} : undefined} align="right">{r.documentValue}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>OK</Button>
        </DialogActions>
    </Dialog>;
}

export default observer(DocumentView);